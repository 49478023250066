import { Col, Row, Skeleton } from 'antd';
import React, { useState } from 'react';
import usePromise from 'react-promise-suspense';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { convertDecimal, imgErrorFallback, sleep } from '../../../common/Utils';
import TokenPairBox from '../../../components/TokenPairBox';
import configs, { havahScanUrl } from '../../../configs';

interface TokensProps {
  walletAddress: string;
  refreshIdx: number;
}

const tableSpans = [15, 3, 3, 3];

export const TokensFallback = () => {
  return (
    <TokensContainer>
      <TableHeader>
        <Col span={tableSpans[0]}>Token</Col>
        <Col span={tableSpans[1]}>Details</Col>
        <Col span={tableSpans[2]}>Price</Col>
        <Col span={tableSpans[3]}>Balance</Col>
      </TableHeader>
      <TableRow>
        <Skeleton paragraph={{ rows: 1 }} active />
      </TableRow>
      <TableRow>
        <Skeleton paragraph={{ rows: 1 }} active />
      </TableRow>
      <TableRow>
        <Skeleton paragraph={{ rows: 1 }} active />
      </TableRow>
    </TokensContainer>
  );
};

export const Tokens = ({ walletAddress, refreshIdx }: TokensProps) => {
  const { profileApiService } = configs;

  const [_refreshIdx, setRefreshIdx] = useState(refreshIdx ?? 0);
  const refresh = () => {
    setRefreshIdx(Math.random());
  };
  const tokens = usePromise(
    async _ => {
      try {
        return await profileApiService.getTokensByAddress(walletAddress);
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    [`profile/${walletAddress}/tokens/${_refreshIdx}`],
    0
  );

  const sortedTokens = tokens.sort((a, b) => {
    return parseFloat(convertDecimal(a.balance, a.decimal)) <
      parseFloat(convertDecimal(b.balance, b.decimal))
      ? 1
      : -1;
  });

  return (
    <TokensContainer>
      <TableHeader>
        <Col span={tableSpans[0]}>Token</Col>
        <Col span={tableSpans[1]}>Details</Col>
        <Col span={tableSpans[2]}>Price</Col>
        <Col span={tableSpans[3]}>Balance</Col>
      </TableHeader>

      {sortedTokens.map((token, idx) => {
        return (
          <TableRow key={idx}>
            <TokenCol span={tableSpans[0]}>
              <TokenImg
                src={token.imageUrl}
                alt={token.name}
                onError={imgErrorFallback}
              />
              <ColContentContainer>
                <TokenSymbol>{token.symbol}</TokenSymbol>
                <TokenName>{token.name}</TokenName>
              </ColContentContainer>
            </TokenCol>
            <TableCol span={tableSpans[1]}>
              <a
                href={`${havahScanUrl}/token/${token.address}`}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <img
                  src={'/assets/token_detail_icon.svg'}
                  alt={'tokenAndBalance detail'}
                />
              </a>
            </TableCol>
            <TableCol span={tableSpans[2]}>
              {token.type === 'lpPool'
                ? null
                : `$${token.tokenPrice.toLocaleString('en-US', {
                    maximumFractionDigits: 4
                  })}`}
            </TableCol>
            <TableCol span={tableSpans[3]}>
              <ColContentContainer>
                <TokenBalance>
                  {parseFloat(
                    convertDecimal(token.balance, token.decimal)
                  ).toLocaleString('en-US', { maximumFractionDigits: 4 })}{' '}
                  {token.symbol}
                </TokenBalance>
                <TokenValue>
                  {token.type === 'lpPool'
                    ? null
                    : `$${(
                        parseFloat(
                          convertDecimal(token.balance, token.decimal)
                        ) * token.tokenPrice
                      ).toLocaleString('en-US', { maximumFractionDigits: 4 })}`}
                </TokenValue>
              </ColContentContainer>
            </TableCol>
          </TableRow>
        );
      })}
    </TokensContainer>
  );
};

const TokensContainer = styled.div``;

const TableHeader = styled(Row)`
  width: 100%;
  font-size: 0.6875rem;
  font-weight: 400;
  background-color: #000;
  color: #fff;
  padding: 1.25rem 0;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > div:first-child {
    padding-left: 1.25rem;
    justify-content: flex-start;
  }
  border-radius: 0.75rem;
`;

const TableCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TokenCol = styled(TableCol)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > div {
    margin-left: 1rem;
  }
`;

const TableRow = styled(Row)`
  padding: 20px 0;
  div:first-child {
    padding-left: 1.25rem;
  }
`;

const TokenImg = styled.img`
  width: 36px;
  height: 36px;
`;

const ColContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TokenSymbol = styled.span`
  font-size: 16px;
`;

const TokenName = styled.span`
  font-size: 12px;
  color: #b3b3b3;
`;

const TokenBalance = styled.span`
  text-align: center;
`;

const TokenValue = styled.span`
  font-size: 12px;
  color: #b3b3b3;
  text-align: center;
`;
