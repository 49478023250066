import {
  HavahSignResponse,
  HavahSignWalletResponse,
  LaunchpadProjectMintingInfo,
  LaunchpadCrossChainMintVerifiedData,
  TxConfig,
  WalletTxRes
} from '../../types';
import { LaunchpadService } from '../LaunchpadService';
import IconService from 'icon-sdk-js';
import BigNumber from 'bignumber.js';
import { convertDecimal } from '../../common/Utils';

const { IconConverter, HttpProvider } = IconService;
const { CallTransactionBuilder, CallBuilder } = IconService.IconBuilder;

export class HavahLaunchpadService implements LaunchpadService {
  service: IconService;
  apiHost: string;

  constructor(
    apiHost = 'http://localhost:3005',
    rpcHost = 'https://ctz.vega.havah.io/api/v3'
  ) {
    this.service = new IconService(new HttpProvider(rpcHost) as any);
    this.apiHost = apiHost;

    this.initialize();
  }

  async initialize(): Promise<void> {
    console.log('HavahLaunchpadService initialized');
  }

  async sign(targetAddress: string): Promise<HavahSignResponse> {
    const timestamp = new Date().getTime();
    const result = (await (window as any)?.havah?.sign(
      `${targetAddress},${timestamp}`
    )) as HavahSignWalletResponse;

    return {
      signature: result.signData.signature,
      timestamp,
      targetAddress
    };
  }

  async mint(
    mintingInfo: LaunchpadProjectMintingInfo,
    amount: number,
    { addr }: TxConfig
  ): Promise<WalletTxRes> {
    const minterAddr = mintingInfo.minterAddress;
    const { mintingStages, currentStageIndex } = mintingInfo;

    const price = mintingStages[currentStageIndex].price.multipliedBy(
      new BigNumber(amount)
    );
    const txObj = new CallTransactionBuilder()
      .from(addr)
      .to(minterAddr)
      .stepLimit(3440000 + amount * 180000)
      .method('mint')
      .params({
        amount: amount.toString()
      })
      .value(
        parseFloat(
          convertDecimal(
            price.plus(price.dividedToIntegerBy(new BigNumber(99999))),
            18,
            18
          )
        )
      )
      .build();
    return await this.sendTx(txObj);
  }

  async mintCrossChainedNft(
    mintingInfo: LaunchpadProjectMintingInfo,
    amount: number,
    verifiedData: LaunchpadCrossChainMintVerifiedData,
    v: number,
    r: string,
    s: string,
    { addr }: TxConfig
  ): Promise<WalletTxRes> {
    const minterAddr = mintingInfo.minterAddress;
    const { mintingStages, currentStageIndex } = mintingInfo;

    const price = mintingStages[currentStageIndex].price.multipliedBy(
      new BigNumber(amount)
    );
    const txObj = new CallTransactionBuilder()
      .from(addr)
      .to(minterAddr)
      .stepLimit(3440000 + amount * 180000)
      .method('mint')
      .params({
        amount: amount.toString(),
        whitelist: verifiedData,
        v: v,
        r: r,
        s: s
      })
      .value(
        parseFloat(
          convertDecimal(
            price.plus(price.dividedToIntegerBy(new BigNumber(99999))),
            18,
            18
          )
        )
      )
      .build();
    return await this.sendTx(txObj);
  }

  private async sendTx(txObj: any): Promise<WalletTxRes> {
    return (await (window as any)?.havah?.sendTransaction({
      ...txObj,
      ...txObj?.data
    })) satisfies WalletTxRes;
  }
}
