import { Modal } from 'antd';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  .ant-modal-close-x {
    font-size: 24px;
  }
  .ant-modal-content {
    background-color: #fafafa;

    .ant-modal-header {
      background-color: unset;
    }
  }
`;

export const CustomModalFooter = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

export const CustomModalTitle = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

export const CustomModalBodyContainer = styled.div`
  > div {
    margin-bottom: 20px;
  }
`;

export const CustomModalContent = styled.div`
  > div {
    margin-bottom: 20px;
  }
  .title {
    font-size: 18px;
  }
`;

export const ReceiveInfoContainer = styled.div`
  background-color: #f3f3f3;
  padding: 15px;
  color: #999999;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  .receive_amount {
    color: #000;
  }
`;

export const TokenImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export const ShareContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .title {
    color: #808080;
  }
`;

export const InputInfoContainer = styled.div`
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 4px;
  box-sizing: border-box;
`;

export const InputTokenInfo = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
`;

export const InputTokenSwapInfo = styled.div`
  padding: 15px;
  display: column;

  img {
    width: 18px;
    height: 18px;
  }
`;

export const InputTokenSwapInfoConvert = styled.div`
  padding: 0 15px;
`;

export const SlippageInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SlippageInfoTitle = styled.div`
  font-size: 16px;
`;

export const SlippageInfoContent = styled.div`
  font-size: 16px;
`;

export const InputTokenInfoLeft = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 10px;
  }
  > .token_name {
    color: #808080;
  }
  > .token_ratio {
    color: #b3b3b3;
  }
`;

export const InputTokenInfoRight = styled.div``;

export const ConfirmSupplyButton = styled.button`
  height: 50px;
  cursor: pointer;
  width: 100%;
  background-color: #f56666;
  border: 1px solid #f56666;
  border-radius: 4px;
  color: #ffffff;

  &:disabled {
    color: #b3b3b3;
    background-color: #e6e6e6;
    border: 1px solid #e6e6e6;
    cursor: not-allowed;
  }
`;
