import { SearchOutlined } from '@ant-design/icons';
import { Input, Skeleton } from 'antd';
import React, { Suspense } from 'react';
import { RadioTabs } from '../../../components/RadioTabs';
import {
  StakeTableContainer,
  StakeTableContent,
  StakeTableSearch,
  StakeTableTabContainer
} from '../styles';
import StakePools, { StakePoolsSkeleton } from './StakePools';

const stakeTabItems = [
  {
    key: 'live',
    label: 'Live'
  },
  {
    key: 'finished',
    label: 'Finished'
  }
];

const StakeTable = () => {
  return (
    <StakeTableContainer>
      <StakeTableTabContainer>
        {/* <RadioTabs items={stakeTabItems} /> */}
        {/* <StakeTableSearch addonBefore={<SearchOutlined></SearchOutlined>} /> */}
      </StakeTableTabContainer>
      <StakeTableContent>
        <Suspense fallback={<StakePoolsSkeleton />}>
          <StakePools></StakePools>
        </Suspense>
      </StakeTableContent>
    </StakeTableContainer>
  );
};

export default StakeTable;
