import React from 'react';
import { Modal } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TransactionAtom, transactionAtom } from '../recoil/transaction/atom';
import styled from 'styled-components';

export const TransactionPendingModal = () => {
  const { pending, pendingMessage } =
    useRecoilValue<TransactionAtom>(transactionAtom);

  if (!pending) {
    return null;
  }

  return (
    <TransactionPendingCustomModal
      title={'Transaction Pending'}
      footer={null}
      open={true}
      closable={false}
      centered={true}
    >
      <TransactionPendingModalContent>
        <TransactionPendingImg src={'/assets/transaction_pending.png'} />
        <TransactionPendingText>
          Your transaction is pending.
        </TransactionPendingText>
        <TransactionPendingText>
          {pendingMessage ?? 'Please wait for the transaction to be confirmed.'}
        </TransactionPendingText>
      </TransactionPendingModalContent>
    </TransactionPendingCustomModal>
  );
};

const TransactionPendingCustomModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-title {
      color: #f56666;
    }
  }
`;

const TransactionPendingModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const TransactionPendingImg = styled.img``;

const TransactionPendingText = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;
