import React, { useState } from 'react';
import styled from 'styled-components';
import { Token } from 'types';
import { StyleBoxShadow } from '../common/styles';
import { imgErrorFallback } from '../common/Utils';

interface TokenInfo {
  address: string;
  name: string;
  nameEn: string;
  unit: string;
  imgUrl: string;
}

interface TokenPairBoxProps {
  tokenA: Token;
  tokenB: Token;
  showTokenName?: boolean;
  iconSize: string;
}

interface PairIconProps {
  imgUrl: string;
}

const TokenPairBox = ({
  tokenA,
  tokenB,
  iconSize,
  showTokenName = false
}: TokenPairBoxProps) => {
  return (
    <TokenPairContainer>
      <TokenPairIconContainer>
        <FirstTokenContainer width={iconSize}>
          <PairIcon imgUrl={tokenA.imageUrl || ''} />
        </FirstTokenContainer>
        <SecondTokenContainer width={iconSize}>
          <PairIcon imgUrl={tokenB.imageUrl || ''} />
        </SecondTokenContainer>
      </TokenPairIconContainer>
      {showTokenName ? (
        <TokenTitles>
          <TokenPairTitle>
            {tokenA.name} + {tokenB.name}
          </TokenPairTitle>
          <TokenPairTitleUnit>
            {tokenA.symbol} + {tokenB.symbol}
          </TokenPairTitleUnit>
        </TokenTitles>
      ) : (
        <></>
      )}
    </TokenPairContainer>
  );
};

export default TokenPairBox;

const TokenPairContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TokenPairIconContainer = styled.div`
  display: flex;
  position: relative;
  padding: 5px;
`;

const FirstTokenContainer = styled.div<{ width: string }>`
  width: ${props => props.width || '24px'};
  z-index: 1;
  border-radius: 50%;
  ${StyleBoxShadow}
`;

const SecondTokenContainer = styled.div<{ width: string }>`
  width: ${props => props.width || '24px'};
  z-index: 2;
  margin-left: -6px;
  border-radius: 50%;
  ${StyleBoxShadow}
`;

const PairIcon = ({ imgUrl }: PairIconProps) => {
  return (
    <img
      src={imgUrl}
      alt="tokenA"
      style={{ width: '100%' }}
      onError={imgErrorFallback}
    />
  );
};
const TokenTitles = styled.div`
  margin-left: 10px;
  width: 100%;

  p {
    margin: 0px;
  }
`;

const TokenPairTitle = styled.p`
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
`;

const TokenPairTitleUnit = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: #999999;
`;
