import { SaleMatchedEvent, isSaleMatchedEvent } from './../../types';
import { MarketApiService } from '../../api';
import axios, { Axios } from 'axios';
import {
  APINftInfo,
  NftInfo,
  ProjectAttributes,
  ProjectInfo,
  RawNftInfo
} from '../../types';
import { explodeRawNftItem } from 'common/Utils';
import BigNumber from 'bignumber.js';

export class HavahMarketApiService implements MarketApiService {
  client: Axios;
  // constructor(host = 'https://api.havahswap.io/') {
  //   this.client = axios.create({
  //     baseURL: host
  //   });
  // }
  constructor(host = 'http://localhost:3005') {
    this.client = axios.create({
      baseURL: host
    });
  }

  async getNftProjectList(): Promise<ProjectInfo[]> {
    const projectList = (
      await this.client.get<ProjectInfo[]>('/nft-market/project')
    ).data;

    return projectList;
  }

  async getRecentlyTradedList(): Promise<NftInfo[]> {
    try {
      const recentlyTradedNftInfos = (
        await this.client.get<RawNftInfo[]>('/nft-market/nft/recently-traded')
      ).data;

      const recentlyTradedList: NftInfo[] = [];

      for (const nftInfo of recentlyTradedNftInfos) {
        (
          nftInfo.nftHistory.filter(history =>
            isSaleMatchedEvent(history)
          ) as SaleMatchedEvent[]
        ).forEach(saledNftInfo => {
          recentlyTradedList.push({
            ...nftInfo,
            owner: nftInfo.owners[0],
            nftHistory: [saledNftInfo]
          });
        });
      }

      return recentlyTradedList;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async getProjectInfoByAddress(address: string): Promise<ProjectInfo> {
    const projectInfo = (
      await this.client.get<ProjectInfo>(`/nft-market/project/${address}`)
    ).data;

    return projectInfo;
  }

  async getNftItemsByProjectAddress(
    projectAddress: string
  ): Promise<NftInfo[]> {
    const nftItemInfo = (
      await this.client.get<APINftInfo>(`/nft-market/nft/${projectAddress}`)
    ).data;

    return nftItemInfo.nftInfo
      .map(info => explodeRawNftItem(info))
      .flat()
      .map(nftInfo => ({
        ...nftInfo,
        project: nftItemInfo.project,
        owner: nftInfo.owner && {
          ...nftInfo.owner,
          balance: new BigNumber(nftInfo.owner?.balance ?? 0)
        }
      }));
  }

  async getNftItemInfoByProjectAddressAndNftId(
    projectAddress: string,
    nftId: string,
    owner: string
  ): Promise<NftInfo | undefined> {
    const nftItemInfos = explodeRawNftItem(
      (
        await this.client.get<RawNftInfo>(
          `/nft-market/nft/${projectAddress}/${nftId}`
        )
      ).data
    );

    if (nftItemInfos.length === 1) return nftItemInfos[0];
    return (
      nftItemInfos.find(nftInfo => nftInfo.owner?.owner === owner) ??
      nftItemInfos[0]
    );
  }

  async getNftItem(
    projectAddress: string,
    nftId: string,
    owner: string
  ): Promise<NftInfo> {
    const nftItemRes = await this.client.get<RawNftInfo>(
      `/nft-market/nft/${projectAddress}/${nftId}`
    );
    if (nftItemRes.status !== 200) {
      throw new Error(`Unexpected status code: ${nftItemRes.status}`);
    }
    const nftItem = explodeRawNftItem(nftItemRes.data).map(nftInfo => ({
      ...nftInfo,
      owner: nftInfo.owner && {
        ...nftInfo.owner,
        balance: new BigNumber(nftInfo.owner?.balance ?? 0)
      }
    }));
    if (nftItem.length === 1) return nftItem[0];
    return (
      nftItem.find(nftInfo => nftInfo.owner?.owner === owner) ?? nftItem[0]
    );
  }

  async getProjectAttributesByAddress(
    address: string
  ): Promise<ProjectAttributes> {
    const projectAttributes = (
      await this.client.get<ProjectAttributes>(
        `/nft-market/project/${address}/attributes`
      )
    ).data;

    return projectAttributes;
  }

  async refreshMetadata(projectAddress: string, nftId: string): Promise<void> {
    await this.client.get(
      `/nft-market/nft/${projectAddress}/${nftId}/refresh-metadata`
    );
  }
}
