import React from 'react';
import styled from 'styled-components';

export const FullWidth = styled.div`
  width: 100%;
  display: flex;
  background-color: #f0f0f0cf;
  background-image: url('/assets/havahswap-bg.webp');
  flex-grow: 1;
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 62%;
  min-width: 1200px;
`;

type CommonPageContainerProps = {
  children: React.ReactNode;
};

const CommonPageContainer = ({ children }: CommonPageContainerProps) => {
  return (
    <FullWidth>
      <Container>{children}</Container>
    </FullWidth>
  );
};

export default CommonPageContainer;
