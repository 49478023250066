import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import SupplyTokenInfo from './SupplyTokenInfo';
import WithdrawTokenInfo from './WithdrawTokenInfo';
import { SupplyAndWithdrawButtonBox } from '../../common/styles';
import { NotiContext } from '../../../../index';

import BigNumber from 'bignumber.js';
import { Pool, SwapRouteInfo, SwapSide } from 'types';
import { convertBN, convertDecimal, dummyToken, sleep } from 'common/Utils';
import configs from '../../../../configs';
import { walletAtom } from '../../../../recoil/wallet/atom';
import { ErrorMessage } from '../../../../styles/common';
import SupplyModal from './SupplyModal';
import { transactionAtom } from '../../../../recoil/transaction/atom';
interface SupplyAndWithdrawContainerProps {
  pool: Pool;
  poolBalance: BigNumber[];
  walletTokenBalances: BigNumber[];
  lpTokenBalance: BigNumber;
  lpTokenTotalSupply: BigNumber;
  refresh: () => Promise<void>;
}

let timeoutTask: any;

type TabValue = 'supply' | 'withdraw';
type TabOptionValue = 'all' | 'tokenA' | 'tokenB';

const SupplyAndWithdrawContainer = ({
  pool,
  poolBalance,
  walletTokenBalances,
  lpTokenBalance,
  lpTokenTotalSupply,
  refresh
}: SupplyAndWithdrawContainerProps) => {
  const { swapService, apiService } = configs;
  const { notify } = useContext(NotiContext);
  const { address: walletAddress } = useRecoilValue(walletAtom);
  const [_, setTransaction] = useRecoilState(transactionAtom);

  const [tabValue, setTabValue] = useState<TabValue>('withdraw');
  const [tabOptionValue, setTabOptionValue] = useState<TabOptionValue>('all');

  const [firstTokenAmount, setFirstTokenAmount] = useState('0');
  const [secondTokenAmount, setSecondTokenAmount] = useState('0');

  const [firstTokenBalance, secondTokenBalance] = walletTokenBalances;

  const [swapInfo, setSwapInfo] = useState<SwapRouteInfo | undefined>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const resetAllSupplyInputs = () => {
    setFirstTokenAmount('0');
    setSecondTokenAmount('0');
    setSwapInfo(undefined);
  };

  const onTabChange = (tabValue: TabValue) => {
    setTabValue(tabValue);
    resetAllSupplyInputs();
  };

  const onTabOptionChange = (tabOptionValue: TabOptionValue) => {
    setTabOptionValue(tabOptionValue);
    resetAllSupplyInputs();
  };

  const onFirstTokenAmountChange = async (amount: string) => {
    setFirstTokenAmount(amount);

    const [poolABalance, poolBBalance] = poolBalance;

    if (tabOptionValue === 'all') {
      const secondTokenAmount = convertDecimal(
        convertBN(amount, pool.tokenA.decimal)
          .multipliedBy(poolBBalance)
          .dividedBy(poolABalance),
        pool.tokenB.decimal
      );
      setSecondTokenAmount(secondTokenAmount);
    } else if (tabOptionValue === 'tokenA') {
      setSwapInfo(undefined);
      clearTimeout(timeoutTask);

      timeoutTask = setTimeout(async () => {
        const amountToSwap = poolABalance
          .multipliedBy(poolBBalance)
          .multipliedBy(-1)
          .plus(
            poolABalance
              .multipliedBy(poolBBalance)
              .pow(2)
              .plus(
                poolABalance
                  .multipliedBy(poolBBalance.pow(2))
                  .multipliedBy(convertBN(amount, pool.tokenA.decimal))
              )
              .sqrt()
          )
          .dividedToIntegerBy(poolBBalance);

        const swapInfo = await apiService.getRoute({
          origin: pool.tokenA.address,
          dest: pool.tokenB.address,
          side: SwapSide.Positive,
          amount: amountToSwap
        });
        setSwapInfo(swapInfo);
      }, 500);
    }
  };

  const onSecondTokenAmountChange = async (amount: string) => {
    setSecondTokenAmount(amount);

    const [poolABalance, poolBBalance] = poolBalance;

    if (tabOptionValue === 'all') {
      const firstTokenAmount = convertDecimal(
        convertBN(amount, pool.tokenB.decimal)
          .multipliedBy(poolABalance)
          .dividedBy(poolBBalance),
        pool.tokenA.decimal
      );
      setFirstTokenAmount(firstTokenAmount);
    } else if (tabOptionValue === 'tokenB') {
      setSwapInfo(undefined);
      clearTimeout(timeoutTask);
      timeoutTask = setTimeout(async () => {
        const amountToSwap = poolBBalance
          .multipliedBy(poolABalance)
          .multipliedBy(-1)
          .plus(
            poolBBalance
              .multipliedBy(poolABalance)
              .pow(2)
              .plus(
                poolBBalance
                  .multipliedBy(poolABalance.pow(2))
                  .multipliedBy(convertBN(amount, pool.tokenB.decimal))
              )
              .sqrt()
          )
          .dividedToIntegerBy(poolABalance);

        const swapInfo = await apiService.getRoute({
          origin: pool.tokenB.address,
          dest: pool.tokenA.address,
          side: SwapSide.Positive,
          amount: amountToSwap
        });
        setSwapInfo(swapInfo);
      }, 500);
    }
  };

  const onSubmitSupply = async (
    tokenAAmount: BigNumber,
    tokenBAmount: BigNumber
  ) => {
    if (checkSupplOrWithdrawButtonDisabled()) {
      return;
    }
    const txRes = await swapService.addLiquidity(
      walletAddress,
      pool.tokenA.address,
      pool.tokenB.address,
      tokenAAmount,
      tokenBAmount
    );

    if (txRes.type === 'success') {
      setFirstTokenAmount('0');
      setSecondTokenAmount('0');
      await sleep(200);
      await refresh();
    } else {
      notify(
        <ErrorMessage>
          Failed to supply liquidity. Please try later.
          <span>
            <img src="/assets/message_error.png" alt="error icon" />
          </span>
        </ErrorMessage>
      );
    }
  };

  const onSubmitWithdraw = async () => {
    if (checkSupplOrWithdrawButtonDisabled()) {
      return;
    }
    const lpTokenAmount = convertBN(firstTokenAmount, 18);
    const tokenAAmount = poolBalance[0]
      .multipliedBy(lpTokenAmount)
      .dividedToIntegerBy(lpTokenTotalSupply);
    const tokenBAmount = poolBalance[1]
      .multipliedBy(lpTokenAmount)
      .dividedToIntegerBy(lpTokenTotalSupply);
    setTransaction({
      pending: true
    });
    const txRes = await swapService.removeLiquidity(
      walletAddress,
      pool.tokenA.address,
      pool.tokenB.address,
      pool.address,
      lpTokenAmount,
      tokenAAmount,
      tokenBAmount
    );
    setTransaction({
      pending: false
    });

    if (txRes.type === 'success') {
      setFirstTokenAmount('0');
      setSecondTokenAmount('0');
      await sleep(200);
      await refresh();
    }
  };

  const checkSupplOrWithdrawButtonDisabled = () => {
    if (tabValue == 'supply') {
      return true;
      // if (
      //   tabOptionValue === 'all' &&
      //   (!firstTokenAmount ||
      //     !secondTokenAmount ||
      //     convertBN(firstTokenAmount, pool.tokenA.decimal).isGreaterThan(
      //       firstTokenBalance
      //     ) ||
      //     convertBN(secondTokenAmount, pool.tokenB.decimal).isGreaterThan(
      //       secondTokenBalance
      //     ) ||
      //     new BigNumber(firstTokenAmount).isEqualTo(0) ||
      //     new BigNumber(secondTokenAmount).isEqualTo(0))
      // ) {
      //   return true;
      // } else if (
      //   tabOptionValue === 'tokenA' &&
      //   (!firstTokenAmount ||
      //     new BigNumber(firstTokenAmount).isEqualTo(0) ||
      //     convertBN(firstTokenAmount, pool.tokenA.decimal).isGreaterThan(
      //       firstTokenBalance
      //     ) ||
      //     !swapInfo)
      // ) {
      //   return true;
      // } else if (
      //   tabOptionValue === 'tokenB' &&
      //   (!secondTokenAmount ||
      //     new BigNumber(secondTokenAmount).isEqualTo(0) ||
      //     convertBN(secondTokenAmount, pool.tokenB.decimal).isGreaterThan(
      //       secondTokenBalance
      //     ) ||
      //     !swapInfo)
      // ) {
      //   return true;
      // }
    } else if (tabValue == 'withdraw') {
      if (new BigNumber(firstTokenAmount).isEqualTo(0)) {
        return true;
      }
    }
    return false;
  };

  const renderTabContents = () => {
    if (tabValue == 'supply') {
      return <></>;
      // if (tabOptionValue == 'all') {
      //   return (
      //     <>
      //       <SupplyTokenInfo
      //         balance={firstTokenBalance}
      //         amount={firstTokenAmount}
      //         token={pool?.tokenA || dummyToken}
      //         onAmountChange={(amount: string) => {
      //           onFirstTokenAmountChange(amount);
      //         }}
      //       />
      //       <SupplyTokenInfo
      //         balance={secondTokenBalance}
      //         amount={secondTokenAmount}
      //         token={pool?.tokenB || dummyToken}
      //         onAmountChange={(amount: string) => {
      //           onSecondTokenAmountChange(amount);
      //         }}
      //       />
      //     </>
      //   );
      // } else if (tabOptionValue == 'tokenA') {
      //   return (
      //     <SupplyTokenInfo
      //       balance={firstTokenBalance}
      //       amount={firstTokenAmount}
      //       token={pool?.tokenA || dummyToken}
      //       onAmountChange={(amount: string) => {
      //         onFirstTokenAmountChange(amount);
      //       }}
      //     />
      //   );
      // } else {
      //   return (
      //     <SupplyTokenInfo
      //       balance={secondTokenBalance}
      //       amount={secondTokenAmount}
      //       token={pool?.tokenB || dummyToken}
      //       onAmountChange={(amount: string) => {
      //         onSecondTokenAmountChange(amount);
      //       }}
      //     />
      //   );
      // }
    } else if (tabValue == 'withdraw') {
      return (
        <WithdrawInfoContainer>
          <WithdrawTokenInfo
            balance={lpTokenBalance}
            amount={firstTokenAmount}
            tokenA={pool?.tokenA || dummyToken}
            tokenB={pool?.tokenB || dummyToken}
            onAmountChange={(amount: string) => {
              onFirstTokenAmountChange(amount);
            }}
          />
        </WithdrawInfoContainer>
      );
    } else {
      return null;
    }
  };

  const renderSupplyNotice = () => {
    if (tabOptionValue == 'tokenA') {
      return (
        <NoticeBox>
          <NoticeText>Notice</NoticeText>
          <p>
            To Maximize pool share, a Cretain percentage of{' '}
            <HighlightedSpan>{pool?.tokenA.name}</HighlightedSpan> Will be
            Converted to <HighlightedSpan>{pool?.tokenB.name}</HighlightedSpan>{' '}
            and will be Deposited as the
            <span>
              {' '}
              {pool?.tokenA.name} + {pool?.tokenB.name}
            </span>{' '}
            Pair.
          </p>
        </NoticeBox>
      );
    } else if (tabOptionValue == 'tokenB') {
      return (
        <NoticeBox>
          <NoticeText>Notice</NoticeText>
          <p>
            To Maximize pool share, a Cretain percentage of{' '}
            <HighlightedSpan>{pool?.tokenB.name}</HighlightedSpan> Will be
            Converted to <HighlightedSpan>{pool?.tokenA.name}</HighlightedSpan>{' '}
            and will be Deposited as the
            <span>
              {' '}
              {pool?.tokenA.name} + {pool?.tokenB.name}
            </span>{' '}
            Pair.
          </p>
        </NoticeBox>
      );
    } else {
      return <></>;
    }
  };
  const renderSupplyAndWithdrawButton = () => {
    if (tabValue == 'supply') {
      return (
        <div>
          {renderSupplyNotice()}
          <SupplyAndWithdrawButtonBox
            onClick={() => {
              if (checkSupplOrWithdrawButtonDisabled()) {
                return;
              }
              setIsModalOpen(true);
            }}
            disabled={checkSupplOrWithdrawButtonDisabled()}
          >
            <span>
              The ratio of tokens you add will set the price of this pair
            </span>
            <ButtonBoxTitle>Supply</ButtonBoxTitle>
          </SupplyAndWithdrawButtonBox>
        </div>
      );
    } else {
      return (
        <div>
          <NoticeBox></NoticeBox>
          <SupplyAndWithdrawButtonBox
            onClick={onSubmitWithdraw}
            disabled={checkSupplOrWithdrawButtonDisabled()}
          >
            <span>
              The ratio of tokens you add will set the price of this pair
            </span>
            <ButtonBoxTitle>Withdraw</ButtonBoxTitle>
          </SupplyAndWithdrawButtonBox>
        </div>
      );
    }
  };

  return (
    <SwitchContainer>
      <SwitchTabContainer>
        <TabBox>
          {/* <TabRadioInput
            type="radio"
            id="supply"
            name="tabForSupply"
            value="supply"
            onChange={e => {
              onTabChange(e.target.value as TabValue);
            }}
            checked={tabValue == 'supply'}
          />
          <label htmlFor="supply">Liquidity Supply</label> */}
          <TabRadioInput
            type="radio"
            id="withdraw"
            name="tabForWithdraw"
            value="withdraw"
            onChange={e => {
              onTabChange(e.target.value as TabValue);
            }}
            checked={tabValue == 'withdraw'}
          />
          <label htmlFor="withdraw">Withdraw</label>
        </TabBox>
        {tabValue == 'supply' && (
          <SupplyOptionBox>
            <RadioInput
              type="radio"
              id="all"
              name="optionForAll"
              value="all"
              onChange={e => onTabOptionChange('all')}
              checked={tabOptionValue == 'all'}
            />
            <label htmlFor="all">
              {pool?.tokenA.name} + {pool?.tokenB.name}
            </label>
            <RadioInput
              type="radio"
              id="tokenA"
              name="optionForTokenA"
              value="tokenA"
              onChange={e => onTabOptionChange('tokenA')}
              checked={tabOptionValue === 'tokenA'}
            />
            <label htmlFor="tokenA">{pool?.tokenA.name}</label>
            <RadioInput
              type="radio"
              id="tokenB"
              name="optionForTokenB"
              value="tokenB"
              onChange={e => onTabOptionChange('tokenB')}
              checked={tabOptionValue === 'tokenB'}
            />
            <label htmlFor="tokenB">{pool?.tokenB.name}</label>
          </SupplyOptionBox>
        )}
      </SwitchTabContainer>
      <SupplyAndWithdrawInputContainer>
        {renderTabContents()}
        {renderSupplyAndWithdrawButton()}
      </SupplyAndWithdrawInputContainer>
      <SupplyModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        pool={pool}
        poolBalance={poolBalance}
        lpTokenTotalSupply={lpTokenTotalSupply}
        firstTokenAmount={convertBN(firstTokenAmount, pool.tokenA.decimal)}
        secondTokenAmount={convertBN(secondTokenAmount, pool.tokenB.decimal)}
        swapInfo={swapInfo}
        tabOptionValue={tabOptionValue}
        onConfirm={async () => {
          if (tabOptionValue !== 'all' && swapInfo) {
            setTransaction({
              pending: true
            });
            const txRes = await swapService.swap(
              walletAddress,
              {
                origin: swapInfo.path[0],
                dest: swapInfo.path.at(-1) ?? '',
                side: SwapSide.Positive,
                amount: new BigNumber(0),
                slippage: new BigNumber(100)
              },
              swapInfo
            );
            if (txRes.type === 'success') {
              await sleep(1500);
              setTransaction({
                pending: false
              });
            } else {
              setTransaction({
                pending: false
              });
              notify(
                <ErrorMessage>
                  Failed to supply liquidity. Please try again.
                  <span>
                    <img src="/assets/message_error.png" alt="error icon" />
                  </span>
                </ErrorMessage>
              );
              setIsModalOpen(false);
              return;
            }
            setTransaction({
              pending: true
            });
            const [poolABalance, poolBBalance] =
              await swapService.getPoolBalance(pool);
            if (tabOptionValue === 'tokenA') {
              const firstTokenAmount = swapInfo.outputAmount
                .multipliedBy(poolABalance)
                .dividedToIntegerBy(poolBBalance);

              await onSubmitSupply(firstTokenAmount, swapInfo.outputAmount);
            } else if (tabOptionValue === 'tokenB') {
              const secondTokenAmount = swapInfo.outputAmount
                .multipliedBy(poolBBalance)
                .dividedToIntegerBy(poolABalance);

              await onSubmitSupply(swapInfo.outputAmount, secondTokenAmount);
            }
            setTransaction({
              pending: false
            });
          } else if (tabOptionValue === 'all') {
            setTransaction({
              pending: true
            });
            await onSubmitSupply(
              convertBN(firstTokenAmount, pool.tokenA.decimal),
              convertBN(secondTokenAmount, pool.tokenB.decimal)
            );
            setTransaction({
              pending: false
            });
          }

          setIsModalOpen(false);
        }}
      />
    </SwitchContainer>
  );
};

export default SupplyAndWithdrawContainer;

const SwitchContainer = styled.div`
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
  padding: 1.25rem;
  border-radius: 0.75rem;
  background: #ffffff;
`;

const SwitchTabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TabBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TabItem = styled.div`
  cursor: pointer;

  &:last-child {
    margin-left: 1rem;
  }
`;

const SupplyOptionBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TabRadioInput = styled.input`
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;

  + label {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.25rem 0.75rem;
    cursor: pointer;
    background: #ffffff;
    color: #b3b3b3;
    font-size: 0.9375rem;

    &:not(:first-of-type) {
      margin-left: 5px;
    }
  }

  &:checked + label {
    color: #000000;
    font-weight: 500;
    z-index: 1;
  }
`;

const RadioInput = styled.input`
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;

  + label {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.25rem 0.75rem;
    cursor: pointer;
    background: #ffffff;
    color: #b3b3b3;
    font-size: 0.6875rem;
    border: 1px solid #cccccc;
    border-radius: 0.75rem;
    transition: 0.2s linear;

    &:not(:first-of-type) {
      margin-left: 5px;
    }
  }

  &:checked + label {
    color: #f56666;
    border-color: #f56666;
    z-index: 1;
  }
`;

const SupplyAndWithdrawInputContainer = styled.div`
  margin-top: 1rem;
`;

const ButtonBoxTitle = styled.div`
  font-size: 1.375rem;
  font-weight: 700;
`;

const ReturnEstimateContainer = styled.div`
  margin-top: 3rem;
  padding: 1.25rem 2.5rem;
  border-top: 1px dashed #eaeaea;
`;

const ReturnEstimateRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 0.6875rem;
`;

const ReturnEstimateRowTitle = styled.div`
  color: #666666;
`;

const ReturnEstimateMainTitleBox = styled.div``;

const WithdrawInfoContainer = styled.div``;

const NoticeText = styled.p`
  border-bottom: 3px solid #f56666;
  width: 52px;
`;

const HighlightedSpan = styled.span`
  color: #f56666;
`;

const NoticeBox = styled.div`
  color: #4d4d4d;
`;
