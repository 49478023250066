import { SwapService } from './blockchain/SwapService';
import { HavahSwapService } from './blockchain/havah/HavahSwapService';
import {
  LaunchpadApiService,
  MarketApiService,
  ProfileApiService,
  SwapApiService
} from './api';
import { HavahSwapApiService } from './api/havah';
import { HavahMarketApiService } from './api/market';
import { NftService } from './blockchain/NftService';
import { HavahNftService } from './blockchain/havah/HavahNftService';
import { HavahProfileApiService } from './api/profile';
import process from 'process';
import { HavahStakingService } from './blockchain/havah/HavahStakingService';
import { StakingService } from './blockchain/StakingService';
import { HavahLaunchpadApiService } from './api/launchpad';
import { LaunchpadService } from './blockchain/LaunchpadService';
import { HavahLaunchpadService } from './blockchain/havah/HavahLaunchpadService';
import { HavahAsteroidService } from 'blockchain/havah/HavahAsteroidService';

interface Config {
  apiService: HavahSwapApiService;
  swapService: HavahSwapService;
  nftService: HavahNftService;
  marketApiService: HavahMarketApiService;
  profileApiService: HavahProfileApiService;
  stakingService: HavahStakingService;
  launchpadApiService: HavahLaunchpadApiService;
  launchpadService: HavahLaunchpadService;
  asteroidService: HavahAsteroidService;
}

export const havahScanUrl =
  process?.env?.REACT_APP_NETWORK === 'testnet'
    ? 'https://scan.vega.havah.io'
    : 'https://scan.havah.io';

const httpProviderUrl =
  process?.env?.REACT_APP_NETWORK === 'testnet'
    ? 'https://ctz.vega.havah.io/api/v3'
    : 'https://ctz.havah.io/api/v3';

export const baseNetworkRpc =
  process?.env?.REACT_APP_NETWORK === 'testnet'
    ? 'https://base-goerli.g.alchemy.com/v2/vE2LZC83cnprvnJqswwwbcEzGWkC5Dzn'
    : 'https://chain-proxy.wallet.coinbase.com?targetName=base';

export const baseChainId =
  process?.env?.REACT_APP_NETWORK === 'testnet' ? '0x14a33' : '0x2105';

const apiHost =
  process?.env?.REACT_APP_HAVAHSWAP_API_HOST ?? 'https://prod.api.havahswap.io';

export const crossChainContractAbi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'caller',
            type: 'address'
          },
          {
            internalType: 'address',
            name: 'signer',
            type: 'address'
          },
          {
            internalType: 'string',
            name: 'origin',
            type: 'string'
          },
          {
            internalType: 'uint256',
            name: 'round',
            type: 'uint256'
          }
        ],
        internalType: 'struct BebeLaunchpad.Whitelist',
        name: 'whitelist',
        type: 'tuple'
      },
      {
        internalType: 'uint8',
        name: 'v',
        type: 'uint8'
      },
      {
        internalType: 'bytes32',
        name: 'r',
        type: 'bytes32'
      },
      {
        internalType: 'bytes32',
        name: 's',
        type: 'bytes32'
      }
    ],
    name: 'mint',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    name: 'minted',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'mintPublic',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      },
      {
        internalType: 'string',
        name: '',
        type: 'string'
      }
    ],
    name: 'mintAmountPerRound',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'mintInfo',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'totalMintAmount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'remaining',
            type: 'uint256'
          },
          {
            internalType: 'address',
            name: 'signer',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'startTime',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'endTime',
            type: 'uint256'
          }
        ],
        internalType: 'struct BebeLaunchpad.MintInfo',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  }
];

export default {
  apiService: new HavahSwapApiService(apiHost) satisfies SwapApiService,
  swapService: new HavahSwapService(
    apiHost,
    httpProviderUrl
  ) satisfies SwapService,
  nftService: new HavahNftService(
    apiHost,
    httpProviderUrl
  ) satisfies NftService,
  marketApiService: new HavahMarketApiService(
    apiHost
  ) satisfies MarketApiService,
  profileApiService: new HavahProfileApiService(
    apiHost
  ) satisfies ProfileApiService,
  stakingService: new HavahStakingService(
    apiHost,
    httpProviderUrl
  ) satisfies StakingService,
  launchpadApiService: new HavahLaunchpadApiService(
    apiHost
  ) satisfies LaunchpadApiService,
  launchpadService: new HavahLaunchpadService(
    apiHost,
    httpProviderUrl
  ) satisfies LaunchpadService,
  asteroidService: new HavahAsteroidService(apiHost, httpProviderUrl)
} satisfies Config;
