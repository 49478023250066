import styled from 'styled-components';

export const footerHeight = '80px';
export const FooterContainer = styled('footer')`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  background: #ffffff;
  border: 1px solid #f3f3f3;
`;
export const FooterSection = styled('div')`
  background: white;
  width: 62%; // 1920px 모니터에서 1200px 기준으로 잡음
  min-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${footerHeight};
  @media only screen and (max-width: 768px) {
    padding: 4rem 2rem;
  }
`;
export const FooterLogo = styled('img')`
  display: inline-block;
  width: 120px;
`;

export const FooterCopyright = styled('p')`
  display: inline-block;
  color: #b3b3b3;
  font-size: 11px;
  text-align: center;
  margin-bottom: 0;
`;
export const FooterSocialContainer = styled('ul')`
  display: flex;
  justify-content: space-between;
  color: #b3b3b3;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 0;
`;

export const FooterSocialItem = styled('li')`
  display: inline-block;
  padding: 0 10px;
  color: #b3b3b3;
  font-size: 1.5rem;
  text-align: center;
`;

export const HavhSwapCatImg = styled.img.attrs({
  src: '/assets/havah-swap-cat.png',
  alt: 'havah swap cat'
})`
  width: 258px;
  margin-top: -178px;
  position: absolute;
`;
