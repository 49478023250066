import { Col, Row, Skeleton, Statistic } from 'antd';
import React, { useContext, useState } from 'react';
import usePromise from 'react-promise-suspense';
import styled from 'styled-components';
import { convertDecimal, sleep } from '../../../common/Utils';
import configs from '../../../configs';
import BigNumber from 'bignumber.js';
import { AsteroidClaimModal } from './AsteroidClaimModal';
import { AsteroidHistory, UserAsteroidStatus } from '../../../types';
import { AsteroidHistoryModal } from './AsteroidHistoryModal';
import { useRecoilState } from 'recoil';
import { transactionAtom } from 'recoil/transaction/atom';
import { NotiContext } from 'index';
import { ErrorMessage } from 'styles/common';

interface AsteroidProps {
  walletAddress: string;
  refreshIdx: number;
}

const tableSpans = [12, 6, 6];

export const AsteroidFallback = () => {
  return (
    <AsteroidContainer>
      <TableHeader>
        <Col span={tableSpans[0]}>Asteroid Id</Col>
        <Col span={tableSpans[1]}>Rewards</Col>
        <Col span={tableSpans[2]}></Col>
      </TableHeader>
      <TableRow>
        <Skeleton paragraph={{ rows: 1 }} active />
      </TableRow>
      <TableRow>
        <Skeleton paragraph={{ rows: 1 }} active />
      </TableRow>
      <TableRow>
        <Skeleton paragraph={{ rows: 1 }} active />
      </TableRow>
    </AsteroidContainer>
  );
};

export const Asteroid = ({ walletAddress, refreshIdx }: AsteroidProps) => {
  const [_, setTransaction] = useRecoilState(transactionAtom);
  const { notify } = useContext(NotiContext);

  const { profileApiService, asteroidService } = configs;
  const [_refreshIdx, setRefreshIdx] = useState(refreshIdx ?? 0);
  const [showClaimAll, setShowClaimAll] = useState(false);
  const [asteroidHistoryInfo, setAsteroidHistoryInfo] = useState<{
    asteroid: UserAsteroidStatus;
    history: AsteroidHistory;
  } | null>(null);

  const refresh = () => {
    setRefreshIdx(Math.random());
  };

  const asteroidInfo = usePromise(
    async _ => {
      return profileApiService.getAsteroidInfoByAddress(walletAddress);
    },
    [`profile/${walletAddress}/asteroid/${_refreshIdx}`],
    0
  );

  const asteroidData = asteroidInfo.asteroids.map(asteroidStat => {
    return {
      purchaseNo: asteroidStat.id,
      rewards: convertDecimal(asteroidStat.claimable, 18, 18),
      asteroid: asteroidStat
    };
  });

  return (
    <>
      <AsteroidContainer>
        <AsteroidStatusContainer>
          <AsteroidStatus>
            <Statistic
              title={'Distributed Reward'}
              value={convertDecimal(asteroidInfo.distributedReward, 18, 18)}
              suffix={'HVH'}
              // valueStyle={{ color: '#8247ff' }}
              style={{ textAlign: 'center' }}
            />
          </AsteroidStatus>
          <AsteroidStatus>
            <Statistic
              title={'Claimable Reward'}
              value={convertDecimal(
                asteroidInfo.asteroids.reduce<BigNumber>((acc, asteroid) => {
                  return acc.plus(asteroid.claimable);
                }, new BigNumber(0)),
                18,
                18
              )}
              suffix={'HVH'}
              // valueStyle={{ color: '#8247ff' }}
              style={{ textAlign: 'center' }}
            />
          </AsteroidStatus>
        </AsteroidStatusContainer>
        <AsteroidHeader>
          <AsteroidHeaderTitle>Asteroid</AsteroidHeaderTitle>
          <ClaimAllButton
            onClick={() => {
              setShowClaimAll(true);
            }}
          >
            Claim All
          </ClaimAllButton>
        </AsteroidHeader>
        <TableHeader>
          <Col span={tableSpans[0]}>Asteroid Id</Col>
          <Col span={tableSpans[1]}>Rewards</Col>
        </TableHeader>

        {asteroidData.map((asteroidRow, idx) => {
          return (
            <TableRow key={idx}>
              <PurchaseNoCol span={tableSpans[0]}>
                {asteroidRow.purchaseNo}
              </PurchaseNoCol>
              <TableCol span={tableSpans[1]}>
                <div>{asteroidRow.rewards} HVH</div>
              </TableCol>
              <TableCol span={tableSpans[1]}>
                <RewardActionButton
                  isHistory={true}
                  onClick={async () => {
                    const asteroidHistory =
                      await profileApiService.getAsteroidHistory(
                        asteroidRow.asteroid.id
                      );
                    setAsteroidHistoryInfo({
                      asteroid: asteroidRow.asteroid,
                      history: asteroidHistory
                    });
                  }}
                >
                  History
                </RewardActionButton>
                <RewardActionButton
                  disabled={asteroidRow.asteroid.claimable.eq(0)}
                  onClick={async () => {
                    try {
                      setTransaction({
                        pending: true,
                        pendingMessage: 'Claiming asteroid rewards'
                      });
                      const txRes = await asteroidService.batchClaim(
                        [asteroidInfo.asteroids[idx]],
                        {
                          addr: walletAddress
                        }
                      );
                      if (txRes.type === 'success') {
                        await sleep(200);
                        refresh();
                      } else {
                        throw new Error();
                      }
                    } catch (e) {
                      console.log(e);
                      notify(
                        <ErrorMessage>
                          Failed to claim Asteroid#{asteroidRow.asteroid.id}{' '}
                          rewards. Please try later.
                          <span>
                            <img
                              src="/assets/message_error.png"
                              alt="error icon"
                            />
                          </span>
                        </ErrorMessage>
                      );
                    } finally {
                      setTransaction({
                        pending: false,
                        pendingMessage: undefined
                      });
                    }
                  }}
                >
                  Claim
                </RewardActionButton>
              </TableCol>
            </TableRow>
          );
        })}
      </AsteroidContainer>

      {showClaimAll && (
        <AsteroidClaimModal
          onClose={() => {
            setShowClaimAll(false);
            refresh();
          }}
          asteroids={asteroidInfo.asteroids}
          walletAddress={walletAddress}
        ></AsteroidClaimModal>
      )}
      {asteroidHistoryInfo && (
        <AsteroidHistoryModal
          onClose={() => setAsteroidHistoryInfo(null)}
          asteroid={asteroidHistoryInfo.asteroid}
          history={asteroidHistoryInfo.history}
        ></AsteroidHistoryModal>
      )}
    </>
  );
};

const AsteroidContainer = styled.div``;

const AsteroidStatusContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
`;

const AsteroidStatus = styled.div``;

const AsteroidHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 10px;
`;

const AsteroidHeaderTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

const ClaimAllButton = styled.button`
  width: 144px;
  height: 50px;
  cursor: pointer;
  background: #8247ff;
  color: white;
  font-size: 16px;
  border: 1px solid #8247ff;
  border-radius: 4px;
  transition: 0.3s linear;
  &:hover {
    opacity: 0.9;
  }
`;

const RewardActionButton = styled.button<{ isHistory?: boolean }>`
  width: 138px;
  height: 36px;
  cursor: pointer;
  background: ${props => (props.isHistory ? '#000000' : '#8247ff')};
  color: white;
  font-size: 13px;
  border: 1px solid ${props => (props.isHistory ? '#000000' : '#8247ff')};
  border-radius: 4px;
  transition: 0.3s linear;
  &:hover {
    opacity: 0.9;
  }

  &:first-child {
    margin-right: 10px;
  }

  &:disabled {
    background: #e0e0e0;
    border: 1px solid #e0e0e0;
    cursor: not-allowed;
  }
`;

const TableHeader = styled(Row)`
  width: 100%;
  font-size: 0.6875rem;
  font-weight: 400;
  background-color: #000;
  color: #fff;
  padding: 1.25rem 0;
  > div {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  > div:first-child {
    padding-left: 1.25rem;
    justify-content: flex-start;
  }
  border-radius: 0.75rem;
`;

const TableCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PurchaseNoCol = styled(TableCol)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > div {
    margin-left: 1rem;
  }
`;

const TableRow = styled(Row)`
  padding: 20px 0;
  div:first-child {
    padding-left: 1.25rem;
  }
`;
