import BigNumber from 'bignumber.js';
import React from 'react';
import styled from 'styled-components';
import { convertDecimal, imgErrorFallback } from '../../../../../common/Utils';
import { Pool, SwapRouteInfo } from '../../../../../types';
import {
  InputInfoContainer,
  InputTokenInfo,
  InputTokenInfoLeft,
  InputTokenInfoRight,
  InputTokenSwapInfo,
  InputTokenSwapInfoConvert,
  TokenImg
} from './styles';

interface SupplyModalSwapInputProps {
  pool: Pool;
  poolBalance: BigNumber[];
  firstTokenAmount: BigNumber;
  secondTokenAmount: BigNumber;
  swapInfo?: SwapRouteInfo;
}

export const SupplyModalSwapInput = ({
  poolBalance,
  pool,
  firstTokenAmount,
  secondTokenAmount,
  swapInfo
}: SupplyModalSwapInputProps) => {
  const tokenAToBRatio = poolBalance[1]
    .dividedBy(new BigNumber(10).pow(pool.tokenB.decimal))
    .dividedBy(
      poolBalance[0].dividedBy(new BigNumber(10).pow(pool.tokenA.decimal))
    );
  const tokenBToARatio = poolBalance[0]
    .dividedBy(new BigNumber(10).pow(pool.tokenA.decimal))
    .dividedBy(
      poolBalance[1].dividedBy(new BigNumber(10).pow(pool.tokenB.decimal))
    );

  if (!swapInfo) return null;

  const inputToken =
    pool.tokenA.address === swapInfo.path[0] ? pool.tokenA : pool.tokenB;
  const outputToken =
    pool.tokenA.address === swapInfo.path.at(-1) ? pool.tokenA : pool.tokenB;
  const tokenRatio =
    inputToken.address === pool.tokenA.address
      ? tokenAToBRatio
      : tokenBToARatio;
  const inputTokenAmount =
    inputToken.address === pool.tokenA.address
      ? firstTokenAmount
      : secondTokenAmount;

  return (
    <>
      <div className="title">Input</div>
      <InputInfoContainer>
        <InputTokenInfo>
          <InputTokenInfoLeft>
            <TokenImg src={inputToken.imageUrl} onError={imgErrorFallback} />
            <span className={'token_name'}>{inputToken.name}</span>
            <span className={'token_ratio'}>
              1 {inputToken.name} = {tokenRatio.toFixed(6)} {outputToken.name}
            </span>
          </InputTokenInfoLeft>
          <InputTokenInfoRight>
            {convertDecimal(inputTokenAmount.toFixed(6), inputToken.decimal)}
          </InputTokenInfoRight>
        </InputTokenInfo>
        <InputTokenSwapInfoConvert>
          <img src="/assets/pink_arrow_down.png" alt="arrow down" />
        </InputTokenSwapInfoConvert>
        <InputTokenSwapInfo>
          <InputTokenInfoLeft>
            <TokenImg src={inputToken.imageUrl} onError={imgErrorFallback} />
            <span className={'token_name'}>{inputToken.name}</span>
            <span className={'token_ratio'}>
              {convertDecimal(swapInfo.inputAmount, inputToken.decimal)}{' '}
              {inputToken.name}
            </span>
          </InputTokenInfoLeft>
          <InputTokenInfoLeft>
            <TokenImg src={outputToken.imageUrl} onError={imgErrorFallback} />
            <span className={'token_name'}>{outputToken.name}</span>
            <span className={'token_ratio'}>
              {convertDecimal(swapInfo.outputAmount, outputToken.decimal)}{' '}
              {outputToken.name}
            </span>
          </InputTokenInfoLeft>
        </InputTokenSwapInfo>
      </InputInfoContainer>
    </>
  );
};
