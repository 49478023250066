import BigNumber from 'bignumber.js';
import {
  convertDecimal,
  convertHavahValue,
  getConfig,
  sleep
} from '../../common/Utils';
import IconService from 'icon-sdk-js';
import { SwapService } from '../SwapService';
import {
  Address,
  Pool,
  SwapInfo,
  SwapRouteInfo,
  SwapSide,
  Token,
  WalletTxRes,
  Config
} from '../../types';
import configs from '../../configs';
import axios from 'axios';

const { IconConverter, HttpProvider } = IconService;
const { CallTransactionBuilder, CallBuilder } = IconService.IconBuilder;

type SwapFunctionName =
  | 'swapExactTokensForTokens'
  | 'swapTokensForExactTokens'
  | 'swapExactHVHForTokens'
  | 'swapTokensForExactHVH'
  | 'swapExactTokensForHVH'
  | 'swapHVHForExactTokens';

interface TxParmas {
  amountIn?: string;
  amountOut?: string;
  amountOutMin?: string;
  amountInMax?: string;
  path?: Address[];
  to?: Address;
  deadline?: string;
}

export class HavahSwapService implements SwapService {
  apiHost: string;
  service: IconService;
  routerAddress: Address;
  baseNumber = new BigNumber('10000');
  intMax = new BigNumber('10000000000000000000000000000');
  defaultSlipage = new BigNumber('100'); // 1%
  wrappedBaseTokenAddress = '';

  constructor(
    apiHost = 'http://localhost:3005',
    rpcHost = 'https://ctz.vega.havah.io/api/v3'
  ) {
    this.apiHost = apiHost;
    this.routerAddress = '';
    this.service = new IconService(new HttpProvider(rpcHost) as any);
    this.initialize();
  }

  private async initialize(): Promise<void> {
    try {
      // const configure = await getConfig(this.apiHost);
      const configure = (await axios.get(`${this.apiHost}/configure`))
        .data as Config;
      if (configure?.havah?.rpcHost) {
        this.service = new IconService(
          new HttpProvider(configure?.havah?.rpcHost) as any
        );
      }
      this.routerAddress = configure?.swap?.router?.address ?? '';
      this.wrappedBaseTokenAddress =
        configure?.wHVHAddress ?? this.wrappedBaseTokenAddress;

      console.log('HavahSwapService initialize', this.routerAddress);
    } catch (e) {
      console.log(e);
    }
  }

  async getBalance(
    token: Address,
    owner: Address,
    forceToken = false
  ): Promise<BigNumber> {
    if (!token || !owner) {
      return new BigNumber(0);
    }
    if (token === this.wrappedBaseTokenAddress && !forceToken) {
      return new BigNumber(await this.service.getBalance(owner).execute());
    }
    return new BigNumber(
      await this.service
        .call(
          new CallBuilder()
            .to(token)
            .method('balanceOf')
            .params({
              _owner: owner
            })
            .build()
        )
        .execute()
    );
  }

  async getTotalSupply(token: Address): Promise<BigNumber> {
    if (!token) {
      return new BigNumber(0);
    }
    return new BigNumber(
      await this.service
        .call(new CallBuilder().to(token).method('totalSupply').build())
        .execute()
    );
  }

  async getPoolBalance(pool: Pool): Promise<BigNumber[]> {
    const poolABalance = await this.getBalance(
      pool.tokenA.address ?? '',
      pool.address ?? '',
      true
    );
    const poolBBalance = await this.getBalance(
      pool.tokenB.address ?? '',
      pool.address ?? '',
      true
    );
    return [poolABalance, poolBBalance];
  }

  private async getDeadline(): Promise<BigNumber> {
    return new BigNumber(0);
  }

  private async sendTx(txObj: any): Promise<WalletTxRes> {
    return (await (window as any)?.havah?.sendTransaction({
      ...txObj,
      ...txObj?.data
    })) satisfies WalletTxRes;
  }

  private getSwapFuncName(swapInfo: SwapInfo): SwapFunctionName | undefined {
    if (
      swapInfo.origin === this.wrappedBaseTokenAddress &&
      swapInfo.side === SwapSide.Positive
    ) {
      return 'swapExactHVHForTokens';
    } else if (
      swapInfo.origin === this.wrappedBaseTokenAddress &&
      swapInfo.side === SwapSide.Negative
    ) {
      return 'swapHVHForExactTokens';
    } else if (
      swapInfo.dest === this.wrappedBaseTokenAddress &&
      swapInfo.side === SwapSide.Positive
    ) {
      return 'swapExactTokensForHVH';
    } else if (
      swapInfo.dest === this.wrappedBaseTokenAddress &&
      swapInfo.side === SwapSide.Negative
    ) {
      return 'swapTokensForExactHVH';
    } else if (swapInfo.side === SwapSide.Positive) {
      return 'swapExactTokensForTokens';
    } else if (swapInfo.side === SwapSide.Negative) {
      return 'swapTokensForExactTokens';
    }
  }

  applySlippage(
    amount: BigNumber,
    slippage: BigNumber,
    direct: 'pos' | 'neg' = 'pos'
  ): BigNumber {
    switch (direct) {
      case 'pos':
        return amount
          .multipliedBy(this.baseNumber.minus(slippage))
          .dividedToIntegerBy(this.baseNumber);
      case 'neg':
        return amount
          .multipliedBy(this.baseNumber.plus(slippage))
          .dividedToIntegerBy(this.baseNumber);
    }
  }

  private async getSlippageInOut(
    swapFuncName: SwapFunctionName,
    slippage: BigNumber,
    swapInfo: SwapInfo,
    swapRouteInfo: SwapRouteInfo
  ): Promise<{ inputAmount: BigNumber; outputAmount: BigNumber }> {
    switch (swapFuncName) {
      case 'swapExactHVHForTokens':
      case 'swapExactTokensForHVH':
      case 'swapExactTokensForTokens':
        return {
          inputAmount: swapRouteInfo.inputAmount,
          outputAmount: this.applySlippage(
            swapRouteInfo.outputAmount,
            slippage,
            'pos'
          )
        };
      case 'swapHVHForExactTokens':
      case 'swapTokensForExactHVH':
      case 'swapTokensForExactTokens':
        return {
          inputAmount: this.applySlippage(
            swapRouteInfo.inputAmount,
            slippage,
            'neg'
          ),
          outputAmount: swapRouteInfo.outputAmount
        };
      default:
        return {
          inputAmount: new BigNumber(0),
          outputAmount: new BigNumber(0)
        };
    }
  }

  async getAllowance(
    tokenAddr: Address,
    walletAddr: Address,
    spender: Address
  ): Promise<BigNumber> {
    const allowance: string = await this.service
      .call(
        new CallBuilder()
          .to(tokenAddr)
          .method('allowance')
          .params({
            _owner: walletAddr,
            _spender: spender
          })
          .build()
      )
      .execute();
    return new BigNumber(allowance);
  }

  async getAmountsIn(
    amountOut: BigNumber,
    path: Address[]
  ): Promise<BigNumber> {
    const amounts: string[] = await this.service
      .call(
        new CallBuilder()
          .to(this.routerAddress)
          .method('getAmountsIn')
          .params({
            amountOut: amountOut.toFixed(),
            path
          })
          .build()
      )
      .execute();
    return new BigNumber(amounts[0]);
  }

  async getAmountsOut(
    amountIn: BigNumber,
    path: Address[]
  ): Promise<BigNumber> {
    const amounts: string[] = await this.service
      .call(
        new CallBuilder()
          .to(this.routerAddress)
          .method('getAmountsOut')
          .params({
            amountIn: amountIn.toFixed(),
            path
          })
          .build()
      )
      .execute();
    return new BigNumber(amounts[amounts.length - 1]);
  }

  async approve(
    token: Address,
    walletAddr: Address,
    spender: Address,
    amount: BigNumber = this.intMax
  ): Promise<WalletTxRes> {
    if (
      (await this.getAllowance(token, walletAddr, spender)).isGreaterThan(
        BigNumber.min(amount, this.intMax.dividedBy(2))
      )
    )
      return { type: 'success', txHash: '' };
    const allowTxObj = new CallTransactionBuilder()
      .from(walletAddr)
      .to(token)
      .method('approve')
      .params({
        _spender: spender,
        _value: amount.toFixed()
      })
      .build();
    return await this.sendTx(allowTxObj);
  }

  async swap(
    walletAddr: Address,
    swapInfo: SwapInfo,
    swapRouteInfo: SwapRouteInfo
  ): Promise<WalletTxRes> {
    const { path } = swapRouteInfo;
    const deadline = await this.getDeadline();

    const swapFuncName: SwapFunctionName | undefined =
      this.getSwapFuncName(swapInfo);

    if (!swapFuncName)
      throw new Error(`Invalid SwapInfo. ${JSON.stringify({}, null, 2)}`);
    if (path.at(0) !== swapInfo.origin || path.at(-1) !== swapInfo.dest)
      throw new Error(`Invalid SwapInfo. ${JSON.stringify({}, null, 2)}`);

    const slippage = swapInfo.slippage ?? this.defaultSlipage;

    const { inputAmount, outputAmount } = await this.getSlippageInOut(
      swapFuncName,
      slippage,
      swapInfo,
      swapRouteInfo
    );

    let txBuild = new CallTransactionBuilder()
      .from(walletAddr)
      .to(this.routerAddress)
      .stepLimit(3000000 * (path.length - 1))
      .method(swapFuncName);

    let txParams: TxParmas = {};
    switch (swapFuncName) {
      case 'swapExactTokensForTokens':
        txParams = {
          amountIn: inputAmount.toFixed(),
          amountOutMin: outputAmount.toFixed(),
          path,
          to: walletAddr,
          deadline: deadline.toFixed()
        };
        break;
      case 'swapTokensForExactTokens':
        txParams = {
          amountOut: outputAmount.toFixed(),
          amountInMax: inputAmount.toFixed(),
          path,
          to: walletAddr,
          deadline: deadline.toFixed()
        };
        break;
      case 'swapExactHVHForTokens':
        txBuild = txBuild.value(parseFloat(convertDecimal(inputAmount)));
        txParams = {
          amountOutMin: outputAmount.toFixed(),
          path,
          to: walletAddr,
          deadline: deadline.toFixed()
        };
        break;
      case 'swapTokensForExactHVH':
        txParams = {
          amountOut: outputAmount.toFixed(),
          amountInMax: inputAmount.toFixed(),
          path,
          to: walletAddr,
          deadline: deadline.toFixed()
        };
        break;
      case 'swapExactTokensForHVH':
        txParams = {
          amountIn: inputAmount.toFixed(),
          amountOutMin: outputAmount.toFixed(),
          path,
          to: walletAddr,
          deadline: deadline.toFixed()
        };
        break;
      case 'swapHVHForExactTokens':
        txBuild = txBuild.value(parseFloat(convertDecimal(inputAmount)));
        txParams = {
          amountOut: outputAmount.toFixed(),
          path,
          to: walletAddr,
          deadline: deadline.toFixed()
        };
        break;
      default:
        break;
    }

    if (swapInfo.origin !== this.wrappedBaseTokenAddress) {
      const allowance = await this.getAllowance(
        swapInfo.origin,
        walletAddr,
        this.routerAddress
      );
      if (allowance.lt(inputAmount)) {
        await this.approve(
          swapInfo.origin,
          walletAddr,
          this.routerAddress,
          this.intMax
        );
      }
    }

    const txObj = txBuild.params(txParams).build();

    const res = await this.sendTx(txObj);
    return res;
  }

  async addLiquidity(
    walletAddr: Address,
    tokenA: Address,
    tokenB: Address,
    amountA: BigNumber,
    amountB: BigNumber,
    slippage = this.defaultSlipage,
    routerAddress = this.routerAddress
  ) {
    const tokenAAllowd = await this.getAllowance(
      tokenA,
      walletAddr,
      routerAddress
    );
    const tokenBAllowd = await this.getAllowance(
      tokenB,
      walletAddr,
      routerAddress
    );

    if (tokenAAllowd.lt(amountA)) {
      const txRes = await this.approve(
        tokenA,
        walletAddr,
        routerAddress,
        this.intMax
      );
      await sleep(200);
      if (txRes.type !== 'success') {
        return txRes;
      }
    }

    if (tokenBAllowd.lt(amountB)) {
      const txRes = await this.approve(
        tokenB,
        walletAddr,
        routerAddress,
        this.intMax
      );
      await sleep(200);
      if (txRes.type !== 'success') {
        return txRes;
      }
    }

    const txBuild = new CallTransactionBuilder()
      .from(walletAddr)
      .to(routerAddress)
      .stepLimit(2000000000);
    if (
      tokenA === this.wrappedBaseTokenAddress ||
      tokenB === this.wrappedBaseTokenAddress
    ) {
      const tokenAddress =
        tokenA === this.wrappedBaseTokenAddress ? tokenB : tokenA;
      const tokenAmount =
        tokenA === this.wrappedBaseTokenAddress ? amountB : amountA;
      const hvhAmount =
        tokenA === this.wrappedBaseTokenAddress ? amountA : amountB;

      txBuild
        .method('addLiquidityHVH')
        .params({
          token: tokenAddress,
          amountTokenDesired: tokenAmount.toFixed(),
          amountTokenMin: this.applySlippage(tokenAmount, slippage).toFixed(),
          amountHVHMin: this.applySlippage(hvhAmount, slippage).toFixed(),
          to: walletAddr,
          deadline: (await this.getDeadline()).toFixed()
        })
        .value(convertHavahValue(hvhAmount));
    } else {
      txBuild.method('addLiquidity').params({
        tokenA,
        tokenB,
        amountADesired: amountA.toFixed(),
        amountBDesired: amountB.toFixed(),
        amountAMin: this.applySlippage(amountA, slippage).toFixed(),
        amountBMin: this.applySlippage(amountB, slippage).toFixed(),
        to: walletAddr,
        deadline: (await this.getDeadline()).toFixed()
      });
    }

    const txObj = txBuild.build();
    const res = await this.sendTx(txObj);
    return res;
  }

  async removeLiquidity(
    walletAddr: Address,
    tokenA: Address,
    tokenB: Address,
    poolAddress: Address,
    liquidity: BigNumber,
    amountAMin: BigNumber,
    amountBMin: BigNumber,
    slippage = this.defaultSlipage
  ): Promise<WalletTxRes> {
    const poolAllowd = await this.getAllowance(
      poolAddress,
      walletAddr,
      this.routerAddress
    );

    if (poolAllowd.lt(liquidity)) {
      const txRes = await this.approve(
        poolAddress,
        walletAddr,
        this.routerAddress,
        this.intMax
      );
      if (txRes.type !== 'success') {
        return txRes;
      }
    }

    const txBuild = new CallTransactionBuilder()
      .from(walletAddr)
      .to(this.routerAddress);

    if (
      tokenA === this.wrappedBaseTokenAddress ||
      tokenB === this.wrappedBaseTokenAddress
    ) {
      const tokenAddress =
        tokenA === this.wrappedBaseTokenAddress ? tokenB : tokenA;
      const tokenAmount =
        tokenA === this.wrappedBaseTokenAddress ? amountBMin : amountAMin;
      const hvhAmount =
        tokenA === this.wrappedBaseTokenAddress ? amountAMin : amountBMin;

      txBuild.method('removeLiquidityHVH').params({
        token: tokenAddress,
        liquidity: liquidity.toFixed(),
        amountTokenMin: this.applySlippage(tokenAmount, slippage).toFixed(),
        amountHVHMin: this.applySlippage(hvhAmount, slippage).toFixed(),
        to: walletAddr,
        deadline: (await this.getDeadline()).toFixed()
      });
    } else {
      txBuild.method('removeLiquidity').params({
        tokenA,
        tokenB,
        liquidity: liquidity.toFixed(),
        amountAMin: this.applySlippage(amountAMin, slippage).toFixed(),
        amountBMin: this.applySlippage(amountBMin, slippage).toFixed(),
        to: walletAddr,
        deadline: (await this.getDeadline()).toFixed()
      });
    }

    const txObj = txBuild.build();
    const res = await this.sendTx(txObj);
    return res;
  }

  async getToken(address: Address): Promise<Token> {
    if (
      address === configs.swapService.wrappedBaseTokenAddress ||
      address === 'cx0000000000000000000000000000000000000000'
    )
      return {
        address: address,
        name: 'Havah',
        symbol: 'HVH',
        decimal: new BigNumber(18),
        imageUrl: '/assets/coins/default.png'
      };

    const name = await this.service
      .call(new CallBuilder().to(address).method('name').build() as any)
      .execute();
    const symbol = await this.service
      .call(new CallBuilder().to(address).method('symbol').build() as any)
      .execute();
    const decimal = new BigNumber(
      await this.service
        .call(new CallBuilder().to(address).method('decimals').build() as any)
        .execute()
    );

    return {
      address: address,
      name: name,
      symbol: symbol,
      decimal: decimal,
      imageUrl: '/assets/coins/default.png'
    };
  }
}
