import { Button, Col, Collapse, Input, Row } from 'antd';
import BigNumber from 'bignumber.js';
import React from 'react';
import { useRecoilValue } from 'recoil';
import configs from '../../../configs';
import { walletAtom } from '../../../recoil/wallet/atom';

const { Panel } = Collapse;

function getValue(id: string): string {
  return (document.querySelector(`#${id}`) as HTMLInputElement).value;
}

const stakeAddress = 'cx3668cfa26d5063faa687ec12f46eaa8076c0f85f';
const lpAddr = 'cxf20b94eb9b79cd36fbade11adbb619d2d11fd03c';

export const StakingTxTest = () => {
  const walletInfo = useRecoilValue(walletAtom);
  const { stakingService, swapService } = configs;
  return (
    <>
      <Row>
        <Col span={24}>
          <h1>approve</h1>
          <Input
            addonBefore="stakeAddress"
            id="stake-approve-1"
            defaultValue={stakeAddress}
          ></Input>
          <Input
            addonBefore="lpAddress"
            id="stake-approve-2"
            defaultValue={lpAddr}
          ></Input>
          <Button
            onClick={async () => {
              const spender = getValue('stake-approve-1');
              const lpAddr = getValue('stake-approve-2');
              const res = await swapService.approve(
                lpAddr,
                walletInfo.address,
                spender
              );
              console.log(res);
            }}
          >
            Execute
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h1>stake</h1>
          <Input
            addonBefore="lpAddress"
            id="stake-stake-1"
            defaultValue={lpAddr}
          ></Input>
          <Input
            addonBefore="amount"
            id="stake-stake-2"
            defaultValue={'0'}
          ></Input>
          <Button
            onClick={async () => {
              const lpAddr = getValue('stake-stake-1');
              const amount = new BigNumber(getValue('stake-stake-2'));
              const res = await stakingService.stake(lpAddr, amount, 0, {
                addr: walletInfo.address
              });
              console.log(res);
            }}
          >
            Execute
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h1>get user info</h1>
          <Input
            addonBefore="lpAddress"
            id="stake-getUserInfo-1"
            defaultValue={lpAddr}
          ></Input>
          <Button
            onClick={async () => {
              const lpAddr = getValue('stake-getUserInfo-1');
              const res = await stakingService.getUserStakeInfo(lpAddr, {
                addr: walletInfo.address
              });
              console.log(res);
            }}
          >
            Execute
          </Button>
        </Col>
      </Row>
    </>
  );
};
