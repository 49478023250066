import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Skeleton } from 'antd';
import PoolTable, { PoolTableSkeleton } from '../components/PoolTable';
import { Pool, PoolTokenBalances, Token } from 'types';

interface PoolBoardProps {
  pools: Pool[];
  poolTokenBalances: PoolTokenBalances;
}

const PoolBoard = ({ pools, poolTokenBalances }: PoolBoardProps) => {
  const [filterClicked, setFilterClicked] = useState('all');

  const poolData = pools.filter(item => {
    if (filterClicked == 'all') {
      return true;
    } else if (filterClicked == 'havah') {
      return item.isHavahPool;
    } else {
      return item.isStablePool;
    }
  });

  return (
    <PoolBoardContainer>
      <PoolFilterContainer>
        <PoolFilterBtnBox>
          <RadioInput
            type="radio"
            id="all"
            name="filterForAll"
            value="all"
            onClick={() => setFilterClicked('all')}
            checked={filterClicked == 'all'}
          />
          <label htmlFor="all">ALL</label>
          <RadioInput
            type="radio"
            id="havah"
            name="filterForHavah"
            value="havah"
            onClick={() => setFilterClicked('havah')}
            checked={filterClicked == 'havah'}
          />
          <label htmlFor="havah">HAVAH</label>
          <RadioInput
            type="radio"
            id="stable"
            name="filterForStable"
            value="stable"
            onClick={() => setFilterClicked('stable')}
            checked={filterClicked == 'stable'}
          />
          <label htmlFor="stable">STABLE</label>
        </PoolFilterBtnBox>
        {/* <PoolFilterSearchBox>
          <SearchBox>
            <SearchIcon />
            <Input onChange={e => setFilterKeyword(e.target.value)} />
          </SearchBox>
        </PoolFilterSearchBox> */}
      </PoolFilterContainer>
      <PoolTable
        pools={poolData}
        isMyPool={false}
        poolTokenBalances={poolTokenBalances}
      />
    </PoolBoardContainer>
  );
};

export const PoolBoardSkeleton = () => {
  return (
    <PoolBoardContainer>
      <PoolTableSkeleton />
    </PoolBoardContainer>
  );
};

export default PoolBoard;

const PoolBoardContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  margin-top: 1.875rem;
  border-radius: 12px 12px 0 0;
`;

const PoolFilterContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
`;

const PoolFilterBtnBox = styled.div`
  width: 100%;
  display: flex;
`;

const PoolFilterSearchBox = styled.div``;

const SearchBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const RadioInput = styled.input`
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;

  + label {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.25rem 0.75rem;
    cursor: pointer;
    background: #ffffff;
    color: #b3b3b3;
    font-size: 0.6875rem;
    border: 1px solid #cccccc;
    border-radius: 0.75rem;
    transition: 0.1s linear;

    &:not(:first-of-type) {
      margin-left: 5px;
    }
  }

  &:checked + label {
    color: #000000;
    border-color: #000000;
    z-index: 1;
  }
`;

const SearchIcon = styled.img.attrs({
  src: '/assets/search_icon.png',
  alt: 'search icon'
})`
  width: 22px;
  height: 22px;
  margin-right: 8px;
  cursor: pointer;
`;
