import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProjectSnsInfo } from 'types';
import { getSnsIconBySnsType } from 'common/Utils';
import { StyleBoxShadow } from '../common/styles';
import SkeletonImage from 'antd/es/skeleton/Image';
import { DefaultAvatar } from './DefaultAvatar';

interface ProfileContainerProps {
  profileImg: string | any | undefined;
  backgroundImgUrl: string | undefined;
  snsInfos?: ProjectSnsInfo[];
}

const BannerProfile = (props: ProfileContainerProps) => {
  const { profileImg, backgroundImgUrl, snsInfos } = props;

  const hanleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
  };
  return (
    <BannerProfileContainer>
      <BackgroundContainer>
        <BackgroundImgBox backgroundUrl={backgroundImgUrl}></BackgroundImgBox>
        {snsInfos && snsInfos.length > 0 && (
          <SnsInfoContainer>
            {snsInfos.map((item, idx) => {
              return (
                <SnsInfoBox key={idx}>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <SnsIconImg
                      src={getSnsIconBySnsType(item.snsType)}
                      alt="sns icon"
                    />
                  </a>
                </SnsInfoBox>
              );
            })}
            <SnsInfoBox onClick={hanleCopyUrl} style={{ cursor: 'pointer' }}>
              <SnsIconImg src={getSnsIconBySnsType('share')} alt="share url" />
            </SnsInfoBox>
          </SnsInfoContainer>
        )}
      </BackgroundContainer>
      <ProfileContainer>
        <ProfileImageBox>
          <ProfileImgOutline>
            <ProfileImgSpan>
              {typeof profileImg === 'string' && profileImg !== '' ? (
                <BannerProfileImg src={profileImg} />
              ) : (
                <DefaultAvatar />
              )}
            </ProfileImgSpan>
          </ProfileImgOutline>
        </ProfileImageBox>
      </ProfileContainer>
    </BannerProfileContainer>
  );
};

export const BannerProfileSkeleton = () => {
  return (
    <BannerProfileContainer>
      <BackgroundContainer>
        <BackgroundImgBox backgroundUrl={undefined}></BackgroundImgBox>
      </BackgroundContainer>
      <ProfileContainer>
        <ProfileImageBox>
          <ProfileImgOutline>
            <ProfileImgSpan>
              <SkeletonImage active={true} />
            </ProfileImgSpan>
          </ProfileImgOutline>
        </ProfileImageBox>
      </ProfileContainer>
    </BannerProfileContainer>
  );
};

export default BannerProfile;

const BannerProfileContainer = styled.div``;

const BackgroundContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const BackgroundImgBox = styled.div<{ backgroundUrl: string | undefined }>`
  width: 100%;
  height: 270px;
  display: flex;
  justify-content: center;
  background-image: url(${props => props.backgroundUrl});
  background-color: #f3f3f3;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const profileSize = 200;
const profileMargin = 10;

const ProfileImgOutline = styled.div`
  width: ${profileSize}px;
  height: ${profileSize}px;
  border-radius: 50%;
  margin-top: -130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  ${StyleBoxShadow}
  position: relative;
`;

const ProfileImgSpan = styled.span`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  position: absolute;
  width: ${profileSize - profileMargin}px;
  height: ${profileSize - profileMargin}px;
  border-radius: 50%;
  background: #ffffff;
`;

const BannerProfileImg = styled.img`
  box-sizing: border-box;
  border: none;
  margin: auto;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
`;

const ProfileContainer = styled.div`
  max-width: 2560px;
  padding: 0px 32px;
  margin: 0px auto;
`;

const ProfileImageBox = styled.div`
  margin-left: 5%;
`;

const SnsInfoContainer = styled.div`
  position: absolute;
  right: 10%;
  bottom: 5%;
  display: flex;
  background: #ffffff;
  border-radius: 6px;
`;

const SnsInfoBox = styled.div`
  padding: 12px 20px;
  &:not(:first-child) {
    border-left: 1px solid #f3f3f3;
  }
`;

const SnsIconImg = styled.img`
  width: 20px;
`;
