import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import ColoredDecimal from './ColoredDecimal';

const PoolDashBoard = () => {
  return (
    <PoolDashBoardRow>
      <PoolDashBoardTitleCol sm={12}>
        <p>Add Liquidity</p>
        <p>And Start Earning</p>
      </PoolDashBoardTitleCol>
    </PoolDashBoardRow>
  );
};

export default PoolDashBoard;

const PoolDashBoardRow = styled(Row)`
  margin-top: 3.125rem;
  width: 100%;
  background: #ffffff;
  border-radius: 0.75rem;
  padding: 1.375rem 1.5625rem;
`;

const PoolDashBoardTitleCol = styled(Col)`
  width: 100%;
  color: #000000;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 0.5rem;
  }
`;

const PoolDashBoardClaimCol = styled(Col)`
  width: 100%;
  color: #f56666;
`;

const ClaimBox = styled.div`
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
`;

const ClaimButton = styled.button`
  height: 1.5rem;
  cursor: pointer;
  background: #ffffff;
  color: #f56666;
  font-size: 0.75rem;
  border: 1px solid #f56666;
  border-radius: 0.75rem;
`;

const ClaimTag = styled.div`
  background: #ffffff;
  padding: 3px 4px;
  color: #f56666;
  font-size: 0.75rem;
  border: 1px solid #f56666;
  border-radius: 0.75rem;
`;

const ClaimInfoBox = styled.div`
  display: inline;
  font-size: 1.25rem;
  font-weight: 600;
  justify-content: end;
`;
