import React from 'react';
import { Divider } from 'antd';
import { Pool, SwapRouteInfo } from '../../../../../types';
import { convertDecimal, imgErrorFallback } from '../../../../../common/Utils';
import BigNumber from 'bignumber.js';
import { SupplyModalInput } from './SupplyModalInput';
import {
  ConfirmSupplyButton,
  CustomModal,
  CustomModalBodyContainer,
  CustomModalContent,
  CustomModalFooter,
  CustomModalTitle,
  ReceiveInfoContainer,
  ShareContainer,
  SlippageInfoContainer,
  SlippageInfoContent,
  SlippageInfoTitle,
  TokenImg
} from './styles';
import { SupplyModalSwapInput } from './SupplyModalSwapInput';
import configs from '../../../../../configs';
import { useRecoilValue } from 'recoil';
import { walletAddressSelector } from '../../../../../recoil/wallet/selectors';

interface SupplyModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  pool: Pool;
  poolBalance: BigNumber[];
  firstTokenAmount: BigNumber;
  secondTokenAmount: BigNumber;
  lpTokenTotalSupply: BigNumber;
  swapInfo?: SwapRouteInfo;
  tabOptionValue: string;
  onConfirm: () => Promise<void>;
}

const SupplyModal = ({
  open,
  setOpen,
  pool,
  poolBalance,
  firstTokenAmount,
  secondTokenAmount,
  lpTokenTotalSupply,
  tabOptionValue,
  swapInfo,
  onConfirm
}: SupplyModalProps) => {
  const { swapService } = configs;

  const walletAddr = useRecoilValue(walletAddressSelector);

  let shareInPool = firstTokenAmount
    .div(poolBalance[0].plus(firstTokenAmount))
    .multipliedBy(100);

  let receiveLPAmount = firstTokenAmount
    .dividedBy(poolBalance[0])
    .multipliedBy(lpTokenTotalSupply);

  if (tabOptionValue === 'tokenA' && swapInfo) {
    shareInPool = firstTokenAmount
      .minus(swapInfo.inputAmount)
      .div(poolBalance[0].plus(swapInfo.inputAmount))
      .multipliedBy(100);
    receiveLPAmount = firstTokenAmount
      .minus(swapInfo.inputAmount)
      .dividedBy(poolBalance[0])
      .multipliedBy(lpTokenTotalSupply);
  } else if (tabOptionValue === 'tokenB' && swapInfo) {
    shareInPool = secondTokenAmount
      .minus(swapInfo.inputAmount)
      .div(poolBalance[1].plus(swapInfo.inputAmount))
      .multipliedBy(100);
    receiveLPAmount = secondTokenAmount
      .minus(swapInfo.inputAmount)
      .dividedBy(poolBalance[1])
      .multipliedBy(lpTokenTotalSupply);
  }

  return (
    <CustomModal
      title={<CustomModalTitle>You will receive</CustomModalTitle>}
      centered
      closable
      width={600}
      footer={null}
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
    >
      <CustomModalBodyContainer>
        <CustomModalContent>
          <div className="title">You will receive</div>
          <ReceiveInfoContainer>
            <span className={'receive_token'}>
              <TokenImg src={pool.tokenA.imageUrl} onError={imgErrorFallback} />
              <TokenImg src={pool.tokenB.imageUrl} onError={imgErrorFallback} />
              {pool.tokenA.symbol} {pool.tokenB.symbol} LP token
            </span>
            <span className={'receive_amount'}>
              {convertDecimal(receiveLPAmount, 18, 18)}
            </span>
          </ReceiveInfoContainer>
          <ShareContainer>
            <span className="title">Your share in pair</span>
            <span className="share">{shareInPool.toFixed(6)} %</span>
          </ShareContainer>
        </CustomModalContent>
        <Divider />
        <CustomModalContent>
          {tabOptionValue === 'all' ? (
            <SupplyModalInput
              poolBalance={poolBalance}
              pool={pool}
              firstTokenAmount={firstTokenAmount}
              secondTokenAmount={secondTokenAmount}
            />
          ) : (
            <SupplyModalSwapInput
              poolBalance={poolBalance}
              pool={pool}
              firstTokenAmount={firstTokenAmount}
              secondTokenAmount={secondTokenAmount}
              swapInfo={swapInfo}
            />
          )}
        </CustomModalContent>
        <CustomModalContent>
          <SlippageInfoContainer>
            <SlippageInfoTitle>Slippage tolerance</SlippageInfoTitle>
            <SlippageInfoContent>1%</SlippageInfoContent>
          </SlippageInfoContainer>
        </CustomModalContent>
        <CustomModalFooter>
          <ConfirmSupplyButton
            onClick={async () => {
              await onConfirm();
              setOpen(false);
            }}
          >
            Confirm Supply
          </ConfirmSupplyButton>
        </CustomModalFooter>
      </CustomModalBodyContainer>
    </CustomModal>
  );
};

export default SupplyModal;
