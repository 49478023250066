import { UserOutlined } from '@ant-design/icons';
import { Avatar as AntdAvatar, Dropdown } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import configs from '../../../../configs';
import { walletAddressSelector } from '../../../../recoil/wallet/selectors';
import { UserInfo } from '../../../../types';
import type { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { DefaultAvatar } from '../../../../components/DefaultAvatar';
import { convertDecimal } from '../../../../common/Utils';
import BigNumber from 'bignumber.js';
import useInterval from 'use-interval';

const avatarSize = 48;
const avatarOutline = 3;

const defaultUserInfo = {
  address: '',
  profileUrl: '',
  point: '0'
};

let gettingUserInfo = false;

export const UserAvatar = () => {
  const walletAddress = useRecoilValue(walletAddressSelector);
  const [userInfo, setUserInfo] = useState<UserInfo>(defaultUserInfo);
  const { profileApiService } = configs;

  const getUserInfo = async () => {
    if (gettingUserInfo) return;
    gettingUserInfo = true;
    try {
      if (walletAddress === '') {
        setUserInfo(defaultUserInfo);
        return;
      }
      const userInfo = await profileApiService.getUserInfo(walletAddress);
      setUserInfo({
        ...userInfo
      });
    } catch (e) {
      console.log(e);
    } finally {
      gettingUserInfo = false;
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [walletAddress]);

  useInterval(async () => {
    getUserInfo();
  }, 4000);

  const dropdownItems: MenuProps['items'] = [
    {
      key: 'my-assets',
      label: (
        <Link to={'/profile'}>
          <DropdownItem>
            <DropdownText>My Assets</DropdownText>
          </DropdownItem>
        </Link>
      )
    },
    {
      key: 'account-settings',
      label: (
        <DropdownItem>
          <DropdownText>Account Settings</DropdownText>
          <DropdownTag>Coming Soon</DropdownTag>
        </DropdownItem>
      ),
      disabled: true
    }
  ];

  return (
    <AvatarContainer>
      <Dropdown menu={{ items: dropdownItems }} placement={'bottomCenter'}>
        <Link to={'/profile'}>
          <AvatarOutline active={(walletAddress ?? '') !== ''}>
            <AvatarInner>
              {(userInfo.profileUrl ?? '') === '' ? (
                <Avatar
                  size={avatarSize - avatarOutline * 2}
                  icon={<DefaultAvatar width={'26px'} />}
                ></Avatar>
              ) : (
                <Avatar
                  size={avatarSize - avatarOutline * 2}
                  src={userInfo.profileUrl}
                ></Avatar>
              )}
            </AvatarInner>
          </AvatarOutline>
        </Link>
      </Dropdown>
      <UserPoint>
        HP {convertDecimal(new BigNumber(userInfo.point), 18, 2)}
      </UserPoint>
    </AvatarContainer>
  );
};

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled(AntdAvatar)``;

const AvatarOutline = styled.div<{ active?: boolean }>`
  cursor: pointer;
  width: ${avatarSize}px;
  height: ${avatarSize}px;
  border-radius: 50%;
  background-color: ${props => (props.active ? '#8247ff' : '#fff')};
  display: flex;
`;

const AvatarInner = styled.div`
  width: ${avatarSize - avatarOutline * 2}px;
  height: ${avatarSize - avatarOutline * 2}px;
  border-radius: 50%;
  background-color: #fff;
  margin: ${avatarOutline}px;
`;

const DropdownItem = styled.div`
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DropdownText = styled.div``;

const DropdownTag = styled.div`
  height: 20px;
  border-radius: 10px;
  background-color: #f3f3f3;
  font-size: 0.75em;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserPoint = styled.div`
  margin: 0 10px;
  border-radius: 10px;
  min-width: 50px;
  height: 20px;
  border: 1px solid #ccc;
  font-size: 12px;
  color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 0 10px;
`;
