import BigNumber from 'bignumber.js';
import {
  Address,
  Config,
  ProjectType,
  TxConfig,
  UserAsteroidStatus,
  WalletTxRes
} from '../../types';
import { getConfig } from '../../common/Utils';
import IconService from 'icon-sdk-js';
import axios from 'axios';

const { IconConverter, HttpProvider } = IconService;
const { CallTransactionBuilder, CallBuilder } = IconService.IconBuilder;

export class HavahAsteroidService {
  service: IconService;
  apiHost: string;
  asteroidAddress: Address = '';

  constructor(
    apiHost = 'http://localhost:3005',
    rpcHost = 'https://ctz.vega.havah.io/api/v3'
  ) {
    this.service = new IconService(new HttpProvider(rpcHost) as any);
    this.apiHost = apiHost;

    this.initialize();
  }

  private async initialize(): Promise<void> {
    try {
      const config = (await axios.get(`${this.apiHost}/configure`))
        .data as Config;

      const asteroid = config.asteroid;
      this.asteroidAddress = asteroid.address;
      console.log('HavahAsteroidService initialized');
    } catch (e) {
      console.log(e);
    }
  }

  private async sendTx(txObj: any): Promise<WalletTxRes> {
    return (await (window as any)?.havah?.sendTransaction({
      ...txObj,
      ...txObj?.data
    })) satisfies WalletTxRes;
  }

  async batchClaim(asteroids: UserAsteroidStatus[], { addr }: TxConfig) {
    const txObj = new CallTransactionBuilder()
      .from(addr)
      .to(this.asteroidAddress)
      .method('claimRewards')
      .params({
        _tokenIds: asteroids.map(a => a.id)
      })
      .build();

    return await this.sendTx(txObj);
  }
}
