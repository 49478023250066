import BigNumber from 'bignumber.js';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Address, Pool, Token } from 'types';
import PoolTable, { PoolTableSkeleton } from './PoolTable';

interface UserPoolBoardProps {
  pools: Pool[];
  poolTokenBalances: {
    [poolAddr: Address]: { [tokenAddr: Address]: BigNumber };
  };
}

const UserPoolBoard = ({ pools, poolTokenBalances }: UserPoolBoardProps) => {
  return (
    <UserPoolBoardContainer>
      <UserPoolBoardTitle>My Assets</UserPoolBoardTitle>
      <PoolTable pools={pools} poolTokenBalances={poolTokenBalances} isMyPool />
    </UserPoolBoardContainer>
  );
};

export const UserPoolBoardSkeleton = () => {
  return (
    <UserPoolBoardContainer>
      <UserPoolBoardTitle>My Assets</UserPoolBoardTitle>
      <PoolTableSkeleton />
    </UserPoolBoardContainer>
  );
};

const UserPoolBoardContainer = styled.div`
  margin-top: 1.875rem;
  background: #ffffff;
  border-radius: 0.75rem;
`;

const UserPoolBoardTitle = styled.p`
  width: 100%;
  font-size: 1rem;
  padding: 20px;
  border-radius: 0.75rem 0.75rem 0 0;
  margin-bottom: 0;
`;

export default UserPoolBoard;
