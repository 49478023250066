import React from 'react';
import { Modal, Table } from 'antd';
import styled from 'styled-components';
import { AsteroidHistory, UserAsteroidStatus } from '../../../types';
import type { ColumnsType } from 'antd/es/table';
import { convertDecimal } from '../../../common/Utils';
import _ from 'lodash';

interface AsteroidClaimModalProps {
  onClose: () => void;
  asteroid: UserAsteroidStatus;
  history: AsteroidHistory;
}

interface DataType {
  date: Date;
  reward: string;
}
const columns: ColumnsType<DataType> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    render: (date: Date) => {
      return date.toLocaleString();
    }
  },
  {
    title: 'Rewards',
    dataIndex: 'reward',
    key: 'reward',
    align: 'center'
  }
];

export const AsteroidHistoryModal = ({
  onClose,
  asteroid,
  history
}: AsteroidClaimModalProps) => {
  if (_.isEmpty(history.history)) {
    return (
      <AsteroidHistoryModalContainer
        title={'Reward History'}
        open={true}
        closable={false}
        cancelText={'Close'}
        centered={true}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={onClose}
      >
        <AsteroidHistoryModalModalContent>
          <EmptyText>No records found.</EmptyText>
        </AsteroidHistoryModalModalContent>
      </AsteroidHistoryModalContainer>
    );
  }

  const dataList: DataType[] = history.history
    .map(it => ({
      date: it.timestamp,
      reward: convertDecimal(it.reward, 18, 18)
    }))
    .sort((a, b) => b.date.getTime() - a.date.getTime());

  return (
    <AsteroidHistoryModalContainer
      title={`Asteroid#${asteroid.id} History`}
      open={true}
      closable={false}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText={'Close'}
      centered={true}
      onCancel={onClose}
    >
      <AsteroidHistoryModalModalContent>
        <ClaimableTable columns={columns} dataSource={dataList} />
      </AsteroidHistoryModalModalContent>
    </AsteroidHistoryModalContainer>
  );
};

const AsteroidHistoryModalContainer = styled(Modal)`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    min-width: 600px;

    .ant-modal-title {
      color: #000000;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
    }

    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-btn-default {
        width: 100%;
        height: 40px;
        border: 1px solid #999999;
        color: #999999;
        &:hover {
          color: #999999;
          border: 1px solid #999999;
        }
      }

      .ant-btn-primary {
        width: 100%;
        height: 40px;
        background-color: #8247ff;
      }

      span {
        margin: 0 auto;
      }
    }
  }
`;

const AsteroidHistoryModalModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ClaimableTable = styled(Table<DataType>)`
  margin-top: 20px;
  width: 100%;
  .ant-table-thead th.ant-table-cell {
    background: black;
    color: white;
    font-weight: normal;
    &:before {
      border: none;
      display: none;
    }
  }
  .ant-pagination .ant-pagination-item-active {
    background: #8247ff;
    border-radius: 20px;
    border: none;
    a {
      color: white;
    }
  }
  .ant-pagination-item {
    border-radius: 20px;
    border: none;
  }
  .ant-table-footer {
    text-align: right;
  }
`;

const EmptyText = styled.div`
  font-size: 15px;
  height: 150px;
  font-weight: 400;
  text-align: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
`;
