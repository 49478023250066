import React from 'react';
import styled from 'styled-components';

const AmountInput = styled.input`
  font-size: 25px;
  font-weight: bold;
  border: none;
  text-align: right;
  outline: none;
  width: 400px;
`;

type SimpleAmountInputProps = {
  amount: string;
  onInputChange: (input: string) => void;
};

const SimpleAmountInput = ({
  amount,
  onInputChange
}: SimpleAmountInputProps) => {
  return (
    <AmountInput
      onChange={e => {
        const userInput = e.target.value;
        if (!userInput || userInput === '') {
          onInputChange('');
          return;
        }
        if (!userInput.match(/^[+-]?\d+(\.\d*)?$/)) return;
        if (
          userInput.startsWith('0') &&
          !userInput.startsWith('0.') &&
          userInput.length > 1
        ) {
          onInputChange(userInput.slice(1));
          return;
        }
        onInputChange(e.target.value);
      }}
      value={amount}
    ></AmountInput>
  );
};

export default SimpleAmountInput;
