import React, { Suspense, useEffect, useState } from 'react';
import configs from '../../configs';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectInfo } from 'types';
import { ProfilePageContainer, ProfileCommonContainer } from './common/styles';
import ProfileContainer from '../../components/BannerProfile';
import NftProjectInfo from './assets/components/NftProjectInfo';
import styled from 'styled-components';
import { DefaultAvatar } from '../../components/DefaultAvatar';
import { Tabs } from '../../components/Tabs';
import { Skeleton } from 'antd';
import type { TabsProps } from 'antd';
import { walletAddressSelector } from '../../recoil/wallet/selectors';
import { useRecoilValue } from 'recoil';
import { Items, ItemsFallback } from './items/Items';
import { Tokens, TokensFallback } from './tokens/Tokens';
import { Asteroid, AsteroidFallback } from './asteroid/Asteroid';

const ProfilePage = () => {
  const { tab } = useParams();

  const navigate = useNavigate();

  const { marketApiService: havahMarketApiService, profileApiService } =
    configs;

  const walletAddress = useRecoilValue(walletAddressSelector);
  const [profileUrl, setProfileUrl] = useState<string | undefined>();

  const { projectAddress = '' } = useParams();
  const [projectInfo, setProjectInfo] = useState<ProjectInfo | null>(null);

  const getProjectInfo = async (address: string) => {
    const projectInfo = await havahMarketApiService.getProjectInfoByAddress(
      address
    );
    setProjectInfo(projectInfo);
  };

  useEffect(() => {
    if (projectAddress != '') {
      getProjectInfo(projectAddress);
    }
  }, [projectAddress]);

  useEffect(() => {
    (async () => {
      if (walletAddress === '') {
        setProfileUrl(undefined);
        return;
      }
      const userInfo = await profileApiService.getUserInfo(walletAddress);
      setProfileUrl(userInfo.profileUrl);
    })();
  }, [walletAddress]);

  const items: TabsProps['items'] = [
    {
      key: 'token',
      label: 'Token',
      children: (
        <Suspense fallback={<TokensFallback />}>
          <Tokens walletAddress={walletAddress} refreshIdx={Math.random()} />
        </Suspense>
      )
    },
    {
      key: 'items',
      label: 'Items',
      children: (
        <></>
        // <Suspense fallback={<ItemsFallback />}>
        //   <Items walletAddress={walletAddress} refreshIdx={Math.random()} />
        // </Suspense>
      ),
      disabled: true
    },
    {
      key: 'asteroid',
      label: 'Asteroid',
      children: (
        <Suspense fallback={<AsteroidFallback />}>
          <Asteroid walletAddress={walletAddress} refreshIdx={Math.random()} />
        </Suspense>
      ),
      disabled: false
    },
    {
      key: 'activity',
      label: 'Activity',
      children: 'Content of Tab Pane 3',
      disabled: true
    },
    {
      key: 'favorites',
      label: 'Favorites',
      children: 'Content of Tab Pane 4',
      disabled: true
    },
    {
      key: 'offers',
      label: 'Offers',
      children: 'Content of Tab Pane 5',
      disabled: true
    }
  ];

  return (
    <ProfilePageContainer>
      <ProfileContainer
        profileImg={profileUrl}
        backgroundImgUrl={projectInfo?.imgBackgroundUrl}
        snsInfos={projectInfo?.snsInfos}
      />
      <ProfileCommonContainer>
        <Tabs
          items={items}
          defaultActiveKey={tab ?? 'token'}
          onChange={key => {
            navigate(`/profile/${key}`);
          }}
        />
      </ProfileCommonContainer>
    </ProfilePageContainer>
  );
};

export default ProfilePage;
