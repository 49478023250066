import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Footer from './footer';
import Header from './header';
import SystemMaintenanceContaner from '../../components/SystemMaintenanceContainer';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  return (
    <MainContainer>
      <Header />
      <Outlet />
      <Footer />
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  & > div {
    flex-shrink: 0;
  }
  & > div:nth-child(2) {
    flex-grow: 1;
  }
`;
