import React from 'react';
import { Tabs as AntdTabs } from 'antd';
import type { TabsProps } from 'antd';
import styled from 'styled-components';

export const Tabs = styled(AntdTabs)`
  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      padding: 0 20px;
      color: #cccccc;
    }
    :hover {
      color: #000 !important;
    }
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #000 !important;
    }
  }

  .ant-tabs-ink-bar {
    background-color: #000;
  }
`;
