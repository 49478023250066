import React from 'react';
import styled from 'styled-components';

const AvatarWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #f9f5ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface DefaultAvatarProps {
  width?: string;
  height?: string;
}

export const DefaultAvatar = ({ width, height }: DefaultAvatarProps) => (
  <AvatarWrapper>
    <img
      src={'/assets/avatar-default.png'}
      alt={'default avatar'}
      style={{
        width,
        height
      }}
    ></img>
  </AvatarWrapper>
);
