import styled from 'styled-components';

export const PoolCommonContainer = styled.div`
  width: 100%;
`;

export const PoolTitleContainer = styled.div`
  margin-top: 5rem;
  background: none;
`;

export const SupplyAndWithdrawButtonBox = styled.div<{ disabled?: boolean }>`
  margin-top: 30px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background: ${props => (props.disabled ? '#bbbbbb' : '#f56666')};
  border-radius: 0.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 1rem 1.125rem;

  span {
    font-size: 0.75rem;
  }
`;

export const SupplyAndWithdrawButtonBoxTitle = styled.div`
  font-size: 1.375rem;
  font-weight: 700;
`;

export const AdditionalInfoContainer = styled.div`
  margin-top: 3rem;
  padding: 1.25rem 2.5rem;
  border-top: 1px dashed #eaeaea;
`;

export const AdditionalInfoMainTitleBox = styled.div``;

export const AdditionalInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 11px;
  font-weight: 400;
`;

export const AdditionalInfoRowTitle = styled.div`
  color: #666666;
`;

export const AdditionalInfoRowValue = styled.div`
  color: #4d4d4d;
`;

export const RouterAddressInputBox = styled.div`
  margin-top: 50px;
`;
