import { atom } from 'recoil';
import { Token } from '../../types';

export const tokenListAtom = atom<Token[]>({
  key: 'tokenListAtom',
  default: []
});

export const tokenPricesAtom = atom<{ [key: string]: number }>({
  key: 'tokenPriceAtom',
  default: {}
});
