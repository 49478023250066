import React, { useContext, useEffect, useState } from 'react';
import {
  Burger,
  DesktopNavWrapper,
  Label,
  MobileNavWrapper,
  NavArea,
  NavCloseOutlined,
  NavContainer,
  NavItem,
  NavItemContainer,
  NavLeft,
  NavMenu,
  NavRight,
  Outline
} from './styles';
import { Drawer } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  walletAddressSelector,
  walletSortAddrSelector
} from '../../../recoil/wallet/selectors';
import { NotiContext } from '../../../index';
import { Wallet } from './Wallet/Wallet';
import { useCookies } from 'react-cookie';

const Header = () => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [walletAddress, setWalletAddress] = useRecoilState(
    walletAddressSelector
  );
  const [cookie, setCookie] = useCookies(['walletAddress']);

  const walletShortAddr = useRecoilValue(walletSortAddrSelector);
  const toggleDrawer = () => setVisibleDrawer(!visibleDrawer);

  const [currentLang, setCurrentLang] = useState('ko');
  const [openConnectWallet, setOpenConnectWallet] = useState(false);
  const navigate = useNavigate();

  const { notify } = useContext(NotiContext);

  const onClickNavItem = (navTarget: string) => {
    // handleMenuClick(navTarget);
    // navigate(`/${navTarget}`, { replace: true });
  };

  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState('');

  useEffect(() => {
    const path = location.pathname;
    handleActiveMenuEffect(path);
  }, [location]);

  const handleActiveMenuEffect = (path: string) => {
    if (path.includes('/swap')) {
      setActiveMenu('swap');
    } else if (path.includes('/market')) {
      setActiveMenu('market');
    } else if (path.includes('/liquidity-pool')) {
      setActiveMenu('liquidity-pool');
    } else if (path.includes('/farm')) {
      setActiveMenu('farm');
    } else if (path.includes('/launchpad')) {
      setActiveMenu('launchpad');
    }
  };

  const handleMenuClick = (menu: string) => {
    setActiveMenu(menu);
    navigate(`/${menu}`);
  };

  const NavItems = () => {
    return (
      <NavItemContainer>
        <Link to={'/liquidity-pool'}>
          <NavItem
            isActive={activeMenu == 'liquidity-pool'}
            value="liquidity-pool"
            activeColor={'#f56666'}
          >
            Liquidity Pool
          </NavItem>
        </Link>
        <Link to={'/farm'}>
          <NavItem
            isActive={activeMenu == 'farm'}
            value="farm"
            activeColor={'#f56666'}
          >
            Farm
          </NavItem>
        </Link>
      </NavItemContainer>
    );
  };

  return (
    <NavArea>
      <NavContainer>
        <NavLeft>
          <Link to={'/'}>
            {activeMenu && activeMenu == 'market' ? (
              <img src="/logo/havahswap_logo_full_market.svg" alt="navLogo" />
            ) : (
              <img src="/logo/havahswap_logo_full.svg" alt="navLogo" />
            )}
          </Link>
        </NavLeft>
        <NavRight>
          <DesktopNavWrapper>
            <NavMenu>
              <NavItems />
            </NavMenu>
            <Wallet isMarket={activeMenu == 'market'} />
          </DesktopNavWrapper>
          <Burger onClick={toggleDrawer}>
            <Outline />
          </Burger>
          <Drawer
            closable={false}
            open={visibleDrawer}
            onClose={toggleDrawer}
            drawerStyle={{ backgroundColor: '#111420' }}
          >
            <MobileNavWrapper>
              <Label onClick={toggleDrawer}>
                <NavCloseOutlined />
              </Label>
              <NavItems />
            </MobileNavWrapper>
          </Drawer>
        </NavRight>
      </NavContainer>
    </NavArea>
  );
};

export default Header;
