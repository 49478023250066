import { atom } from 'recoil';

export interface TransactionAtom {
  pending: boolean;
  pendingMessage?: string;
}

export const transactionAtom = atom<TransactionAtom>({
  key: 'transactionAtom',
  default: {
    pending: false,
    pendingMessage: undefined
  }
});
