import React, { Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import MainLayout from '../layout/main';
import SwapPage from '../pages/swap';
import LiquidityPoolPage, {
  LiquidityPoolPageSkeleton
} from '../pages/pool/pool-main';
import PoolDetailPage from '../pages/pool/pool-detail';
import PoolCreatePage from '../pages/pool/pool-create';
import MarketMainPage from '../pages/market/market-main';
import MarketProjectPage, {
  MarketProjectPageSkeleton
} from '../pages/market/market-project';
import TxTestPage from '../pages/tx-tests';
import ProfilePage from '../pages/profile/ProfilePage';
import MarketListForSalePage from '../pages/market-list-for-sale';
import { NftItemPage, NftItemPageSkeleton } from '../pages/market/nft-item';
import StakePage from '../pages/stake';
import { StakePoolsSkeleton } from '../pages/stake/components/StakePools';
import LaunchpadProject, {
  LaunchpadProjectSkeleton
} from '../pages/launchpad/project';
import { ConfigProvider } from 'antd';
import {
  LaunchpadMain,
  LaunchpadMainSkeleton
} from 'pages/launchpad/launchpad-main/LaunchpadMain';

export default function Router() {
  return useRoutes([
    {
      element: <MainLayout />,
      children: [
        // {
        //   path: 'swap',
        //   element: <SwapPage />
        // },
        {
          path: 'liquidity-pool',
          element: (
            <Suspense fallback={<LiquidityPoolPageSkeleton />}>
              <LiquidityPoolPage refreshIdx={Math.random()} />
            </Suspense>
          )
        },
        {
          path: 'liquidity-pool/detail/:poolAddress',
          element: (
            <Suspense>
              <PoolDetailPage />
            </Suspense>
          )
        },
        // {
        //   path: 'liquidity-pool/create/havah-swap/admin',
        //   element: (
        //     <Suspense>
        //       <PoolCreatePage />
        //     </Suspense>
        //   )
        // },
        // {
        //   path: 'market',
        //   element: (
        //     <Suspense>
        //       <MarketMainPage />
        //     </Suspense>
        //   )
        // },
        // {
        //   path: 'market/collection/:projectAddress',
        //   element: (
        //     <Suspense fallback={<MarketProjectPageSkeleton />}>
        //       <ConfigProvider
        //         theme={{
        //           token: {
        //             colorPrimary: '#8247FF'
        //           }
        //         }}
        //       >
        //         <MarketProjectPage refreshIdx={Math.random()} />
        //       </ConfigProvider>
        //     </Suspense>
        //   )
        // },
        // {
        //   path: 'market/collection/:projectAddress/:nftId/:ownerAddress',
        //   element: (
        //     <Suspense fallback={<NftItemPageSkeleton />}>
        //       <NftItemPage refreshIdx={Math.random()} />
        //     </Suspense>
        //   )
        // },
        {
          path: 'profile',
          element: (
            <Suspense>
              <ProfilePage />
            </Suspense>
          )
        },
        {
          path: 'profile/:tab',
          element: (
            <Suspense>
              <ProfilePage />
            </Suspense>
          )
        },
        {
          path: 'tx-test',
          element: (
            <Suspense>
              <TxTestPage />
            </Suspense>
          )
        },
        // {
        //   path: 'market/list-for-sale/:projectAddress/:nftId',
        //   element: (
        //     <Suspense>
        //       <MarketListForSalePage />
        //     </Suspense>
        //   )
        // },
        {
          path: 'farm',
          element: (
            <Suspense fallback={<StakePoolsSkeleton />}>
              <StakePage />
            </Suspense>
          )
        }
        // {
        //   path: 'launchpad',
        //   element: (
        //     <Suspense fallback={<LaunchpadMainSkeleton />}>
        //       <ConfigProvider
        //         theme={{
        //           token: {
        //             colorPrimaryActive: '#fff',
        //             colorPrimaryHover: '#fff'
        //           }
        //         }}
        //       >
        //         <LaunchpadMain />
        //       </ConfigProvider>
        //     </Suspense>
        //   )
        // },
        // {
        //   path: 'launchpad/:projectId',
        //   element: (
        //     <Suspense fallback={<LaunchpadProjectSkeleton />}>
        //       <ConfigProvider
        //         theme={{
        //           token: {
        //             colorPrimaryActive: '#fff',
        //             colorPrimaryHover: '#fff'
        //           }
        //         }}
        //       >
        //         <LaunchpadProject />
        //       </ConfigProvider>
        //     </Suspense>
        //   )
        // }
      ]
    },
    { path: '*', element: <Navigate to="liquidity-pool" replace /> }
  ]);
}
