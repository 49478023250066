import BigNumber from 'bignumber.js';
import { ProjectType, TxConfig, WalletTxRes, Config } from '../../types';
import { NftService } from '../NftService';
import { convertDecimal, getConfig } from '../../common/Utils';
import IconService from 'icon-sdk-js';
import axios from 'axios';

const { IconConverter, HttpProvider } = IconService;
const { CallTransactionBuilder, CallBuilder } = IconService.IconBuilder;

export class HavahNftService implements NftService {
  service: IconService;
  apiHost: string;
  defaultSlipage = new BigNumber('100'); // 1%
  marketAddress = '';
  hsp1155MarketAddress = '';
  baseTokenAddr = 'cx0000000000000000000000000000000000000000';

  constructor(
    apiHost = 'http://localhost:3005',
    rpcHost = 'https://ctz.vega.havah.io/api/v3'
  ) {
    this.service = new IconService(new HttpProvider(rpcHost) as any);
    this.apiHost = apiHost;
    this.marketAddress = '';

    this.initialize();
  }

  private async initialize(): Promise<void> {
    try {
      // const configure = await getConfig(this.apiHost);
      const configure = (await axios.get(`${this.apiHost}/configure`))
        .data as Config;
      this.marketAddress = configure?.market?.address ?? '';
      this.hsp1155MarketAddress = configure?.hsp1155Market?.address ?? '';
      if (configure?.havah?.rpcHost) {
        this.service = new IconService(
          new HttpProvider(configure?.havah?.rpcHost) as any
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  private async sendTx(txObj: any): Promise<WalletTxRes> {
    return (await (window as any)?.havah?.sendTransaction({
      ...txObj,
      ...txObj?.data
    })) satisfies WalletTxRes;
  }

  async mint(
    nftAddr: string,
    nftId: string,
    txConfig: TxConfig
  ): Promise<WalletTxRes> {
    const txObj = new CallTransactionBuilder()
      .from(txConfig.addr)
      .to(nftAddr)
      .method('mint')
      .params({
        _to: txConfig.addr,
        _tokenId: nftId
      })
      .build();
    return await this.sendTx(txObj);
  }

  async isApprovedForAll(
    nftAddr: string,
    operator: string,
    txConfig: TxConfig
  ): Promise<boolean> {
    const allowTxObj = new CallBuilder()
      .to(nftAddr)
      .method('isApprovedForAll')
      .params({
        _owner: txConfig.addr,
        _operator: operator
      })
      .build();
    return (
      parseInt((await this.service.call(allowTxObj).execute()) as string) === 1
    );
  }

  async setApprovalForAll(
    nftAddr: string,
    operator: string,
    txConfig: TxConfig
  ): Promise<WalletTxRes> {
    const allowTxObj = new CallTransactionBuilder()
      .from(txConfig.addr)
      .to(nftAddr)
      .method('setApprovalForAll')
      .params({
        _operator: operator,
        _approved: '1'
      })
      .build();
    return await this.sendTx(allowTxObj);
  }

  async retrieveApprovalForAll(
    nftAddr: string,
    operator: string,
    txConfig: TxConfig
  ): Promise<WalletTxRes> {
    const allowTxObj = new CallTransactionBuilder()
      .from(txConfig.addr)
      .to(nftAddr)
      .method('setApprovalForAll')
      .params({
        _operator: operator,
        _approved: '0'
      })
      .build();
    return await this.sendTx(allowTxObj);
  }

  async putOnSale(
    type: ProjectType,
    nftAddr: string,
    nftId: string,
    priceContract: string,
    price: BigNumber,
    amount: BigNumber,
    durationDays: number,
    txConfig: TxConfig
  ): Promise<WalletTxRes> {
    const expiration = new BigNumber(new Date().getTime())
      .plus(
        new BigNumber(durationDays)
          .multipliedBy(24)
          .multipliedBy(60)
          .multipliedBy(60)
          .multipliedBy(1000)
      )
      .multipliedBy(1000);

    let txObj = new CallTransactionBuilder().from(txConfig.addr);
    if (type === 'hsp1155') {
      txObj = txObj
        .to(this.hsp1155MarketAddress)
        .method('putOnSaleWithExpiration')
        .params({
          hsp1155Contract: nftAddr,
          tokenId: nftId,
          priceContract,
          price: price.toFixed(0),
          amount: amount.toFixed(0),
          expiration: expiration.toFixed(0)
        });
    } else {
      txObj = txObj
        .to(this.marketAddress)
        .method('putOnSaleWithExpiration')
        .params({
          hsp721Contract: nftAddr,
          tokenId: nftId,
          priceContract,
          price: price.toFixed(0),
          expiration: expiration.toFixed(0)
        });
    }

    return await this.sendTx(txObj.build());
  }

  async cancelSale(
    type: ProjectType,
    nftAddr: string,
    nftId: string,
    price: BigNumber,
    txConfig: TxConfig
  ): Promise<WalletTxRes> {
    let cancelTxObj = new CallTransactionBuilder().from(txConfig.addr);
    if (type === 'hsp721') {
      cancelTxObj = cancelTxObj
        .to(this.marketAddress)
        .method('cancelSale')
        .params({
          hsp721Contract: nftAddr,
          tokenId: nftId
        });
    } else if (type === 'hsp1155') {
      cancelTxObj = cancelTxObj
        .to(this.hsp1155MarketAddress)
        .method('cancelSale')
        .params({
          hsp1155Contract: nftAddr,
          tokenId: nftId,
          seller: txConfig.addr,
          price: price.toFixed(0)
        });
    }
    return await this.sendTx(cancelTxObj.build());
  }
  async buySale(
    type: ProjectType,
    nftAddr: string,
    nftId: string,
    priceContract: string,
    price: BigNumber,
    amount: BigNumber,
    seller: string,
    txConfig: TxConfig
  ): Promise<WalletTxRes> {
    let txBuilder = new CallTransactionBuilder()
      .from(txConfig.addr)
      .method('buyInHVH');
    if (type === 'hsp721') {
      txBuilder = txBuilder.to(this.marketAddress).params({
        hsp721Contract: nftAddr,
        tokenId: nftId
      });
    } else if (type === 'hsp1155') {
      txBuilder = txBuilder.to(this.hsp1155MarketAddress).params({
        seller: seller,
        price: price.toFixed(0),
        amount: amount.toFixed(0),
        hsp1155Contract: nftAddr,
        tokenId: nftId
      });
    }

    if (priceContract === this.baseTokenAddr) {
      const marginPrice = price
        .multipliedBy(amount)
        .plus(price.dividedToIntegerBy(new BigNumber('10000')));
      txBuilder = txBuilder.value(parseFloat(convertDecimal(marginPrice)));
    } else {
      txBuilder = txBuilder.method('buyInHSP20').params({
        hsp721Contract: nftAddr,
        tokenId: nftId,
        value: price.multipliedBy(amount).toFixed()
      });
    }
    const allowTxObj = txBuilder.build();
    return await this.sendTx(allowTxObj);
  }
}
