import React, { useEffect } from 'react';
import styled from 'styled-components';
import TokenPairBox from '../../../../components/TokenPairBox';
import ColoredDecimal from '../../../../components/ColoredDecimal';
import BigNumber from 'bignumber.js';
import { Pool } from 'types';
import { convertDecimal, dummyToken } from 'common/Utils';

interface PoolDetailDashboardProps {
  pool: Pool;
  poolBalance: BigNumber[];
  lpTokenBalance: BigNumber;
  lpTokenTotalSupply: BigNumber;
}

const PoolDetailDashboard = ({
  pool,
  poolBalance,
  lpTokenBalance,
  lpTokenTotalSupply
}: PoolDetailDashboardProps) => {
  const isClaimButtonDisabled = () => {
    //TODO: add condition for claim button
    return true;
  };

  const myPoolShare = lpTokenBalance.dividedBy(lpTokenTotalSupply);
  const myPoolSharePercentage = myPoolShare.multipliedBy(100).toFixed(4);

  const myTokenASupply = poolBalance[0].multipliedBy(myPoolShare);
  const myTokenBSupply = poolBalance[1].multipliedBy(myPoolShare);

  return (
    <DashboardContainer>
      <PoolTitleContainer>
        <TokenPairBox
          tokenA={pool?.tokenA || dummyToken}
          tokenB={pool?.tokenB || dummyToken}
          iconSize="48px"
          showTokenName={false}
        />
        <span style={{ marginLeft: '16px' }}>
          {pool?.tokenA.name} + {pool?.tokenB.name}
        </span>
      </PoolTitleContainer>
      <LpAndClaimContainer>
        <LpAndClaimInfoBox>
          <LpAndClaimTitleBox>
            <LpAndClaimTitle>My Lp Supply</LpAndClaimTitle>
            <span>Share of Pool {myPoolSharePercentage}%</span>
          </LpAndClaimTitleBox>
          <LpAndClaimOptionBox>
            <OptionKey>{pool?.tokenA.name}</OptionKey>
            <OptionValue>
              <ColoredDecimal
                amount={convertDecimal(
                  myTokenASupply.toFixed(4),
                  pool.tokenA.decimal
                )}
                numberSize="14px"
                unitSize="14px"
                showUnit={false}
              />
            </OptionValue>
          </LpAndClaimOptionBox>
          <LpAndClaimOptionBox>
            <OptionKey>{pool?.tokenB.name}</OptionKey>
            <OptionValue>
              <ColoredDecimal
                amount={convertDecimal(
                  myTokenBSupply.toFixed(4),
                  pool.tokenB.decimal
                )}
                numberSize="14px"
                unitSize="14px"
                showUnit={false}
              />
            </OptionValue>
          </LpAndClaimOptionBox>
        </LpAndClaimInfoBox>
        {/* <LpAndClaimInfoBox>
          <LpAndClaimTitleBox>
            <LpAndClaimTitle>Claimable</LpAndClaimTitle>
            <span>Share of Pool {myPoolSharePercentage}%</span>
          </LpAndClaimTitleBox>
          <LpAndClaimButtonBox>
            <LpAndClaimOptionBox>
              <OptionKey></OptionKey>
              <OptionValue>
                <ColoredDecimal amount="0" numberSize="14px" unitSize="14px" />
              </OptionValue>
            </LpAndClaimOptionBox>
            <ClaimRewardButton disabled={isClaimButtonDisabled()}>
              Claim Reward
            </ClaimRewardButton>
          </LpAndClaimButtonBox>
        </LpAndClaimInfoBox> */}
      </LpAndClaimContainer>
    </DashboardContainer>
  );
};

export default PoolDetailDashboard;

const DashboardContainer = styled.div`
  width: 100%;
`;

const PoolTitleContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  align-items: center;
  font-size: 1.625rem;
  font-weight: 500;
  color: #333333;
  background: none;
`;

const LpAndClaimContainer = styled.div`
  margin-top: 1.875rem;
  display: flex;
`;

const LpAndClaimTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  color: #4d4d4d;
  font-size: 0.8125rem;
`;

const LpAndClaimTitle = styled.span`
  font-size: 0.9375rem;
  font-weight: 500;
`;

const LpAndClaimOptionBox = styled.div`
  width: 100%;
  padding: 0.8125rem 0.9375rem;
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  border-radius: 0.25rem;

  &:not(:first-child) {
    margin-top: 0.375rem;
  }
`;

const OptionKey = styled.span`
  font-size: 0.6875rem;
  color: #999999;
`;

const OptionValue = styled.span`
  font-size: 0.75rem;
`;

const LpAndClaimInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  border-radius: 0.625rem;

  /* &:last-child {
    margin-left: 0.5rem;
  } */
`;

const LpAndClaimButtonBox = styled.div`
  display: flex;
  align-items: center;
`;

const ClaimRewardButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  justify-content: center;
  width: 100%;
  color: ${props => (props.disabled ? '#b3b3b3' : '#f56666')};
  border: 1px solid ${props => (props.disabled ? '#e6e6e6' : '#f56666')};
  border-radius: 4px;
  font-size: 13px;
  height: 100%;
`;
