import styled, { css } from 'styled-components';
// import '../../public/fonts/fonts.css';
import { Link as ReactLink } from 'react-router-dom';

export const displayFlexAlignCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const disableSelectTextBlock = css`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const addSizeProps = css<any>`
  ${({ width, height, minWidth, minHeight, maxWidth, maxHeight }) => {
    return css`
      width: ${width || 'auto'};
      height: ${height || 'auto'};
      min-width: ${minWidth || ''};
      min-height: ${minHeight || ''};
      max-width: ${maxWidth || ''};
      max-height: ${minHeight || ''};
    `;
  }}
`;

export const addSpaceProps = css<any>`
  ${({ margin, padding }) => {
    return css`
      margin: ${margin || '0'};
      padding: ${padding || '0'};
    `;
  }}
`;

export const addTextProps = css<any>`
  ${({ color, fontSize, fontWeight, fontFamily, textAlign }) => {
    return css`
      color: ${color || 'white'};
      font-size: ${fontSize || 'medium'};
      font-weight: ${fontWeight || 'normal'};
      text-align: ${textAlign || 'center'};
    `;
  }}
`;

export const addFlexProps = css<any>`
  ${({ flexDirection, justifyContent, alignItems }) => {
    return css`
      display: flex;
      flex-direction: ${flexDirection || 'column'};
      justify-content: ${justifyContent || 'center'};
      align-items: ${alignItems || 'center'};
    `;
  }}
`;

export const FlexDiv = styled.div<any>`
  ${addFlexProps}
  ${addSizeProps}
`;

export const AlignCenterDiv = styled.div<any>`
  ${p => p.width || (p.width = '100%')}
  ${p => p.height || (p.height = '100%')}
  ${addSizeProps}
  ${displayFlexAlignCenter};
`;

export const TextBox = styled.div<any>`
  ${addSizeProps}
  ${addSpaceProps}
  ${addTextProps}
  ${disableSelectTextBlock}
`;

export const SpaceDiv = styled.div<any>`
  ${addSizeProps}
`;

export const Image = styled.img<any>`
  ${addSizeProps}
  ${addSpaceProps}
  ${disableSelectTextBlock}
`;

// noinspection CssInvalidPropertyValue
export const GradientBorderDiv = styled.div<any>`
  width: 100%;
  height: ${p => p.height || '100%'};

  background-color: ${p => p.backgroundColor || 'rgba(0, 0, 0, 0.5)'};
  border-radius: ${p => p.borderRadius || '30px'};
  position: relative;
  padding: ${p => (p.border ? 'calc(' + p.border + ' + 5px)' : '5px')};

  overflow: hidden;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    padding: ${p => p.border || '2px'};
    border-radius: ${p => p.borderRadius || '30px'};
    background: linear-gradient(#736b71cc, #736b7101),
      linear-gradient(to right, #736b71cc, #736b7177, #736b7105);
    -webkit-mask: linear-gradient(#fff, 0, 0) content-box,
      linear-gradient(#fff, 0, 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export const Message = styled.div`
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 25px;
  drop-shadow(0px 15px 30px rgba(100, 100, 100, 0.4));
`;

export const SuccessMessage = styled(Message)`
  background-color: #1cd17c;
  drop-shadow(0px 15px 30px rgba(40, 195, 135, 0.4));
`;

export const ErrorMessage = styled(Message)`
  background-color: #f23636;
  filter: drop-shadow(0px 15px 30px rgba(221, 6, 50, 0.4));
`;

export const Link = styled(ReactLink)`
  color: inherit;
  text-decoration: inherit;

  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
`;
