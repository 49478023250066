import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Divider } from 'antd';
import {
  convertBN,
  convertDecimal,
  imgErrorFallback,
  sleep
} from '../../../../common/Utils';
import configs from '../../../../configs';
import { useRecoilState, useRecoilValue } from 'recoil';
import { walletAddressSelector } from '../../../../recoil/wallet/selectors';
import BigNumber from 'bignumber.js';
import {
  ConfirmSupplyButton,
  CustomModal,
  CustomModalBodyContainer,
  CustomModalContent,
  CustomModalFooter,
  CustomModalTitle,
  CancelButton
} from './styles';
import StakeInput from './StakeInput';
import { StakeInfo } from 'types';
import { transactionAtom } from '../../../../recoil/transaction/atom';

interface SupplyModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  stakeInfo: StakeInfo;
  hvhReward: string;
  tabOptionValue: string;
  lpName: string;
  onConfirm: () => Promise<void>;
  notifyError: () => void;
}

const StakeModal = ({
  open,
  setOpen,
  stakeInfo,
  hvhReward,
  tabOptionValue,
  lpName,
  onConfirm,
  notifyError
}: SupplyModalProps) => {
  const [_, setTransaction] = useRecoilState(transactionAtom);
  const [stakingAmount, setStakingAmount] = useState('0');
  const [unStakingAmount, setUnStakingAmount] = useState('0');
  const [lpAmount, setLpAmount] = useState('0');
  const { swapService, stakingService } = configs;
  const walletAddress = useRecoilValue(walletAddressSelector);

  const onInputAmountChange = (amount: string) => {
    if (tabOptionValue == 'stake') {
      setStakingAmount(amount);
    } else {
      setUnStakingAmount(amount);
    }
  };

  const renderModalTitle = () => {
    if (tabOptionValue == 'stake') {
      return <span>Stake LP Tokens</span>;
    } else {
      return <span>Unstake LP Tokens</span>;
    }
  };

  const renderStakeInput = () => {
    if (tabOptionValue == 'stake') {
      return (
        <StakeInput
          balance={new BigNumber(stakeInfo.userLpBalance)}
          amount={stakingAmount}
          lpName={lpName}
          onAmountChange={(amount: string) => {
            onInputAmountChange(amount);
          }}
        />
      );
    } else {
      return (
        <StakeInput
          balance={new BigNumber(stakeInfo.userStakeBalance)}
          amount={unStakingAmount}
          lpName={lpName}
          onAmountChange={(amount: string) => {
            onInputAmountChange(amount);
          }}
        />
      );
    }
  };

  const renderStakeOrUnStakeButton = () => {
    if (tabOptionValue == 'stake') {
      return (
        <ConfirmSupplyButton
          onClick={async () => {
            await onSubmitStaking();
            setOpen(false);
            onConfirm();
          }}
        >
          Confirm Stake
        </ConfirmSupplyButton>
      );
    } else {
      return (
        <ConfirmSupplyButton
          onClick={async () => {
            await onSubmitUnstaking();
            setOpen(false);
            onConfirm();
          }}
        >
          Confirm UnStake
        </ConfirmSupplyButton>
      );
    }
  };

  const onSubmitStaking = async () => {
    try {
      setTransaction({
        pending: true
      });
      const allowance = await swapService.getAllowance(
        stakeInfo.lpAddress,
        walletAddress,
        stakeInfo.stakeAddress
      );

      if (allowance.lt(convertBN(stakingAmount))) {
        const approveTx = await swapService.approve(
          stakeInfo.lpAddress,
          walletAddress,
          stakeInfo.stakeAddress
        );
        if (approveTx.type !== 'success') {
          notifyError();
          throw new Error('approve failed');
        }
        await sleep(200);
      }

      const txRes = await stakingService.stake(
        stakeInfo.lpAddress,
        convertBN(stakingAmount),
        stakeInfo.stakeAddress === 'cx1a198d0f43f8e64f672ceddb2cbf5fac1c7f7218'
          ? 7
          : 0,
        {
          addr: walletAddress
        }
      );

      if (txRes.type !== 'success') {
        notifyError();
        throw new Error('stake failed');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setTransaction({
        pending: false
      });
    }
  };

  const onSubmitUnstaking = async () => {
    try {
      setTransaction({
        pending: true
      });

      const allowance = await swapService.getAllowance(
        stakeInfo.lpAddress,
        walletAddress,
        stakeInfo.stakeAddress
      );

      if (allowance.lt(convertBN(unStakingAmount))) {
        const approveTx = await swapService.approve(
          stakeInfo.lpAddress,
          walletAddress,
          stakeInfo.stakeAddress
        );
        if (approveTx.type !== 'success') {
          notifyError();
          throw new Error('approve failed');
        }
        await sleep(200);
      }

      const txRes = await stakingService.unstake(
        convertBN(unStakingAmount),
        stakeInfo.stakeAddress,
        {
          addr: walletAddress
        }
      );

      if (txRes.type !== 'success') {
        notifyError();
        throw new Error('unstake failed');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setTransaction({
        pending: false
      });
    }
  };

  return (
    <CustomModal
      title={<CustomModalTitle>{renderModalTitle()}</CustomModalTitle>}
      centered
      closable
      width={600}
      footer={null}
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
    >
      <CustomModalBodyContainer>
        <CustomModalContent>{renderStakeInput()}</CustomModalContent>
        <CustomModalContent>
          <div>*you will receive {hvhReward} HVH and HP</div>
        </CustomModalContent>
        <CustomModalFooter>
          <CancelButton
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </CancelButton>
          {renderStakeOrUnStakeButton()}
        </CustomModalFooter>
      </CustomModalBodyContainer>
    </CustomModal>
  );
};

export default StakeModal;
