import { Button, Col, Collapse, Input, Row } from 'antd';
import BigNumber from 'bignumber.js';
import React, { useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import CommonPageContainer from '../../../components/CommonPageContainer';
import configs from '../../../configs';
import { walletAtom } from '../../../recoil/wallet/atom';

const { Panel } = Collapse;

function getValue(id: string): string {
  return (document.querySelector(`#${id}`) as HTMLInputElement).value;
}

const routerAddr = 'cxba550bcd218daf3bc7dd7853891c1b449279c41d';
const poolAddr = 'cx4bc2c8eb4b35398fbabdeb1c7d16743229938c07';
const tokenA = 'cx30462bc016075f0eee1898fade3e383dd7689f34';
const tokenB = 'cx49af87fab95726e3824562f2a504d8e629540059';

export const SwapTxTest = () => {
  const walletInfo = useRecoilValue(walletAtom);
  const { nftService, swapService } = configs;
  return (
    <>
      <Row>
        <Col span={24}>
          <h1>addLiquidity</h1>
          <Input
            addonBefore="tokenAAddress"
            id="addLiquidity-1"
            defaultValue={tokenA}
          ></Input>
          <Input
            addonBefore="tokenBAddress"
            id="addLiquidity-2"
            defaultValue={tokenB}
          ></Input>
          <Input
            addonBefore="amountA"
            id="addLiquidity-3"
            defaultValue={'0'}
          ></Input>
          <Input
            addonBefore="amountB"
            id="addLiquidity-4"
            defaultValue={'0'}
          ></Input>
          <Input
            addonBefore="routerAddress"
            id="addLiquidity-5"
            defaultValue={routerAddr}
          ></Input>
          <Button
            onClick={async () => {
              const tokenAAddr = getValue('addLiquidity-1');
              const tokenBAddr = getValue('addLiquidity-2');
              const amountA = new BigNumber(getValue('addLiquidity-3'));
              const amountB = new BigNumber(getValue('addLiquidity-4'));
              const routerAddr = getValue('addLiquidity-5');
              const res = await swapService.addLiquidity(
                walletInfo.address,
                tokenAAddr,
                tokenBAddr,
                amountA,
                amountB,
                new BigNumber(100),
                routerAddr
              );
              console.log(res);
            }}
          >
            Execute
          </Button>
        </Col>
      </Row>
      {/* <Row>
        <Col span={24}>
          <h1>removeLiquidity</h1>
          <Input
            addonBefore="tokenAAddress"
            id="removeLiquidity-1"
            defaultValue={tokenA}
          ></Input>
          <Input
            addonBefore="tokenBAddress"
            id="removeLiquidity-2"
            defaultValue={tokenB}
          ></Input>
          <Input
            addonBefore="amountA"
            id="removeLiquidity-3"
            defaultValue={'0'}
          ></Input>
          <Input
            addonBefore="amountB"
            id="removeLiquidity-4"
            defaultValue={'0'}
          ></Input>
          <Button
            onClick={async () => {
              const tokenAAddr = getValue('addLiquidity-1');
              const tokenBAddr = getValue('addLiquidity-2');
              const amountA = new BigNumber(getValue('addLiquidity-3'));
              const amountB = new BigNumber(getValue('addLiquidity-4'));
              const res = await swapService.addLiquidity(
                walletInfo.address,
                routerAddr,
                tokenAAddr,
                tokenBAddr,
                amountA,
                amountB
              );
              console.log(res);
            }}
          >
            Execute
          </Button>
        </Col>
      </Row> */}
    </>
  );
};
