import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import 'antd/dist/reset.css';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import Router from './router';
import MobileMainPage from './pages/mobile-main';
import InitAtoms from './components/InitAtoms';
import { HelmetProvider } from 'react-helmet-async';
import { isMobile } from 'react-device-detect';
import { message } from 'antd';
import { Styles } from './styles/global';
import { CookiesProvider } from 'react-cookie';
import { TransactionPendingModal } from './components/TransactionPendingModal';
import styled from 'styled-components';

export const NotiContext = React.createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  notify: (message: any) => {}
});

const App = () => {
  const [api, contextHolder] = message.useMessage();

  const notify = (message: any) => {
    api.open({
      content: message,
      className: 'hs_message'
    });
  };

  return (
    <React.StrictMode>
      <Styles />
      <CookiesProvider>
        <RecoilRoot>
          {contextHolder}
          <NotiContext.Provider value={{ notify }}>
            <HelmetProvider>
              {isMobile ? (
                <MobileMainPage />
              ) : (
                <BrowserRouter>
                  <InitAtoms />
                  {/*<I18nextProvider i18n={i18n}>*/}
                  <Router />
                  {/*</I18nextProvider>*/}
                </BrowserRouter>
              )}
            </HelmetProvider>
          </NotiContext.Provider>
          <TransactionPendingModal />
        </RecoilRoot>
      </CookiesProvider>
    </React.StrictMode>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
