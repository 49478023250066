import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { PoolCommonContainer } from '../common/styles';
import CommonPageContainer from '../../../components/CommonPageContainer';
import PoolDetailDashboard from './components/PoolDetailDashboard';
import SupplyAndWithdrawContainer from './components/SupplyAndWithdrawContainer';
import { useRecoilValue } from 'recoil';
import { allPoolsSelector } from '../../../recoil/pool/selectors';
import configs from '../../../configs';
import BigNumber from 'bignumber.js';
import { walletAtom } from '../../../recoil/wallet/atom';

const PoolDetailPage = () => {
  const { poolAddress = '' } = useParams();
  const { address: walletAddress } = useRecoilValue(walletAtom);
  const pools = useRecoilValue(allPoolsSelector);
  const pool = pools.find(item => item.address == poolAddress);
  const { swapService } = configs;
  const [poolBalance, setPoolBalance] = useState([
    new BigNumber(0),
    new BigNumber(0)
  ]);
  const [lpTokenBalance, setLpTokenBalance] = useState(new BigNumber(0));
  const [lpTokenTotalSupply, setLpTokenTotalSupply] = useState(
    new BigNumber(0)
  );
  const [tokenBalances, setTokenBalances] = useState([
    new BigNumber(0),
    new BigNumber(0)
  ]);

  const refresh = async () => {
    if (!pool) return;
    const poolBalance = await swapService.getPoolBalance(pool);
    const lpTokenBalance = await swapService.getBalance(
      pool.address,
      walletAddress
    );
    const tokenBalances = await Promise.all([
      swapService.getBalance(pool.tokenA.address, walletAddress),
      swapService.getBalance(pool.tokenB.address, walletAddress)
    ]);
    const lpTokenTotalSupply = await swapService.getTotalSupply(pool.address);
    setPoolBalance(poolBalance);
    setLpTokenBalance(lpTokenBalance);
    setLpTokenTotalSupply(lpTokenTotalSupply);
    setTokenBalances(tokenBalances);
  };

  useEffect(() => {
    refresh();
  }, [pool?.address, walletAddress]);

  if (!pool) return null;
  return (
    <CommonPageContainer>
      <PoolCommonContainer>
        <PoolDetailDashboard
          pool={pool}
          poolBalance={poolBalance}
          lpTokenBalance={lpTokenBalance}
          lpTokenTotalSupply={lpTokenTotalSupply}
        />
        <SupplyAndWithdrawContainer
          pool={pool}
          poolBalance={poolBalance}
          walletTokenBalances={tokenBalances}
          lpTokenBalance={lpTokenBalance}
          lpTokenTotalSupply={lpTokenTotalSupply}
          refresh={async () => await refresh()}
        />
      </PoolCommonContainer>
    </CommonPageContainer>
  );
};

export default PoolDetailPage;
