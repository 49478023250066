import React, { Suspense, useState } from 'react';
import styled from 'styled-components';
import { StakeInfo, Token } from '../../../../types';
import BigNumber from 'bignumber.js';
import { convertBN, convertDecimal } from '../../../../common/Utils';
import SimpleAmountInput from '../../../../components/SimpleAmountInput';
import configs from '../../../../configs';

interface SwapTokenBaseInfoProps {
  balance: BigNumber;
  amount: string;
  lpName: string;
  onAmountChange: (amount: string) => void;
}

const StakeInput = ({
  balance,
  amount,
  lpName,
  onAmountChange
}: SwapTokenBaseInfoProps) => {
  const [amountPresetSelected, setAmountPresetSelected] = useState('');

  const onAmountPresetSelected = (presetVal: string) => {
    setAmountPresetSelected(presetVal);

    onAmountChange(
      convertDecimal(
        BigNumber.max(
          balance
            .multipliedBy(new BigNumber(presetVal))
            .dividedToIntegerBy(100),
          new BigNumber(0)
        ),
        new BigNumber(18),
        new BigNumber(18)
      )
    );
  };

  return (
    <StakeInputContainer>
      <LpNameContainer>
        <LpNameText>{lpName}</LpNameText>
      </LpNameContainer>
      <AmountContainer>
        <SimpleAmountInput
          amount={amount}
          onInputChange={newAmount => onAmountChange(newAmount)}
        />
        <BalanceText>
          Balance <BalanceAmount>{convertDecimal(balance)}</BalanceAmount>
        </BalanceText>
        <AmountPresetContainer>
          <AmountPresetItem>
            <AmountPresetButton
              id={'amount_preset_10%'}
              value={'10'}
              checked={amountPresetSelected === '10'}
              name={'amountPreset'}
              onChange={() => onAmountPresetSelected('10')}
              onClick={() => onAmountPresetSelected('10')}
            />
            <AmountPresetLabel htmlFor={'amount_preset_10%'}>
              10%
            </AmountPresetLabel>
          </AmountPresetItem>

          <AmountPresetItem>
            <AmountPresetButton
              id={'amount_preset_25%'}
              value={'25'}
              checked={amountPresetSelected === '25'}
              name={'amountPreset'}
              onChange={() => onAmountPresetSelected('25')}
              onClick={() => onAmountPresetSelected('25')}
            />
            <AmountPresetLabel htmlFor={'amount_preset_25%'}>
              25%
            </AmountPresetLabel>
          </AmountPresetItem>

          <AmountPresetItem>
            <AmountPresetButton
              id={'amount_preset_50%'}
              value={'50'}
              checked={amountPresetSelected === '50'}
              name={'amountPreset'}
              onChange={() => onAmountPresetSelected('50')}
              onClick={() => onAmountPresetSelected('50')}
            />
            <AmountPresetLabel htmlFor={'amount_preset_50%'}>
              50%
            </AmountPresetLabel>
          </AmountPresetItem>

          <AmountPresetItem>
            <AmountPresetButton
              id={'amount_preset_75%'}
              value={'75'}
              checked={amountPresetSelected === '75'}
              name={'amountPreset'}
              onChange={() => onAmountPresetSelected('75')}
              onClick={() => onAmountPresetSelected('75')}
            />
            <AmountPresetLabel htmlFor={'amount_preset_75%'}>
              75%
            </AmountPresetLabel>
          </AmountPresetItem>

          <AmountPresetItem>
            <AmountPresetButton
              id={'amount_preset_Max'}
              value={'100'}
              checked={amountPresetSelected === '100'}
              name={'amountPreset'}
              onChange={() => onAmountPresetSelected('100')}
              onClick={() => onAmountPresetSelected('100')}
            />
            <AmountPresetLabel htmlFor={'amount_preset_Max'}>
              Max
            </AmountPresetLabel>
          </AmountPresetItem>
        </AmountPresetContainer>
      </AmountContainer>
    </StakeInputContainer>
  );
};

export default StakeInput;

const StakeInputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.875rem;
  background: white;
`;

const LpNameContainer = styled.div``;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
`;

const BalanceText = styled.p`
  margin-top: 16px;
  font-size: 11px;
  color: #b3b3b3;
`;
const BalanceAmount = styled.span`
  font-size: 11px;
  color: #b3b3b3;
  margin-left: 12px;
`;

const AmountPresetContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const AmountPresetItem = styled.li`
  display: inline-block;
  margin: 0;
`;

const AmountPresetLabel = styled.label`
  cursor: pointer;
  font-size: 11px;
  border: 1px solid #b3b3b3;
  color: #b3b3b3;
  border-color: #b3b3b3;
  border-radius: 30px;
  margin-left: 6px;
  padding: 4px 10px;
  background-color: transparent;
  transition: 0.3s linear;
`;
const AmountPresetButton = styled.input.attrs({ type: 'radio' })`
  display: none;
  transition: 0.3s linear;
  &:hover + ${AmountPresetLabel}, &:checked + ${AmountPresetLabel} {
    border-color: #f56666;
    color: #f56666;
  }
`;

const LpNameText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
`;
