import {
  Address,
  ChainEvent,
  Config,
  NftInfo,
  NftOwnerInfo,
  NftSaleInfo,
  RawNftInfo,
  RouteInfoPool,
  SaleMatchedEvent,
  StakePoolInfo,
  SwapRouteInfo,
  Token
} from './../types';
import { baseChainId, baseNetworkRpc } from '../configs';
import axios from 'axios';
import BigNumber from 'bignumber.js';

export interface CoinInfo {
  name: string;
  symbol: string;
  icon: string;
}

const availableCoin: CoinInfo[] = [
  {
    name: 'Bitcoin',
    symbol: 'BTC',
    icon: '/assets/coins/btc.png'
  },
  {
    name: 'Ethereum',
    symbol: 'ETH',
    icon: '/assets/coins/eth.png'
  },
  {
    name: 'Tether',
    symbol: 'USDT',
    icon: '/assets/coins/usdt.png'
  }
];

export const defaultCoinInfo: CoinInfo = {
  name: 'Token',
  symbol: 'Token',
  icon: '/assets/coins/default.png'
};

export const getCoinInfoBySymbol = (symbol: string) => {
  return (
    availableCoin.find(
      item => item.symbol.toLowerCase() === symbol.toLowerCase()
    ) || defaultCoinInfo
  );
};

export const convertDecimal = (
  amount: BigNumber | number | string,
  decimal: BigNumber | number | string = new BigNumber(18),
  fixed: BigNumber | number | string = new BigNumber(6)
): string => {
  const _amount = new BigNumber(amount);
  if (_amount.eq(new BigNumber(0))) return '0';
  return _amount
    .dividedBy(new BigNumber(10).pow(new BigNumber(decimal)))
    .toFixed(new BigNumber(fixed).toNumber());
};

export const convertBN = (
  amount: string | number | BigNumber,
  decimal: BigNumber | number | string = new BigNumber(18)
): BigNumber => {
  return new BigNumber(amount).multipliedBy(
    new BigNumber(10).pow(new BigNumber(decimal))
  );
};

export const isGraterThanTargetNumber = (
  amount: BigNumber,
  targetNumber: number,
  decimal = new BigNumber(18)
): boolean => {
  return amount
    .dividedBy(new BigNumber(10).pow(decimal))
    .isGreaterThan(new BigNumber(targetNumber));
};

export const dummyToken: Token = {
  address: '',
  name: '',
  symbol: '',
  decimal: new BigNumber(0),
  imageUrl: '/assets/coins/default.png'
};

export const getManualToken = (tokenAddress: string): Token => {
  return {
    address: tokenAddress,
    name: '',
    symbol: '',
    decimal: new BigNumber(18),
    imageUrl: '/assets/coins/default.png'
  };
};

export const calcFeeRate = (swapRouteInfo: SwapRouteInfo): BigNumber => {
  const feeInfos = swapRouteInfo.poolPath ?? [];

  let curReturn = new BigNumber(1);

  for (let feeInfoIdx = 0; feeInfoIdx < feeInfos.length; feeInfoIdx++) {
    const feeInfo = feeInfos[feeInfoIdx];
    const inputToken = swapRouteInfo.path[feeInfoIdx];
    curReturn = curReturn
      .multipliedBy(
        feeInfo.feeBase.minus(
          feeInfo.feeRate.plus(
            inputToken === feeInfo.tokenA.address
              ? feeInfo.feeRateAtoB
              : feeInfo.feeRateBtoA
          )
        )
      )
      .dividedBy(feeInfo.feeBase);
  }
  return new BigNumber(1).minus(curReturn);
};

export const calcPoolPrice = (
  path: Address[],
  poolPath: Pick<RouteInfoPool, 'balances'>[]
): BigNumber => {
  let curPrice = new BigNumber(1);
  for (let idx = 0; idx < poolPath.length; idx++) {
    const sourceBalance = poolPath[idx].balances[path[idx]];
    const destBalance = poolPath[idx].balances[path[idx + 1]];
    curPrice = curPrice.multipliedBy(destBalance.dividedBy(sourceBalance));
  }
  return curPrice;
};

export const getTokenImageByTicker = (ticker?: string): string => {
  if (ticker == 'HVH') {
    return '/assets/coins/havah.png';
  } else if (ticker == 'USDT') {
    return '/assets/coins/usdt.png';
  } else {
    return '/assets/coins/default.png';
  }
};

export const getSnsIconBySnsType = (snsType: string): string => {
  switch (snsType) {
    case 'web':
      return '/assets/social/icon_web.png';
    case 'twitter':
      return '/assets/social/icon_twitter.png';
    case 'discord':
      return '/assets/social/icon_discord.png';
    case 'telegram':
      return '/assets/social/icon_discord.png';
    case 'facebook':
      return '/assets/social/icon_facebook.png';
    case 'share':
      return '/assets/social/icon_share.png';
    default:
      return '/assets/social/icon_share.png';
  }
};

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function wrapPromise<T>(promise: Promise<T>) {
  let status = 'pending';
  let result: T;
  const suspender = promise.then(
    r => {
      status = 'success';
      result = r;
    },
    e => {
      status = 'error';
      result = e;
    }
  );
  return {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      } else if (status === 'success') {
        return result;
      }
    }
  };
}

export const getLastPriceFromHistory = (nftHistory: ChainEvent[]) => {
  const saleMatched = nftHistory.filter(
    history => history.event === 'SaleMatched'
  ) as SaleMatchedEvent[];
  if (!saleMatched.length) return null;

  const price = new BigNumber(saleMatched[0]?.price ?? 0);

  if (price.isNaN()) return new BigNumber(0);
  return price;
};

export const toShortAddress = (address: string) => {
  if (!address) return '';
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const parseCreatorFee = (creatorFee: string) => {
  const floatCreatorFee = parseFloat(creatorFee);
  return floatCreatorFee;
};

export const nftHasAttribute = (
  nft: NftInfo,
  traitType: string,
  value: string
): boolean => {
  const { metadata } = nft;
  const { attributes } = metadata ?? {};
  if (!attributes) return false;
  const matched = attributes.find(
    attribute => attribute.trait_type === traitType && attribute.value === value
  );
  if (!matched) return false;
  return true;
};

export const removeDuplicates = <T>(
  arr: T[],
  getKey: (item: T) => string | number
): T[] => {
  const uniqueObject: { [key: string | number]: T } = {};
  for (const item of arr) {
    uniqueObject[getKey(item)] = item;
  }
  return Object.values(uniqueObject);
};

export const getConfig = async (apiHost: string): Promise<Config> => {
  const config = await axios.get(`${apiHost}/configure`);
  const configData = config.data;

  return configData as Config;
};

export const getStakePools = async (
  apiHost: string
): Promise<StakePoolInfo[]> => {
  const stakePools = (await axios.get(`${apiHost}/stake`)).data;
  return stakePools as StakePoolInfo[];
};

export const DEFAULT_IMAGE_URL = '/assets/coins/default.png';

export const imgErrorFallback = (
  event: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  event.currentTarget.src = DEFAULT_IMAGE_URL;
};

export const convertHavahValue = (
  amount: BigNumber,
  margin = 0.00001
): number => {
  return parseFloat(convertDecimal(amount)) * (1 + margin);
};

export const explodeRawNftItem = (nftItem: RawNftInfo): NftInfo[] => {
  const { owners, saleInfos } = nftItem;

  if (owners.length === 0) {
    return [];
  }

  const exploded: NftInfo[] = [];

  for (const owner of owners) {
    exploded.push({
      ...nftItem,
      owner,
      saleInfo: saleInfos.find(saleInfo => saleInfo.seller === owner.owner)
    });
  }

  return exploded;
};

export const isOwner = (ownerInfos: NftOwnerInfo[], address: Address) => {
  return ownerInfos.some(ownerInfo => ownerInfo.owner === address);
};

export const getMySaleInfos = (saleInfos: NftSaleInfo[], address: Address) => {
  return saleInfos.filter(saleInfo => saleInfo.seller === address);
};

export const switchToBaseNetwork = async () => {
  const web3 = (window as any).ethereum;
  try {
    await web3.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: baseChainId }]
    });
  } catch (error: any) {
    if (error.code === 4902) {
      await web3.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: baseChainId,
            chainName: 'Base',
            rpcUrls: [baseNetworkRpc],
            nativeCurrency: {
              name: 'Ethereum',
              symbol: 'ETH',
              decimals: 18
            },
            blockExplorerUrls: ['https://explorer.base.org']
          }
        ]
      });
    }
  }
};

export const connectCoinbaseWallet = async () => {
  const ethereum = (window as any).ethereum;
  if (!ethereum) {
    window.open(
      'https://chromewebstore.google.com/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?hl=en'
    );
    return;
  }
  const provider = ethereum.providerMap?.get('CoinbaseWallet') ?? ethereum;
  if (!provider || !provider.isCoinbaseWallet) {
    window.open(
      'https://chromewebstore.google.com/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?hl=en'
    );
    throw new Error('Coinbase Wallet is not installed');
  }

  if (ethereum.setSelectedProvider) {
    ethereum.setSelectedProvider(provider);
  }
  return await ethereum.enable();
};

export const connectMetamask = async () => {
  const ethereum = (window as any).ethereum;
  if (!ethereum) {
    window.open(
      'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'
    );
    return;
  }

  const provider = ethereum.providerMap?.get('MetaMask') ?? ethereum;

  if (!provider || !provider.isMetaMask) {
    window.open(
      'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'
    );
    throw new Error('MetaMask is not installed');
  }

  if (ethereum.setSelectedProvider) {
    ethereum.setSelectedProvider(provider);
  }
  return await provider.enable();
};
