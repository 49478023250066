import React, { useContext } from 'react';
import { Modal, Table } from 'antd';
import styled from 'styled-components';
import configs from '../../../configs';
import { UserAsteroidStatus } from '../../../types';
import _ from 'lodash';
import { convertDecimal, sleep } from '../../../common/Utils';
import BigNumber from 'bignumber.js';
import type { ColumnsType } from 'antd/es/table';
import { TransactionPendingModal } from 'components/TransactionPendingModal';
import { useRecoilState } from 'recoil';
import { transactionAtom } from 'recoil/transaction/atom';
import { NotiContext } from 'index';
import { ErrorMessage } from 'styles/common';

interface AsteroidClaimModalProps {
  onClose: () => void;
  asteroids: UserAsteroidStatus[];
  walletAddress: string;
}

interface DataType {
  id: string;
  claimable: string;
}
const columns: ColumnsType<DataType> = [
  {
    title: 'Purchase no',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: 'Rewards',
    dataIndex: 'claimable',
    key: 'claimable',
    align: 'center'
  }
];

export const AsteroidClaimModal = ({
  onClose,
  asteroids,
  walletAddress
}: AsteroidClaimModalProps) => {
  const { notify } = useContext(NotiContext);
  const [transacton, setTransaction] = useRecoilState(transactionAtom);

  const claimableAsteroids = asteroids.filter(it => !it.claimable.isZero());
  if (_.isEmpty(claimableAsteroids)) {
    return (
      <AsteroidClaimModalContainer
        title={'Claim your rewards'}
        open={true}
        closable={false}
        cancelText={'Close'}
        centered={true}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={onClose}
      >
        <AsteroidClaimModalContent>
          <EmptyText>No claimable rewards.</EmptyText>
        </AsteroidClaimModalContent>
      </AsteroidClaimModalContainer>
    );
  }

  const claimableReward = convertDecimal(
    claimableAsteroids.reduce<BigNumber>((acc, asteroid) => {
      return acc.plus(asteroid.claimable);
    }, new BigNumber(0)),
    18,
    18
  );
  const dataList: DataType[] = claimableAsteroids.map(it => ({
    id: it.id,
    claimable: convertDecimal(it.claimable, 18, 18)
  }));

  const { asteroidService } = configs;

  if (transacton.pending) {
    return <></>;
  }

  return (
    <AsteroidClaimModalContainer
      title={'Claim your rewards'}
      open={true}
      closable={false}
      okText="Confirm"
      cancelText={'Cancel'}
      centered={true}
      onOk={async () => {
        setTransaction({
          pending: true,
          pendingMessage: 'Claiming rewards...'
        });
        try {
          const txRes = await asteroidService.batchClaim(asteroids, {
            addr: walletAddress
          });
          if (txRes.type === 'success') {
            await sleep(200);
          } else {
            notify(
              <ErrorMessage>
                Failed to claim rewards. Please try later.
                <span>
                  <img src="/assets/message_error.png" alt="error icon" />
                </span>
              </ErrorMessage>
            );
          }
        } catch (e) {
          console.error(e);
          notify(
            <ErrorMessage>
              Failed to claim rewards. Please try later.
              <span>
                <img src="/assets/message_error.png" alt="error icon" />
              </span>
            </ErrorMessage>
          );
        } finally {
          setTransaction({
            pending: false,
            pendingMessage: undefined
          });
          onClose();
        }
      }}
      onCancel={onClose}
    >
      <AsteroidClaimModalContent>
        <ClaimableTable
          columns={columns}
          dataSource={dataList}
          footer={() => <>{claimableReward} HVH</>}
        />
      </AsteroidClaimModalContent>
    </AsteroidClaimModalContainer>
  );
};

const AsteroidClaimModalContainer = styled(Modal)`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    min-width: 600px;

    .ant-modal-title {
      color: #000000;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
    }

    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-btn-default {
        width: 100%;
        height: 40px;
        border: 1px solid #999999;
        color: #999999;
        &:hover {
          color: #999999;
          border: 1px solid #999999;
        }
      }

      .ant-btn-primary {
        width: 100%;
        height: 40px;
        background-color: #8247ff;
      }

      span {
        margin: 0 auto;
      }
    }
  }
`;

const AsteroidClaimModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ClaimableTable = styled(Table<DataType>)`
  margin-top: 20px;
  width: 100%;
  .ant-table-thead th.ant-table-cell {
    background: black;
    color: white;
    font-weight: normal;
    &:before {
      border: none;
      display: none;
    }
  }
  .ant-pagination .ant-pagination-item-active {
    background: #8247ff;
    border-radius: 20px;
    border: none;
    a {
      color: white;
    }
  }
  .ant-pagination-item {
    border-radius: 20px;
    border: none;
  }
  .ant-table-footer {
    text-align: right;
  }
`;

const EmptyText = styled.div`
  font-size: 15px;
  height: 150px;
  font-weight: 400;
  text-align: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
`;
