import { Button, Col, Collapse, Input, Row } from 'antd';
import BigNumber from 'bignumber.js';
import React, { useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import CommonPageContainer from '../../components/CommonPageContainer';
import configs from '../../configs';
import { walletAtom } from '../../recoil/wallet/atom';
import { NftMarketTxTest } from './tests/NftMarketTxTest';
import { StakingTxTest } from './tests/StakingTxTest';
import { SwapTxTest } from './tests/SwapTxTest';

const { Panel } = Collapse;

function getValue(id: string): string {
  return (document.querySelector(`#${id}`) as HTMLInputElement).value;
}

const marketAddr = 'cx74bc753c6940f556c96b0b0d3a521a6abd6c9509';
const nftAddr = 'cx37f9e62e72c6b7641aa4f9859b19bcade2724b1a';
const hvhAddr = 'cx0000000000000000000000000000000000000000';

const TxTestPage = () => {
  const walletInfo = useRecoilValue(walletAtom);
  const { nftService, swapService } = configs;

  return (
    <CommonPageContainer>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <Collapse>
            <Panel header="Market Test" key="market-test">
              <NftMarketTxTest />
            </Panel>
            <Panel header="Swap Test" key="swap-test">
              <SwapTxTest />
            </Panel>
            <Panel header="Staking Test" key="staking-test">
              <StakingTxTest />
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </CommonPageContainer>
  );
};

export default TxTestPage;
