import React, { useState } from 'react';
import styled from 'styled-components';
import { Token } from 'types';
import { StyleBoxShadow } from '../common/styles';
import { imgErrorFallback } from '../common/Utils';

interface TokenSingleBoxProps {
  token: Token;
  showTokenSymbol?: boolean;
  iconSize?: string;
}

interface IconProps {
  imgUrl: string;
}

const TokenSingleBox = ({
  token,
  iconSize = '24px',
  showTokenSymbol = false
}: TokenSingleBoxProps) => {
  return (
    <TokenSingleContainer>
      <TokenSingleIconContainer>
        <TokenContainer width={iconSize}>
          <Icon imgUrl={token.imageUrl || ''} />
        </TokenContainer>
      </TokenSingleIconContainer>
      {showTokenSymbol ? (
        <TokenTitles>
          <TokenPairTitleUnit>{token.symbol}</TokenPairTitleUnit>
        </TokenTitles>
      ) : (
        <></>
      )}
    </TokenSingleContainer>
  );
};

export default TokenSingleBox;

const TokenSingleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TokenSingleIconContainer = styled.div`
  display: flex;
  padding: 5px;
`;

const TokenContainer = styled.div<{ width: string }>`
  width: ${props => props.width || '24px'};
  border-radius: 50%;
  ${StyleBoxShadow}
`;

const Icon = ({ imgUrl }: IconProps) => {
  return (
    <img
      src={imgUrl}
      alt="tokenA"
      style={{ width: '100%' }}
      onError={imgErrorFallback}
    />
  );
};
const TokenTitles = styled.div`
  margin-left: 10px;
  width: 100%;

  p {
    margin: 0px;
  }
`;

const TokenPairTitle = styled.p`
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
`;

const TokenPairTitleUnit = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: #999999;
`;
