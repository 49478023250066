import { atom } from 'recoil';
import { Address } from '../../types';

export interface WalletAtom {
  address: Address;
  shortAddr: string;
}

export const walletAtom = atom<WalletAtom>({
  key: 'walletAtom',
  default: {
    address: '',
    shortAddr: ''
  }
});
