import BigNumber from 'bignumber.js';
import React from 'react';
import styled from 'styled-components';
import { convertDecimal, imgErrorFallback } from '../../../../../common/Utils';
import { Pool, SwapRouteInfo } from '../../../../../types';
import {
  InputInfoContainer,
  InputTokenInfo,
  InputTokenInfoLeft,
  InputTokenInfoRight,
  TokenImg
} from './styles';

interface SupplyModalInputProps {
  pool: Pool;
  poolBalance: BigNumber[];
  firstTokenAmount: BigNumber;
  secondTokenAmount: BigNumber;
}

export const SupplyModalInput = ({
  poolBalance,
  pool,
  firstTokenAmount,
  secondTokenAmount
}: SupplyModalInputProps) => {
  const tokenAToBRatio = poolBalance[1].div(poolBalance[0]);
  const tokenBToARatio = poolBalance[0].div(poolBalance[1]);

  return (
    <>
      <div className="title">Input</div>
      <InputInfoContainer>
        <InputTokenInfo>
          <InputTokenInfoLeft>
            <TokenImg src={pool.tokenA.imageUrl} onError={imgErrorFallback} />
            <span className={'token_name'}>{pool.tokenA.name}</span>
            <span className={'token_ratio'}>
              1 {pool.tokenA.name} = {tokenAToBRatio.toFixed(6)}{' '}
              {pool.tokenB.name}
            </span>
          </InputTokenInfoLeft>
          <InputTokenInfoRight>
            {convertDecimal(firstTokenAmount.toFixed(6), pool.tokenA.decimal)}
          </InputTokenInfoRight>
        </InputTokenInfo>
        <InputTokenInfo>
          <InputTokenInfoLeft>
            <TokenImg src={pool.tokenB.imageUrl} onError={imgErrorFallback} />
            <span className={'token_name'}>{pool.tokenB.name}</span>
            <span className={'token_ratio'}>
              1 {pool.tokenB.name} = {tokenBToARatio.toFixed(6)}{' '}
              {pool.tokenA.name}
            </span>
          </InputTokenInfoLeft>
          <InputTokenInfoRight>
            <span>
              {convertDecimal(
                secondTokenAmount.toFixed(6),
                pool.tokenB.decimal
              )}
            </span>
          </InputTokenInfoRight>
        </InputTokenInfo>
      </InputInfoContainer>
    </>
  );
};
