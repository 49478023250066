import React from 'react';
import styled from 'styled-components';

interface ColoredDecimalProps {
  amount: string | number;
  unit?: string;
  theme?: 'black' | 'pink';
  showUnit?: boolean;
  leftColor?: string;
  rightColor?: string;
  numberSize?: string;
  unitSize?: string;
}

const ColoredDecimal = ({
  amount,
  unit = 'HVH',
  showUnit = true,
  theme = 'black',
  numberSize = '20px',
  unitSize = '14px'
}: ColoredDecimalProps) => {
  const decimalNumber = amount.toString().split('.');
  return (
    <ColoredDecimalContainer>
      <DecimalLeftSpan theme={theme}>{decimalNumber[0]}.</DecimalLeftSpan>
      <DecimalRightSpan theme={theme}>{decimalNumber[1]}</DecimalRightSpan>
      {showUnit && <Unit theme={theme}>{unit}</Unit>}
    </ColoredDecimalContainer>
  );
};

export default ColoredDecimal;

export const ColoredDecimalContainer = styled.div`
  float: right;
`;

export const DecimalLeftSpan = styled.span<{
  theme: string;
}>`
  color: ${props => (props.theme === 'black' ? '#000000' : '#F56666')};
  font-weight: 500;
`;

export const DecimalRightSpan = styled.span<{
  theme: string;
}>`
  color: ${props => (props.theme === 'black' ? '#999999' : '#FAADAE')};
  font-weight: 500;
`;

export const Unit = styled.span<{ theme: string }>`
  color: ${props => (props.theme === 'black' ? '#999999' : '#FAADAE')};
  margin-left: 0.625rem;
  font-size: 0.8125rem;
  font-weight: 300;
`;
