import styled from 'styled-components';

export const SwapContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 90px;
  margin-bottom: 200px;
`;

export const TokenTitle = styled('p')`
  color: #f56666;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
`;

export const TokenContainer = styled('div')`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  border-radius: 20px 20px 0px 0px;
  padding: 40px;
  background-color: white;
`;

export const BaseTokenContainer = styled('div')`
  display: flex;

  width: 100%;
  // height: 60px;
`;
interface SwapTokenProps {
  isActive: boolean;
  height: string;
}
// export const SwapTokenImage = styled.img.attrs((props: SwapTokenProps) => {
//   `${props => props.isActive}.png`;
// })``;
export const TargetTokenContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 60px;
  background-color: white;
`;

export const SwapButton = styled('div')<{ disable?: boolean }>`
  background-color: ${props => (props.disable ? '#bbbbbb' : '#f56666')};
  width: 100%;
  height: 80px;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0px 0px 20px 20px;
  cursor: ${props => (props.disable ? 'not-allowed' : 'pointer')};
  span {
    font-size: 24px;
    margin-right: 40px;
    font-weight: bold;
    cursor: default;
  }
`;

export const StakePoolContainer = styled.div``;
