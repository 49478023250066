import styled from 'styled-components';

export const MobilePageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('/assets/mobile_main_background.png') no-repeat;
  background-size: cover;
`;

export const HavahSwapLogo = styled.img.attrs({
  src: '/logo/havahswap_logo_full.svg',
  alt: 'havah-swap-logo'
})`
  margin-top: 50px;
  @media only screen and (max-width: 450px) {
    width: 60%;
  }
`;

export const MobilePageNoticeContainer = styled.div`
  position: absolute;
  top: 45%;
  text-align: center;
  font-size: 2rem;

  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 400px) {
    font-size: 1.2rem;
  }
`;

export const Paragraph = styled.p`
  color: #000000;
  font-weight: 500;
`;

export const HighLightedParagraph = styled.p`
  font-weight: 700;
  color: #f56666;
`;

export const CopyRight = styled.div`
  position: absolute;
  bottom: 1%;
  font-size: 12px
  display: inline-block;
  color: #b3b3b3;
  text-align: center;
  margin-bottom: 0;

  @media only screen and (max-width: 400px) {
    font-size: 10px;
  }
`;
