import React, { useEffect, useState } from 'react';
import { PoolContainer, AddLiquidityButton } from './style';
import CommonPageContainer from '../../../components/CommonPageContainer';
import { useRecoilValue } from 'recoil';
import { allPoolsSelector } from '../../../recoil/pool/selectors';
import PoolDashBoard from './components/PoolDashBoard';
import UserPoolBoard, {
  UserPoolBoardSkeleton
} from './components/UserPoolBoard';
import PoolBoard, { PoolBoardSkeleton } from './components/PoolBoard';
import { useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import configs from '../../../configs';
import { Address, Pool, PoolTokenBalances } from '../../../types';
import usePromise from 'react-promise-suspense';
import { walletAddressSelector } from '../../../recoil/wallet/selectors';
import { sleep } from '../../../common/Utils';

export const LiquidityPoolPageSkeleton = () => {
  return (
    <CommonPageContainer>
      <PoolContainer>
        <PoolDashBoard />
        <UserPoolBoardSkeleton />
        <PoolBoardSkeleton />
        <div style={{ height: '100px', width: '100%' }}></div>
      </PoolContainer>
    </CommonPageContainer>
  );
};

interface LiquidityPoolPageProps {
  refreshIdx?: string | number;
}

const LiquidityPoolPage = ({ refreshIdx }: LiquidityPoolPageProps) => {
  const { apiService } = configs;
  const pools = useRecoilValue(allPoolsSelector).filter(pool => pool.listed);
  const walletAddr = useRecoilValue(walletAddressSelector);

  const poolTokenBalances = usePromise(
    async (pools, _) => {
      const poolTokenBalances: PoolTokenBalances = {};
      await Promise.all(
        (pools as Pool[]).flatMap(async pool => {
          poolTokenBalances[pool.address] = {};

          poolTokenBalances[pool.address][pool.tokenA.address] =
            await apiService.getTokenBalance(pool.tokenA.address, pool.address);
          poolTokenBalances[pool.address][pool.tokenB.address] =
            await apiService.getTokenBalance(pool.tokenB.address, pool.address);
        })
      );
      return poolTokenBalances;
    },
    [pools, `liquidityPool/poolTokenBalances/${refreshIdx}`]
  );

  const myPoolTokenBalances = usePromise(
    async (pools, walletAddr, _) => {
      return await Promise.all(
        (pools as Pool[]).map(async pool => {
          let balance = new BigNumber(0);
          try {
            balance = await apiService.getTokenBalance(
              pool.address,
              walletAddr as string
            );
          } catch (e) {
            console.log(e);
          }
          return {
            pool: pool,
            balance
          };
        })
      );
    },
    [pools, walletAddr, `liquidityPool/myPoolTokenBalances/${refreshIdx}`]
  );

  return (
    <CommonPageContainer>
      <PoolContainer>
        <PoolDashBoard />
        <UserPoolBoard
          pools={myPoolTokenBalances
            .filter(myPoolBalance => myPoolBalance.balance.gt(new BigNumber(0)))
            .map(myPoolBalance => myPoolBalance.pool)}
          poolTokenBalances={poolTokenBalances}
        />
        <PoolBoard pools={pools} poolTokenBalances={poolTokenBalances} />
        {/* <AddLiquidityButton
          onClick={() => navigateTo('/liquidity-pool/create')}
        >
          Add New Liquidity Pool
        </AddLiquidityButton> */}
        <div style={{ height: '100px', width: '100%' }}></div>
      </PoolContainer>
    </CommonPageContainer>
  );
};

export default LiquidityPoolPage;
