import { Collapse, Input, Row, Col } from 'antd';
import styled from 'styled-components';

export const StakeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 90px;
  margin-bottom: 200px;
`;

export const StakeTitle = styled.div`
  color: #f56666;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
`;

export const StakeSubTitle = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
`;

export const StakeContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

export const StakeNotice = styled.div`
  color: #000;
  margin: 5px 0;
  text-align: right;
  font-size: 12px;
`;

export const StakeTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
  padding: 20px;
`;

export const StakeTableTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StakeTableRow = styled.div``;

export const StakeTableSearch = styled(Input)`
  width: 30%;
  min-width: 200px;
  .ant-input-group-addon {
    background-color: #fff;
  }

  input.ant-input {
    border-left-color: #fff;
  }
`;

export const StakeTableContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StakePoolCollapse = styled(Collapse)`
  border: none;
  background: none;

  .ant-collapse-item,
  .ant-collapse-content,
  .ant-collapse-content-active {
    border: none !important;
    background: none !important;
  }

  .ant-collapse,
  .ant-collapse-item,
  .ant-collapse-header {
    align-items: center !important;
  }

  .ant-collapse-content-box {
    background: #f8f8f8;
  }

  .ant-collapse-header {
    padding: 0 !important;
  }
`;

export const StakeTableHeader = styled(Row)`
  width: 100%;
  font-size: 0.6875rem;
  font-weight: 400;
  color: #999999;
  padding: 1.25rem;
  margin-right: 24px;
`;

export const StakePoolHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.6875rem;
  font-weight: 400;
  padding: 1.25rem;
`;

export const StakePoolActionContainer = styled.div`
  display: flex;
  padding: 0.5rem;
`;

export const StakePoolActionTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #1a1a1a;
`;

export const StakePoolActionButton = styled.button`
  padding: 3px 22px;
  cursor: pointer;
  width: 130px;
  color: #4d4d4d;
  border: 1px solid #4d4d4d;
  background: #ffffff;
  border-radius: 24px;

  :disabled {
    background: #cccccc;
    border-color: #cccccc;
    cursor: not-allowed;
  }
`;

export const StakeActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 10px;
`;
