export const StyleBoxShadow = `
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
`;

export const StyleButton = `
  width: 100%;
  height: 40px;
  cursor: pointer;
  font-weight: bold;
  color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  transition: 0.3s linear;
  &:hover {
    opacity: 0.9;
  }
`;
