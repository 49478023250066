import React, { useEffect } from 'react';

import {
  MobilePageContainer,
  HavahSwapLogo,
  MobilePageNoticeContainer,
  Paragraph,
  HighLightedParagraph,
  CopyRight
} from './style';
const MobileMainPage = () => {
  return (
    <MobilePageContainer>
      <HavahSwapLogo />
      <MobilePageNoticeContainer>
        <Paragraph>The mobile site will be available later.</Paragraph>
        <HighLightedParagraph>
          Plase access our site using a PC browser
        </HighLightedParagraph>
      </MobilePageNoticeContainer>
      <CopyRight>Copyright © 2023 HAVAHswap. All rights reserved.</CopyRight>
    </MobilePageContainer>
  );
};

export default MobileMainPage;
