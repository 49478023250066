import styled from 'styled-components';
import {
  disableSelectTextBlock,
  displayFlexAlignCenter
} from '../../../styles/common';

export const PoolContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background: transparent;
  width: 100%;
`;

export const ClaimUnit = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

export const AddLiquidityButton = styled.div`
  margin-top: 2px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
  font-weight: 500;
  background: white;
  color: #666666;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 0px;
  border-radius: 0 0 12px 12px;
  margin-bottom: 50px;
`;

export const MintViewPort = styled.div`
  height: calc(100vh - 72px - 92px);
  width: 100vw;

  min-height: 1000px;
  min-width: 1350px;

  @media only screen and (max-width: 1440px) {
    height: calc(100vh - 72px - 83px);
  }

  @media only screen and (max-width: 768px) {
    height: calc(100vh - 50px - 83px);
  }

  ${displayFlexAlignCenter}
`;

export const MintContainer = styled.div`
  width: 1305px;
  height: 856px;
`;

export const ProcessContainer = styled.div`
  width: 707px;
  height: 248px;
  padding: 24px;
  border-radius: 10px;
  background-color: #181818;
`;

export const MintAmountButton = styled.div<any>`
  width: 50px;
  height: 50px;
  padding-top: 8px;
  border-radius: 10px;
  background-color: #764dd0;

  ${disableSelectTextBlock}

  &:active {
    opacity: 0.6;
  }
`;
