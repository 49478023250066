import styled from 'styled-components';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

export const NavArea = styled('div')`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #f3f3f3;

  @media only screen and (max-width: 768px) {
    height: 50px;
  }
`;

export const NavContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 91.1%;
`;

export const NavLeft = styled('div')`
  width: 200px;
  img {
    position: absolute;
    top: 20px;
    width: 170px;
    height: 40px;

    @media only screen and (max-width: 768px) {
      top: 12px;
      width: 80px;
    }
  }
`;

export const NavRight = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const NavItem = styled('li')<{
  disable?: boolean;
  isActive?: boolean;
  activeColor?: string;
}>`
  color: ${props =>
    props.disable
      ? '#bbbbbb'
      : props.isActive && props.activeColor
      ? props.activeColor
      : '#000000'};
  font-weight: ${props => (props.isActive ? '700' : '400')};
  cursor: ${props => (props.disable ? 'not-allowed' : 'pointer')};
  margin: 0 3%;
  transition: 0.3s linear;
  &:hover {
    ${props => (props.disable ? '#bbbbbb' : '#f56666')};
  }
`;

export const NavItemDisabled = styled(NavItem)`
  cursor: not-allowed;
  color: #a4a4a4;
  &:hover {
    color: #a4a4a4;
  }
`;

export const DesktopNavWrapper = styled(NavRight)`
  width: 100%;
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const NavItemContainer = styled('ul')`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 890px) {
    display: block;
  }
`;

export const ConnectButton = styled.button<{ isMarket?: boolean }>`
  width: 164px;
  height: 48px;
  cursor: pointer;
  font-weight: 700;
  background: ${props => (props.isMarket ? '#8247FF' : '#f56666')};
  color: white;
  font-size: 14px;
  border: 1px solid ${props => (props.isMarket ? '#8247FF' : '#f56666')};
  border-radius: 4px;
  transition: 0.3s linear;
  &:hover {
    opacity: 0.9;
  }
`;

export const NavMenu = styled('nav')`
  width: 100%;

  ul {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    padding-inline-start: 0px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #f56666;
    padding-right: 5%;
    text-align: left;
    list-style-position: inside;

    ${NavItem} {
      display: inline-flex;
      button {
        background: none;
        border: 0;
        display: inherit;
        justify-content: inherit;
        margin: inherit;
        font-size: inherit;
        font-weight: inherit;
        font-stretch: inherit;
        font-style: inherit;
        padding-inline-start: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        text-align: inherit;
        color: inherit;
      }
    }

    ${NavItemDisabled} {
      display: inline-flex;
      button {
        background: none;
        border: 0;
        display: inherit;
        justify-content: inherit;
        margin: inherit;
        font-size: inherit;
        font-weight: inherit;
        font-stretch: inherit;
        font-style: inherit;
        padding-inline-start: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        text-align: inherit;
        color: inherit;
      }
    }
  }
`;

export const NavIcons = styled('nav')`
  height: 100%;
  margin-top: 5px;

  ul {
    display: flex;
    padding-inline-start: 0px;
    margin: 0px;

    li {
      display: inline-flex;
    }
  }

  figure {
    width: 24px;
    height: 20.4px;
    @include figureInit();

    img {
      margin-top: 4px;
      width: 20px;
    }
  }

  li:not(:last-child) {
    margin-right: 20px;
  }
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
  svg {
    fill: #fff;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;
export const NavCloseOutlined = styled(CloseOutlined)`
  font-size: 3rem;
  font-weight: bold;
  svg {
    fill: #fff;
  }
`;

export const Burger = styled('div')`
  margin-left: auto;
  margin-right: 0;
  @media only screen and (max-width: 890px) {
    display: block;
  }
  display: none;
  svg {
    fill: #fff;
  }
`;
export const MenuTitle = styled('h5')`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;
export const Label = styled('span')`
  font-weight: 500;
  color: #f56666;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const MobileNavWrapper = styled('div')`
  ${NavCloseOutlined} {
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }

  ul {
    padding-left: 40px;
    margin-bottom: 50px;
  }
  ${NavItem} {
    display: block;
    color: #f56666;
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 30px;
  }
  ${NavItemDisabled} {
    display: block;
    color: #a4a4a4;
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 30px;
  }
  ${NavIcons} {
    figure {
      width: 40px;
      margin-right: 20px;
      img {
        margin-top: 4px;
        width: 40px;
      }
    }
  }
`;

export const CustomModalTitle = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

export const WalletModalLogo = styled.img.attrs({
  src: '/assets/connect_wallet_logo.png',
  alt: 'connect wallet'
})`
  width: 110px;
`;

export const ConnectWalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConnectWalletButton = styled.button`
  cursor: pointer;
  margin-top: 50px;
  padding: 10px;
  width: 100%;
  background-color: #f56666;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  border: 1px solid #f56666;
`;

export const HavahWalletIntro = styled.div`
  margin-top: 50px;
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
`;

export const HavahWalletLinkButton = styled.button`
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  background-color: #ffffff;
  color: #f56666;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #f56666;

  a {
    width: 100%;
    display: flex;
    color: #f56666;
    justify-content: center;

    &:hover {
      color: #f56666;
    }
  }
`;

export const ConnectWalletModal = styled(Modal)`
  .ant-modal-close-x {
    font-size: 24px;
  }
`;
