import { DefaultValue, selector } from 'recoil';
import { toShortAddress } from '../../common/Utils';
import { Address } from '../../types';
import { walletAtom } from './atom';

export const walletAddressSelector = selector<Address>({
  key: 'walletAddressSelector',
  get: ({ get }) => get(walletAtom).address,
  set: ({ get, set }, newAddr) => {
    if (newAddr instanceof DefaultValue) return;
    set(walletAtom, {
      ...get(walletAtom),
      address: newAddr,
      shortAddr: toShortAddress(newAddr)
    });
  }
});

export const walletSortAddrSelector = selector<string>({
  key: 'walletSortAddrSelector',
  get: ({ get }) => get(walletAtom).shortAddr
});
