import React, { useContext, useState } from 'react';
import { useRecoilState } from 'recoil';

import styled from 'styled-components';
import { NotiContext } from '../../../..';
import { walletAddressSelector } from '../../../../recoil/wallet/selectors';
import {
  ConnectWalletContainer,
  ConnectWalletModal,
  CustomModalTitle,
  HavahWalletIntro,
  HavahWalletLinkButton,
  WalletModalLogo
} from '../styles';
import { ErrorMessage } from '../../../../styles/common';
import { WalletButton } from './WalletButton';
import { UserAvatar } from './UserAvatar';
import { useCookies } from 'react-cookie';

interface WalletProps {
  isMarket?: boolean;
}

export const Wallet = ({ isMarket }: WalletProps) => {
  const [walletAddress, setWalletAddress] = useRecoilState(
    walletAddressSelector
  );
  const [cookie, setCookie] = useCookies(['walletAddress']);

  const [openConnectWallet, setOpenConnectWallet] = useState(false);

  const { notify } = useContext(NotiContext);

  return (
    <WalletWrapper>
      {walletAddress !== '' && <UserAvatar />}
      <WalletButton
        isMarket={isMarket}
        setOpenConnectWallet={(status: boolean) => {
          setOpenConnectWallet(status);
        }}
      />
      <ConnectWalletModal
        title={<CustomModalTitle>Connect Wallet</CustomModalTitle>}
        open={openConnectWallet}
        onCancel={() => {
          setOpenConnectWallet(false);
        }}
        closable
        width={600}
        footer={null}
      >
        <ConnectWalletContainer>
          <WalletModalLogo />
          <HavahWalletIntro>
            HAVAH Wallet is an extension program for using distributed
            applications on the HAVAH Network
          </HavahWalletIntro>
          <HavahWalletLinkButton>
            <a
              href="https://chrome.google.com/webstore/detail/cnncmdhjacpkmjmkcafchppbnpnhdmon"
              target="_blank"
              rel="noreferrer"
            >
              Go to Chrome store for HAVAH Wallet install
            </a>
          </HavahWalletLinkButton>
        </ConnectWalletContainer>
      </ConnectWalletModal>
    </WalletWrapper>
  );
};

const WalletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  min-width: 300px;
`;
