import { Button, Col, Collapse, Input, Row } from 'antd';
import BigNumber from 'bignumber.js';
import React, { useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import CommonPageContainer from '../../../components/CommonPageContainer';
import configs from '../../../configs';
import { walletAtom } from '../../../recoil/wallet/atom';

const { Panel } = Collapse;

function getValue(id: string): string {
  return (document.querySelector(`#${id}`) as HTMLInputElement).value;
}

const marketAddr = 'cx74bc753c6940f556c96b0b0d3a521a6abd6c9509';
const nftAddr = 'cx37f9e62e72c6b7641aa4f9859b19bcade2724b1a';
const hvhAddr = 'cx0000000000000000000000000000000000000000';

export const NftMarketTxTest = () => {
  const walletInfo = useRecoilValue(walletAtom);
  const { nftService, swapService } = configs;
  return (
    <>
      <Row>
        <Col span={24}>
          <h1>mintNft</h1>
          <Input
            addonBefore="NftAddress"
            id="mintNft-1"
            defaultValue={nftAddr}
          ></Input>
          <Input addonBefore="nftId" id="mintNft-2"></Input>
          <Button
            onClick={async () => {
              const nftAddress = getValue('mintNft-1');
              const nftId = getValue('mintNft-2');
              const res = await nftService.mint(nftAddress, nftId, {
                addr: walletInfo.address
              });
              console.log(res);
            }}
          >
            Execute
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h1>isApprovedForAll</h1>
          <Input
            addonBefore="NftAddress"
            id="getApproved1"
            defaultValue={nftAddr}
          ></Input>
          <Input
            addonBefore="Operator"
            id="getApproved2"
            defaultValue={marketAddr}
          ></Input>
          <Button
            onClick={async () => {
              const nftAddress = getValue('getApproved1');
              const operator = getValue('getApproved2');
              const res = await nftService.isApprovedForAll(
                nftAddress,
                operator,
                {
                  addr: walletInfo.address
                }
              );
              console.log(res);
            }}
          >
            Execute
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h1>setApprovalForAll</h1>
          <Input
            addonBefore="NftAddress"
            id="setApprove1"
            defaultValue={nftAddr}
          ></Input>
          <Input
            addonBefore="Operator"
            id="setApprove2"
            defaultValue={marketAddr}
          ></Input>
          <Button
            onClick={async () => {
              const nftAddress = getValue('setApprove1');
              const operator = getValue('setApprove2');
              const res = await nftService.setApprovalForAll(
                nftAddress,
                operator,
                {
                  addr: walletInfo.address
                }
              );
              console.log(res);
            }}
          >
            Execute
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h1>retriveApprovalForAll</h1>
          <Input
            addonBefore="NftAddress"
            id="setApprove1"
            defaultValue={nftAddr}
          ></Input>
          <Input
            addonBefore="Operator"
            id="setApprove2"
            defaultValue={marketAddr}
          ></Input>
          <Button
            onClick={async () => {
              const nftAddress = getValue('setApprove1');
              const operator = getValue('setApprove2');
              const res = await nftService.retrieveApprovalForAll(
                nftAddress,
                operator,
                {
                  addr: walletInfo.address
                }
              );
              console.log(res);
            }}
          >
            Execute
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h1>putOnSale</h1>
          <Input
            addonBefore="NftAddress"
            id="putOnSale1"
            defaultValue={nftAddr}
          ></Input>
          <Input addonBefore="nftId" id="putOnSale2"></Input>
          <Input
            addonBefore="priceContract"
            defaultValue={hvhAddr}
            id="putOnSale3"
          ></Input>
          <Input
            addonBefore="price"
            id="putOnSale4"
            defaultValue={'1000000000000000000'}
          ></Input>
          <Button
            onClick={async () => {
              const nftAddress = getValue('putOnSale1');
              const nftId = getValue('putOnSale2');
              const priceContract = getValue('putOnSale3');
              const price = new BigNumber(getValue('putOnSale4'));
              const res = await nftService.putOnSale(
                'hsp721',
                nftAddress,
                nftId,
                priceContract,
                price,
                new BigNumber(1),
                30,
                {
                  addr: walletInfo.address
                }
              );
              console.log(res);
            }}
          >
            Execute
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h1>cancelSale</h1>
          <Input
            addonBefore="NftAddress"
            id="cancelSale-1"
            defaultValue={nftAddr}
          ></Input>
          <Input addonBefore="nftId" id="cancelSale-2"></Input>
          <Button
            onClick={async () => {
              const nftAddress = getValue('cancelSale-1');
              const nftId = getValue('cancelSale-2');
              const res = await nftService.cancelSale(
                'hsp721',
                nftAddress,
                nftId,
                new BigNumber(1),
                {
                  addr: walletInfo.address
                }
              );
              console.log(res);
            }}
          >
            Execute
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h1>buySale</h1>
          <Input
            addonBefore="NftAddress"
            id="buySale-1"
            defaultValue={nftAddr}
          ></Input>
          <Input addonBefore="nftId" id="buySale-2"></Input>
          <Input
            addonBefore="priceContract"
            defaultValue={hvhAddr}
            id="buySale-3"
          ></Input>
          <Input
            addonBefore="price"
            id="buySale-4"
            defaultValue={'1000000000000000000'}
          ></Input>
          <Button
            onClick={async () => {
              const nftAddress = getValue('cancelSale-1');
              const nftId = getValue('cancelSale-2');
              const priceContract = getValue('buySale-3');
              const price = new BigNumber(getValue('buySale-4'));
              const res = await nftService.buySale(
                'hsp721',
                nftAddress,
                nftId,
                priceContract,
                price,
                new BigNumber(1),
                '',
                {
                  addr: walletInfo.address
                }
              );
              console.log(res);
            }}
          >
            Execute
          </Button>
        </Col>
      </Row>
    </>
  );
};
