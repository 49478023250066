import React, { Suspense } from 'react';
import CommonPageContainer from '../../components/CommonPageContainer';
import StakeTable from './components/StakeTable';
import {
  StakeContainer,
  StakeContent,
  StakeNotice,
  StakeSubTitle,
  StakeTitle
} from './styles';

const StakePage = () => {
  return (
    <CommonPageContainer>
      <StakeContainer>
        <StakeTitle>Farm</StakeTitle>
        <StakeSubTitle>Stake LP tokens to earn.</StakeSubTitle>
        <StakeContent>
          <StakeNotice>* The event may end without prior notice.</StakeNotice>
          <StakeTable></StakeTable>
        </StakeContent>
      </StakeContainer>
    </CommonPageContainer>
  );
};

export default StakePage;
