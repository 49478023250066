import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NotiContext } from '../../../..';
import {
  walletAddressSelector,
  walletSortAddrSelector
} from '../../../../recoil/wallet/selectors';
import { ErrorMessage } from '../../../../styles/common';
import { ConnectButton } from '../styles';

interface WalletButtonProps {
  setOpenConnectWallet: (status: boolean) => void;
  isMarket?: boolean;
}

export const WalletButton = ({
  setOpenConnectWallet,
  isMarket
}: WalletButtonProps) => {
  const [walletAddress, setWalletAddress] = useRecoilState(
    walletAddressSelector
  );
  const walletShortAddr = useRecoilValue(walletSortAddrSelector);

  const { notify } = useContext(NotiContext);
  const [cookie, setCookie] = useCookies(['walletAddress']);

  useEffect(() => {
    if (cookie.walletAddress) {
      setWalletAddress(cookie.walletAddress);
    }
  }, [cookie.walletAddress]);

  const connectByHavahWallet = async () => {
    const connect = await (window as any).havah?.connect();
    const res = connect?.body ?? connect ?? {};
    const { address, nid } = res;
    // if (nid === '0x101' && address !== '') {
    setCookie('walletAddress', address, {
      expires: new Date(Date.now() + 1000 * 60 * 60)
    });
    setWalletAddress(address);
    setOpenConnectWallet(false);
    // } else if (nid === '0x100' && address !== '') {
    //   notify(<ErrorMessage>Please change to Testnet</ErrorMessage>);
    // }
  };

  const connectWallet = () => {
    if (walletAddress) {
      setCookie('walletAddress', '');
      setWalletAddress('');
    } else if ((window as any).havah) {
      connectByHavahWallet();
    } else {
      setOpenConnectWallet(true);
    }
  };

  const dropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      )
    },
    {
      key: '2',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item
        </a>
      )
    },
    {
      key: '3',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          3rd menu item
        </a>
      )
    }
  ];

  return walletAddress && walletAddress !== '' ? (
    // <Dropdown menu={{ items: dropdownItems }} placement={'bottom'}>
    <ConnectButton
      isMarket={isMarket}
      onClick={async () => {
        connectWallet();
      }}
    >
      {walletShortAddr}
    </ConnectButton>
  ) : (
    // </Dropdown>
    <ConnectButton
      isMarket={isMarket}
      onClick={async () => {
        connectWallet();
      }}
    >
      Connect Wallet
    </ConnectButton>
  );
};
