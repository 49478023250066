import BigNumber from 'bignumber.js';
import IconService from 'icon-sdk-js';
import { convertDecimal, getStakePools } from '../../common/Utils';
import configs from '../../configs';
import {
  Address,
  StakeGetUserInfoResp,
  StakePoolInfo,
  TxConfig,
  UserStakeInfo,
  WalletTxRes
} from '../../types';
import { StakingService } from '../StakingService';
import axios from 'axios';

const { IconConverter, HttpProvider } = IconService;
const { CallTransactionBuilder, CallBuilder } = IconService.IconBuilder;

export class HavahStakingService implements StakingService {
  service: IconService;
  apiHost: string;
  stakePools: StakePoolInfo[] = [];

  constructor(
    apiHost = 'http://localhost:3005',
    rpcHost = 'https://ctz.vega.havah.io/api/v3'
  ) {
    this.service = new IconService(new HttpProvider(rpcHost) as any);
    this.apiHost = apiHost;

    this.initialize();
  }

  private async initialize(): Promise<void> {
    try {
      // this.stakePools = await getStakePools(this.apiHost);
      this.stakePools = (await axios.get(`${this.apiHost}/stake`))
        .data as StakePoolInfo[];
    } catch (e) {
      console.log(e);
    }
  }

  private async sendTx(txObj: any): Promise<WalletTxRes> {
    return (await (window as any)?.havah?.sendTransaction({
      ...txObj,
      ...txObj?.data
    })) satisfies WalletTxRes;
  }

  getStakePoolByLpAddress(lpAddr: Address): StakePoolInfo | undefined {
    const stakePool = this.stakePools?.find(
      pool => pool.lpTokenAddress === lpAddr
    );

    return stakePool;
  }

  async stake(
    lpAddr: Address,
    amount: BigNumber,
    lockupDays: number,
    txConfig: TxConfig
  ): Promise<WalletTxRes> {
    // Comment Out for Test

    // if (lockupDays < 7 || lockupDays > 365) {
    //   throw new Error('Lockup days must be between 7 and 365');
    // }

    const stakePool = this.getStakePoolByLpAddress(lpAddr);

    if (!stakePool) {
      throw new Error(`There's no pool for ${lpAddr}`);
    }

    const txObj = new CallTransactionBuilder()
      .from(txConfig.addr)
      .to(stakePool.address)
      .method('stakeByDays')
      .params({
        _amount: amount.toFixed(0),
        _days: lockupDays.toString()
      })
      .build();

    return this.sendTx(txObj);
  }

  async unstake(
    amount: BigNumber,
    stakePoolAddress: Address,
    txConfig: TxConfig
  ): Promise<WalletTxRes> {
    const txObj = new CallTransactionBuilder()
      .from(txConfig.addr)
      .to(stakePoolAddress)
      .method('withdraw')
      .params({
        _amount: amount.toFixed(0)
      })
      .build();

    return this.sendTx(txObj);
  }

  async harvest(
    stakePoolAddress: Address,
    walletAddr: Address
  ): Promise<WalletTxRes> {
    const allowTxObj = new CallTransactionBuilder()
      .from(walletAddr)
      .to(stakePoolAddress)
      .method('claimReward')
      .stepLimit(1000000)
      .build();
    return await this.sendTx(allowTxObj);
  }

  async getUserStakeInfo(
    lpAddr: Address,
    txConfig: TxConfig
  ): Promise<UserStakeInfo | undefined> {
    const stakePool = this.getStakePoolByLpAddress(lpAddr);
    if (!stakePool) {
      return;
    }

    const call = new CallBuilder()
      .to(stakePool.address)
      .method('getUserInfo')
      .params({
        user: txConfig.addr
      })
      .build();

    const res = (await this.service
      .call(call)
      .execute()) as StakeGetUserInfoResp;

    if (!res) {
      return;
    }

    return {
      stakePoolInfo: stakePool,
      balance: new BigNumber(res.balance),
      releaseTime: new Date(parseInt(res.releaseTime) / 1000),
      rewardDebt: new BigNumber(res.rewardDebt),
      reward: new BigNumber(res.reward)
    };
  }
}
