import BigNumber from 'bignumber.js';
import { LaunchpadApiService } from '..';
import {
  LaunchpadCrossChainVerifyApiRequest,
  LaunchpadCrossChainVerifyApiResponse,
  LaunchpadCrossChainVerifyRequest,
  LaunchpadProjectInfo,
  LaunchpadProjectMintingInfo,
  MintingStages
} from '../../types';
import axios, { Axios } from 'axios';

export class HavahLaunchpadApiService implements LaunchpadApiService {
  client: Axios;
  constructor(host: string) {
    this.client = axios.create({
      baseURL: host
    });
  }

  async getLaunchpadList(): Promise<LaunchpadProjectInfo[]> {
    const projects = (
      await this.client.get<LaunchpadProjectInfo[]>('/launchpad/projects')
    ).data;
    return projects.map(project => ({
      ...project,
      startDate: new Date(project?.startDate ?? new Date()),
      endDate: new Date(project?.endDate ?? new Date())
    }));
  }

  async getLaunchpadProjectInfoById(id: string): Promise<LaunchpadProjectInfo> {
    const project = (
      await this.client.get<LaunchpadProjectInfo>(`/launchpad/project/${id}`)
    ).data;
    const startDate = project.startDate
      ? new Date(project.startDate)
      : undefined;
    const endDate = project.endDate ? new Date(project.endDate) : undefined;
    return {
      ...project,
      startDate,
      endDate
    };
  }

  async verifyCrossChain(
    request: LaunchpadCrossChainVerifyRequest
  ): Promise<LaunchpadCrossChainVerifyApiResponse | undefined> {
    const apiReqiest: LaunchpadCrossChainVerifyApiRequest = {
      havahAddress: request.havahAddress,
      targetAddress: request.targetAddress,
      timestamp: request.timestamp.toString(),
      signature: request.signature
    };
    try {
      const apiMintingInfo =
        await this.client.post<LaunchpadCrossChainVerifyApiResponse>(
          `/launchpad/mintingInfo/${request.projectId}/crosschain-verify`,
          apiReqiest
        );
      return apiMintingInfo.data;
    } catch (e) {
      return undefined;
    }
  }

  async getMintingInfo(
    projectId: string,
    walletAddress: string
  ): Promise<LaunchpadProjectMintingInfo> {
    const apiMintingInfo = (
      await this.client.get(
        `/launchpad/mintingInfo/${projectId}?walletAddress=${walletAddress}`
      )
    ).data;
    return {
      ...apiMintingInfo,
      mintingStages: apiMintingInfo.mintingStages.map(
        (stage: MintingStages) => ({
          ...stage,
          price: new BigNumber(stage.price)
        })
      )
    } as LaunchpadProjectMintingInfo;
  }
}
