import axios, { Axios } from 'axios';
import BigNumber from 'bignumber.js';
import { ProfileApiService } from '..';
import {
  Address,
  ApiAsteroidHistory,
  ApiUserAsteroidInfo,
  ApiUserTokenInfo,
  AsteroidHistory,
  NftInfo,
  RawNftInfo,
  UserAsteroidInfo,
  UserInfo,
  UserTokenInfo
} from '../../types';
import { explodeRawNftItem } from 'common/Utils';

export class HavahProfileApiService implements ProfileApiService {
  client: Axios;
  constructor(host = 'http://localhost:3005') {
    this.client = axios.create({
      baseURL: host
    });
  }

  async getUserInfo(address: Address): Promise<UserInfo> {
    return (await this.client.get<UserInfo>(`/profile/${address}`)).data;
  }

  async getNftsByAddress(address: string): Promise<NftInfo[]> {
    if (!address) return [];
    try {
      const nfts: NftInfo[] = (
        await this.client.get<RawNftInfo[]>(`/profile/${address}/nft`)
      ).data
        .map(nftInfo => explodeRawNftItem(nftInfo))
        .flat()
        .map(nft => ({
          ...nft,
          owner: nft.owner && {
            ...nft.owner,
            balance: new BigNumber(nft.owner?.balance ?? 0)
          }
        })) satisfies NftInfo[];

      return nfts;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async getTokensByAddress(address: string): Promise<UserTokenInfo[]> {
    const tokenData = (
      await this.client.get<ApiUserTokenInfo[]>(`/profile/${address}/token`)
    ).data;
    return tokenData.map(token => ({
      ...token,
      balance: new BigNumber(token.balance)
    }));
  }

  async getAsteroidInfoByAddress(address: string): Promise<UserAsteroidInfo> {
    try {
      if (address == '') {
        return {
          asteroids: [],
          distributedReward: new BigNumber('0')
        };
      }
      const asteroidData = (
        await this.client.get<ApiUserAsteroidInfo>(`/asteroid/${address}`)
      ).data;
      return {
        asteroids: asteroidData.asteroids.map(asteroid => ({
          ...asteroid,
          claimable: new BigNumber(asteroid.claimable),
          isRewardable: asteroid.isRewardable === '0x1'
        })),
        distributedReward: new BigNumber(asteroidData.distributedReward)
      };
    } catch (e) {
      console.log(e);
      return {
        asteroids: [],
        distributedReward: new BigNumber('0')
      };
    }
  }

  async getAsteroidHistory(asteroidId: string): Promise<AsteroidHistory> {
    try {
      const asteroidHistory = (
        await this.client.get<ApiAsteroidHistory>(
          `/asteroid/${asteroidId}/history`
        )
      ).data;
      return {
        ...asteroidHistory,
        history: asteroidHistory.history.map(log => ({
          ...log,
          timestamp: new Date(log.timestamp),
          reward: new BigNumber(log.reward)
        }))
      };
    } catch (e) {
      console.log(e);
      return {
        id: asteroidId,
        history: []
      };
    }
  }
}
