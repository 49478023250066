import BigNumber from 'bignumber.js';

export type Address = string;

export enum SwapSide {
  Positive = 'Positive',
  Negative = 'Negative'
}

export interface SwapInfo {
  origin: Address;
  dest: Address;
  side: SwapSide;
  amount: BigNumber;
  slippage?: BigNumber; // 100 = 1%
}

export interface APITokenData {
  address: Address;
  name: string;
  symbol: string;
  decimal: BigNumber;
  imageUrl?: string;
}

export type Token = APITokenData;

export interface TokenAndBalance {
  token: Token;
  balance: BigNumber;
  ownerAddress: Address;
}

export interface APIPoolData {
  poolAddress: Address;
  tokens: Token[];
  feeRateAtoB: BigNumber;
  feeRateBtoA: BigNumber;
  feeRate: BigNumber;
  feeBase: BigNumber;
  poolVolume: number;
  aprRate: number;
  apyRate: number;
  awardTokens: Token[];
  isHavahPool: boolean;
  isStablePool: boolean;
  listed: boolean;
}

export interface Pool {
  address: Address;
  tokenA: Token;
  tokenB: Token;
  feeRateAtoB: BigNumber;
  feeRateBtoA: BigNumber;
  feeRate: BigNumber;
  feeBase: BigNumber;
  poolVolume: number;
  aprRate: number;
  apyRate: number;
  awardTokens: Token[];
  isHavahPool: boolean;
  isStablePool: boolean;
  listed: boolean;
}

export interface RouteInfoPool extends Pool {
  balances: { [key: Address]: BigNumber };
}

export interface APIRouteInfoPool extends APIPoolData {
  balances: { [key: Address]: string };
}

export interface APIRouteData {
  path: Address[];
  poolPath: APIRouteInfoPool[];
  outputAmount: string;
  inputAmount: string;
}

export interface RouteData
  extends Omit<APIRouteData, 'poolPath' | 'outputAmount' | 'inputAmount'> {
  outputAmount: BigNumber;
  inputAmount: BigNumber;
  poolPath: RouteInfoPool[];
}

export type SwapRouteInfo = Pick<
  RouteData,
  'path' | 'inputAmount' | 'outputAmount' | 'poolPath'
>;

export interface WalletTxRes {
  type: 'cancel' | 'success';
  txHash: string;
}

export interface HavahSignWalletResponse {
  signData: {
    signature: string;
  };
  type: string;
}

export interface HavahSignResponse {
  signature: string;
  timestamp: number;
  targetAddress: string;
}

export interface TxConfig {
  addr: Address;
}
export interface NftSaleInfo {
  nftId: string;
  price: string;
  priceAddress: string;
  priceTicker: string;
  seller: string;
  value: string;
}

export type ChainEventType =
  | 'SalePlaced'
  | 'SaleCanceled'
  | 'SaleMatched'
  | 'Transfer'
  | string;

export interface ChainEvent {
  blockNumber: number;
  address: Address;
  event: ChainEventType;
  data: string;
  timestamp: string;
}

export interface TransferEvent extends ChainEvent {
  event: 'Transfer';
  from: Address;
  to: Address;
  nftAddress: Address;
  nftId: string;
}

export interface NftMarketEvent extends ChainEvent {
  nftAddress: Address;
  nftId: string;
}

export interface SalePlacedEvent extends NftMarketEvent {
  event: 'SalePlaced';
  seller: Address;
  priceContract: Address;
  price: string;
}

export interface SaleCanceledEvent extends NftMarketEvent {
  event: 'SaleCanceled';
  operator: Address;
}

export interface SaleMatchedEvent extends NftMarketEvent {
  event: 'SaleMatched';
  priceContract: Address;
  price: string;
  seller: Address;
  buyer: Address;
  value: string;
}

export type EventMixin =
  | ChainEvent
  | TransferEvent
  | SalePlacedEvent
  | SaleCanceledEvent
  | SaleMatchedEvent;

export function isNftMarketEvent(event: ChainEvent): event is NftMarketEvent {
  return (
    event.event === 'SaleCanceled' ||
    event.event === 'SaleMatched' ||
    event.event === 'SalePlaced'
  );
}

export function isSalePlacedEvent(event: ChainEvent): event is SalePlacedEvent {
  return event.event === 'SalePlaced';
}

export function isSaleCanceledEvent(
  event: ChainEvent
): event is SaleCanceledEvent {
  return event.event === 'SaleCanceled';
}

export function isSaleMatchedEvent(
  event: ChainEvent
): event is SaleMatchedEvent {
  return event.event === 'SaleMatched';
}

export function isTransferEvent(event: ChainEvent): event is TransferEvent {
  return event.event === 'Transfer';
}

export interface NftItemAttribute {
  trait_type: string;
  value: string;
}

export type Metadata = {
  [key: string]: any;
  attributes?: NftItemAttribute[];
  description?: string;
};

export interface NftOwnerInfo {
  owner: Address;
  balance: BigNumber;
}

export interface NftInfo {
  id: string;
  metadata: Metadata;
  imgUrl: string;
  videoUrl: string;
  project: ProjectInfo;
  nftHistory: EventMixin[];
  saleInfo?: NftSaleInfo;
  saleInfos?: NftSaleInfo[];
  owner: NftOwnerInfo;
}

export interface RawNftInfo extends Omit<NftInfo, 'owner' | 'saleInfo'> {
  owners: NftOwnerInfo[];
  saleInfos: NftSaleInfo[];
}

export interface APINftInfo {
  nftInfo: RawNftInfo[];
  project: ProjectInfo;
}

export interface ProjectInfo {
  type: ProjectType;
  projectId: number;
  address: Address;
  totalSupplyCount: number;
  projectName: string;
  description: string;
  imgProfileUrl: string;
  imgBackgroundUrl: string;
  creatorFee: string;
  publishedDate: string;
  tradedVolume: string;
  floorSale?: NftSaleInfo;
  snsInfos: ProjectSnsInfo[];
}

export interface ProjectSnsInfo {
  snsType: string;
  url: string;
  seq: number;
}

export interface UserInfo {
  address: Address;
  profileUrl: string;
  point: string;
}

export type ProjectAttributes = {
  [traitType: string]: { [value: string]: { nftIds: string[] } };
};

export interface NftItemQuery {
  limit?: number;
}

export interface AttributeQuery extends NftItemQuery {
  attributes?: { [traitType: string]: string[] };
}

export interface Config {
  wHVHAddress: string;
  havah: {
    rpcHost: string;
  };
  market: {
    address: string;
  };
  hsp1155Market: {
    address: string;
  };
  swap: {
    router: {
      address: string;
    };
  };
  asteroid: {
    address: string;
  };
}

export type PoolTokenBalances = {
  [poolAddr: Address]: { [tokenAddr: Address]: BigNumber };
};

export interface StakeEarning {
  txFeeReward: string;
  hpReward: string;
  eventReward: string;
}

export interface StakePoolInfo {
  address: string;
  lpTokenAddress: string;
}
export interface StakeInfo extends Omit<StakePoolInfo, 'lpTokenAddress'> {
  lpAddress: string;
  stakeAddress: string;
  pool: APIPoolData;
  totalStaked: BigNumber;
  earnings: StakeEarning;
  aprRate: number;
  userLpBalance: BigNumber;
  userStakeBalance: BigNumber;
  userHvhReward: BigNumber;
  stakeReleaseDate: number;
  totalLpBalance: BigNumber;
  userLpAmountValue: number;
  userStakeValue: number;
}

export interface StakeGetUserInfoResp {
  balance: string;
  releaseTime: string;
  reward: string;
  rewardDebt: string;
}

export interface UserStakeInfo {
  stakePoolInfo: StakePoolInfo;
  balance: BigNumber;
  releaseTime: Date;
  reward: BigNumber;
  rewardDebt: BigNumber;
}

export interface LaunchpadMetadata {
  type: string;
  descriptions?: string[];
  profileImg?: string;
  descriptionImg?: string;
}

export interface LaunchpadProjectInfo {
  id: string;
  name: string;
  team: string;
  startDate?: Date;
  endDate?: Date;
  profileImgUrl?: string;
  projectImgUrl?: string;
  data?: LaunchpadMetadata;
  status: 'upcoming' | 'ongoing' | 'finished';
  navigateTo?: string;
}

export interface MintingStages {
  name: string;
  price: BigNumber;
  totalMintingCount: number;
  remains: number;
  maxMintPerTx: number;
  duration: string;
  startTime: string;
}

export interface LaunchpadProjectMintingInfo {
  id: string;
  minterAddress: Address;
  currentStageIndex: number;
  mintingStages: MintingStages[];
  mintingToken: Token;
  isEligible: boolean;
}

export interface LaunchpadCrossChainVerifyRequest {
  projectId: string;
  havahAddress: string;
  targetAddress: string;
  timestamp: number;
  signature: string;
}
export interface LaunchpadCrossChainVerifyApiRequest {
  havahAddress: string;
  targetAddress: string;
  timestamp: string;
  signature: string;
}

export interface LaunchpadCrossChainVerifyApiResponse {
  targetAddress: string;
  signer: string;
  contractAddress: string;
  round: number;
  signature: string;
  timestamp: string;
}

export interface LaunchpadCrossChainMintVerifiedData {
  caller: string;
  signer: string;
  origin: string;
  round: number;
}

export interface UserTokenInfo extends Token {
  type: 'hsp20' | 'lpPool';
  balance: BigNumber;
  tokenPrice: number;
}

export interface ApiUserTokenInfo extends Omit<UserTokenInfo, 'balance'> {
  balance: string;
  tokenPrice: number;
}

export type ProjectType = 'hsp721' | 'hsp1155';

export interface ApiUserAsteroidStatus {
  id: string;
  isRewardable: string;
  claimable: string;
  planetId: string;
}

export interface ApiUserAsteroidInfo {
  asteroids: ApiUserAsteroidStatus[];
  distributedReward: string;
}

export interface UserAsteroidStatus {
  id: string;
  isRewardable: boolean;
  claimable: BigNumber;
  planetId: string;
}

export interface UserAsteroidInfo {
  asteroids: UserAsteroidStatus[];
  distributedReward: BigNumber;
}

export interface ApiAsteroidLog {
  timestamp: string;
  reward: string;
}
export interface ApiAsteroidHistory {
  id: string;
  history: ApiAsteroidLog[];
}

export interface AsteroidLog {
  timestamp: Date;
  reward: BigNumber;
}

export interface AsteroidHistory {
  id: string;
  history: AsteroidLog[];
}
