import { createGlobalStyle } from 'styled-components';

export const Styles = createGlobalStyle`
  html, body {
    font-family: ubuntu, Sans-Serif;
  }
  .hs_message {
    .ant-message-notice-content {
      padding: 0;
      width: 50%;
      min-width: 250px;
      background-color: unset;
      height: 50px;
      box-shadow: unset;
    }
  }
`;
