import { SwapApiService } from '../../api';
import axios, { Axios } from 'axios';
import BigNumber from 'bignumber.js';
import {
  Address,
  Pool,
  RouteData,
  SwapInfo,
  Token,
  APIPoolData,
  APIRouteData,
  APITokenData,
  RouteInfoPool,
  UserInfo,
  StakeInfo,
  StakePoolInfo
} from '../../types';
import { DEFAULT_IMAGE_URL } from '../../common/Utils';

export class HavahSwapApiService implements SwapApiService {
  client: Axios;
  constructor(host: string) {
    this.client = axios.create({
      baseURL: host
    });
  }
  async getTokenList(): Promise<Token[]> {
    const response = (await this.client.get<APITokenData[]>('/token')).data;
    return response.map(token => ({
      ...token,
      decimal: new BigNumber(token.decimal),
      imageUrl: token.imageUrl ?? DEFAULT_IMAGE_URL
    })) satisfies Token[];
  }
  async getPoolList(): Promise<Pool[]> {
    const poolData = (await this.client.get<APIPoolData[]>('/pool')).data;

    return poolData.map(poolDatum => {
      const [token0, token1] = poolDatum.tokens.map(token => ({
        ...token,
        decimal: new BigNumber(token.decimal),
        imageUrl: token.imageUrl ?? DEFAULT_IMAGE_URL
      })) satisfies Token[];
      return {
        ...poolDatum,
        address: poolDatum.poolAddress,
        tokenA: token0,
        tokenB: token1,
        feeBase: new BigNumber(poolDatum.feeBase),
        feeRate: new BigNumber(poolDatum.feeRate),
        feeRateAtoB: new BigNumber(poolDatum.feeRateAtoB),
        feeRateBtoA: new BigNumber(poolDatum.feeRateBtoA)
      } satisfies Pool;
    });
  }
  async getRoute(swapInfo: SwapInfo): Promise<RouteData> {
    const response = (
      await this.client.post<APIRouteData>('/swap/route', swapInfo)
    ).data;

    return {
      ...response,
      poolPath: response.poolPath.map(pool => {
        const balances: { [key: Address]: BigNumber } = {};
        const [token0, token1] = pool.tokens;
        for (const token in pool.balances) {
          balances[token] = new BigNumber(pool.balances[token]);
        }
        return {
          ...pool,
          address: pool.poolAddress,
          feeBase: new BigNumber(pool.feeBase),
          feeRate: new BigNumber(pool.feeRate),
          feeRateAtoB: new BigNumber(pool.feeRateAtoB),
          feeRateBtoA: new BigNumber(pool.feeRateBtoA),
          tokenA: token0.address < token1.address ? token0 : token1,
          tokenB: token0.address < token1.address ? token1 : token0,
          balances,
          listed: pool.listed
        } satisfies RouteInfoPool;
      }),
      inputAmount: new BigNumber(response.inputAmount),
      outputAmount: new BigNumber(response.outputAmount)
    };
  }
  async getRouterAddress(): Promise<Address> {
    return (await this.client.get<Address>('/swap/router')).data;
  }
  async getTokenPrices(): Promise<{ [key: Address]: number }> {
    const response = (await this.client.get('/token/prices')).data;
    return response;
  }
  async getTokenBalance(token: string, address: string): Promise<BigNumber> {
    const response = new BigNumber(
      (await this.client.get<string>(`/token/balance/${token}/${address}`)).data
    );
    return new BigNumber(response);
  }

  async getStakePools(): Promise<StakePoolInfo[]> {
    return await this.client
      .get<StakePoolInfo[]>('/stake')
      .then(res => res.data);
  }

  async getStakeInfo(address: string): Promise<StakeInfo[]> {
    try {
      const response = (
        await this.client.get<StakeInfo[]>(`/stake/wallet/${address}`)
      ).data;
      return response.map(stake => ({
        ...stake,
        totalStaked: new BigNumber(stake.totalStaked),
        userLpBalance: new BigNumber(stake.userLpBalance),
        userStakeBalance: new BigNumber(stake.userStakeBalance),
        userHvhReward: new BigNumber(stake.userHvhReward),
        totalLpBalance: new BigNumber(stake.totalLpBalance)
      })) satisfies StakeInfo[];
    } catch (e) {
      console.log(e);
      return [];
    }
  }
}
