import { Skeleton, Col } from 'antd';
import React from 'react';
import { useEffect, useState, useContext } from 'react';
import useInterval from 'use-interval';
import usePromise from 'react-promise-suspense';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useRecoilState, useRecoilValue } from 'recoil';
import configs from '../../../configs';
import { walletAddressSelector } from '../../../recoil/wallet/selectors';
import { StakeInfo } from '../../../types';
import { StakePoolContainer } from '../../swap/styles';
import TokenPairBox from '../../../components/TokenPairBox';
import TokenSingleBox from '../../../components/TokenSingleBox';
import { convertDecimal, dummyToken } from '../../../common/Utils';
import StakeModal from './StakeModal';
import {
  StakePoolCollapse,
  StakeTableHeader,
  StakePoolHeaderContainer,
  StakePoolActionContainer,
  StakePoolActionTitle,
  StakeActionButtonContainer,
  StakePoolActionButton
} from '../styles';
import { ErrorMessage } from 'styles/common';
import { NotiContext } from 'index';
import { transactionAtom } from '../../../recoil/transaction/atom';
import BigNumber from 'bignumber.js';

const Panel = StakePoolCollapse.Panel;
const tableSpans = [4, 4, 4, 5, 3, 4];
dayjs.extend(duration);

interface UserStakeRewardInfo {
  lpAddress?: string;
  reward?: BigNumber;
}

const StakePools = () => {
  const { apiService, stakingService } = configs;
  const walletAddress = useRecoilValue(walletAddressSelector);
  const [_refreshIdx, setRefreshIdx] = useState(0);
  const [_userRewardRefreshIdx, setUserRewardRefreshIdx] = useState(0);
  const [userRewardInfos, setUserRewardInfos] = useState<UserStakeRewardInfo[]>(
    []
  );
  const stakeInfos = usePromise(
    async _ => apiService.getStakeInfo(walletAddress),
    [`stakePools/${walletAddress}/${_refreshIdx}`]
  );

  const getUserRewardInfos = async () => {
    if (walletAddress !== '') {
      const userRewardInfos = await Promise.all(
        stakeInfos.map(async stakePool => {
          const stakeInfo = await stakingService.getUserStakeInfo(
            stakePool.lpAddress,
            { addr: walletAddress }
          );
          return {
            lpAddress: stakePool.lpAddress,
            reward: stakeInfo?.reward
          };
        })
      );
      setUserRewardInfos(userRewardInfos);
      setUserRewardRefreshIdx(Math.random());
    } else {
      setUserRewardInfos([]);
      setUserRewardRefreshIdx(Math.random());
    }
  };

  const refresh = () => {
    setRefreshIdx(Math.random());
    getUserRewardInfos();
  };

  useEffect(() => {
    refresh();
  }, [walletAddress]);

  useInterval(() => {
    // refreshUserReward();
    getUserRewardInfos();
  }, 8000);

  return (
    <>
      <div style={{ display: 'flex', backgroundColor: '#f8f8f8' }}>
        <StakeTableHeader>
          <Col span={tableSpans[0]}>Pair name</Col>
          <Col span={tableSpans[1]}>Total Liquidity</Col>
          <Col span={tableSpans[2]}>Distributed Tokens</Col>
          <Col span={tableSpans[3]}>Estimate earnings</Col>
          <Col span={tableSpans[4]}>APR</Col>
          <Col span={tableSpans[5]}>My LP</Col>
        </StakeTableHeader>
      </div>
      {stakeInfos &&
        stakeInfos.map((stakeInfo, idx) => {
          return (
            <StakePool
              key={`stake-pool-${idx}`}
              stakeInfo={stakeInfo}
              userRewardInfos={userRewardInfos}
              refreshIdx={_userRewardRefreshIdx}
              refresh={refresh}
            />
          );
        })}
    </>
  );
};

interface StakePoolProps {
  stakeInfo: StakeInfo;
  userRewardInfos: UserStakeRewardInfo[];
  refreshIdx: number;
  refresh: () => void;
}

const StakePool = ({
  stakeInfo,
  userRewardInfos,
  refreshIdx,
  refresh
}: StakePoolProps) => {
  const [_, setTransaction] = useRecoilState(transactionAtom);
  const { stakingService } = configs;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('stake');
  const walletAddress = useRecoilValue(walletAddressSelector);
  const { notify } = useContext(NotiContext);

  const [userRewardAmount, setUserRewardAmount] = useState(new BigNumber(0));

  useEffect(() => {
    const rewardTarget = userRewardInfos.filter(
      item => item.lpAddress === stakeInfo.lpAddress
    )[0];
    if (rewardTarget !== undefined) {
      setUserRewardAmount(rewardTarget.reward || new BigNumber(0));
    } else {
      setUserRewardAmount(new BigNumber(0));
    }
  }, [refreshIdx]);

  const getUserRewardAmount = (): BigNumber => {
    const rewardTarget = userRewardInfos.filter(
      item => item.lpAddress === stakeInfo.lpAddress
    )[0];

    if (rewardTarget !== undefined) {
      return rewardTarget.reward || new BigNumber(0);
    } else {
      return new BigNumber(0);
    }
  };

  const handleStakeButtonClick = () => {
    setActionType('stake');
    setIsModalOpen(true);
  };

  const handleUnStakeButtonClick = () => {
    setActionType('unstake');
    setIsModalOpen(true);
  };

  const renderRemaningTimeForUnstake = () => {
    const currentDate = dayjs();
    const targetDate = dayjs(stakeInfo.stakeReleaseDate);

    if (targetDate.isAfter(currentDate)) {
      const timeDifference = dayjs.duration(targetDate.diff(currentDate));
      const daysLeft = timeDifference.days();
      return (
        <>
          <StakePoolActionButton onClick={handleUnStakeButtonClick} disabled>
            UnStake LP
          </StakePoolActionButton>
          <div style={{ marginTop: '10px' }}>
            <span>{`${daysLeft} days ${timeDifference.format(
              'HH:mm:ss'
            )} left`}</span>
          </div>
        </>
      );
    } else {
      return (
        <StakePoolActionButton
          onClick={handleUnStakeButtonClick}
          disabled={
            stakeInfo.stakeReleaseDate == 0 || stakeInfo.userStakeBalance.eq(0)
          }
        >
          UnStake LP
        </StakePoolActionButton>
      );
    }
  };

  const harvestReward = async () => {
    try {
      setTransaction({
        pending: true
      });
      const res = await stakingService.harvest(
        stakeInfo.stakeAddress,
        walletAddress
      );
      refresh();
      if (res.type !== 'success') {
        notifyError('harvest');
        throw new Error('Failed to harvest reward');
      }
    } catch (e) {
      console.log(e, 'error');
    } finally {
      setTransaction({
        pending: false
      });
    }
  };

  const notifyError = (type: string = actionType) => {
    notify(
      <ErrorMessage>
        Failed to {type}. Please try again.
        <span>
          <img src="/assets/message_error.png" alt="error icon" />
        </span>
      </ErrorMessage>
    );
  };

  return (
    <StakePoolCollapse expandIconPosition={'end'}>
      <Panel
        key={stakeInfo.address}
        header={
          stakeInfo && (
            <StakePoolHeaderContainer>
              <Col span={tableSpans[0]}>
                <TokenPairBox
                  tokenA={stakeInfo.pool.tokens[0]}
                  tokenB={stakeInfo.pool.tokens[1]}
                  iconSize="24px"
                  showTokenName={true}
                />
              </Col>
              <Col span={tableSpans[1]}>
                ${stakeInfo.pool.poolVolume.toLocaleString()}
              </Col>
              <Col span={tableSpans[2]}>
                <TokenSingleBox
                  token={stakeInfo.pool.awardTokens[0] || dummyToken}
                  iconSize="32px"
                  showTokenSymbol={true}
                />
              </Col>
              <Col span={tableSpans[3]}>
                <div style={{ fontSize: '12px' }}>
                  <div>
                    <span style={{ color: '#999999' }}>
                      Transaction fee reward{' '}
                    </span>
                    <span>{(stakeInfo.pool.aprRate * 100).toFixed(4)}%</span>
                  </div>
                  <div>
                    <span style={{ color: '#999999' }}>HAVAHswap Point</span>
                  </div>
                </div>
              </Col>
              <Col span={tableSpans[4]}>
                <span style={{ color: '#000000' }}>
                  {/* {(stakeInfo.aprRate * 100).toFixed(2)} % */}
                  {/* TODO: Temporary correction due to server deploy issue. Have to undo after server stake apr logic corrected*/}
                  {(((stakeInfo.aprRate * 100) / 30000) * 8000).toFixed(2)} %
                </span>
              </Col>
              <Col span={tableSpans[5]}>
                <div>{convertDecimal(stakeInfo.userLpBalance)}</div>
                <div style={{ color: '#999999', fontSize: '11px' }}>
                  ( ~ {stakeInfo.userLpAmountValue.toFixed(3)} USD)
                </div>
              </Col>
            </StakePoolHeaderContainer>
          )
        }
      >
        <StakePoolActionContainer>
          <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
            <div style={{ width: '50%' }}>
              <StakePoolActionTitle>Reward Earned</StakePoolActionTitle>
              <div>
                <span style={{ marginRight: '10px', fontWeight: 600 }}>
                  HVH
                </span>
                <span>{convertDecimal(userRewardAmount)}</span>
              </div>
              <div>
                <span style={{ marginRight: '10px', fontWeight: 600 }}>HP</span>
                <span>{convertDecimal(userRewardAmount)}</span>
              </div>
            </div>
            <StakeActionButtonContainer>
              <StakePoolActionButton
                onClick={harvestReward}
                disabled={stakeInfo.userHvhReward.eq(0)}
              >
                Harvest
              </StakePoolActionButton>
            </StakeActionButtonContainer>
          </div>
          <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
            <div style={{ width: '50%' }}>
              <StakePoolActionTitle>
                Stake {stakeInfo.pool.tokens[0].symbol}-
                {stakeInfo.pool.tokens[1].symbol} LP
              </StakePoolActionTitle>
              <div>
                <span>{convertDecimal(stakeInfo.userStakeBalance)}</span>
              </div>
              <div style={{ color: '#999999', fontSize: '11px' }}>
                ( ~ {stakeInfo.userStakeValue.toFixed(3)} USD)
              </div>
            </div>
            <StakeActionButtonContainer>
              <StakePoolActionButton onClick={handleStakeButtonClick}>
                Stake Lp
              </StakePoolActionButton>
              <div style={{ marginTop: '10px' }}>
                {renderRemaningTimeForUnstake()}
              </div>
            </StakeActionButtonContainer>
          </div>
        </StakePoolActionContainer>
      </Panel>
      <StakeModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        stakeInfo={stakeInfo}
        hvhReward={convertDecimal(getUserRewardAmount())}
        tabOptionValue={actionType}
        lpName={`${stakeInfo.pool.tokens[0].symbol} -
        ${stakeInfo.pool.tokens[1].symbol} LP`}
        onConfirm={async () => {
          refresh();
        }}
        notifyError={() => {
          notifyError();
        }}
      />
    </StakePoolCollapse>
  );
};

const StakePoolsSkeleton = () => {
  return (
    <>
      <StakePoolContainer>
        <Skeleton paragraph={{ rows: 2 }} active={true} />
      </StakePoolContainer>
      <StakePoolContainer>
        <Skeleton paragraph={{ rows: 2 }} active={true} />
      </StakePoolContainer>
      <StakePoolContainer>
        <Skeleton paragraph={{ rows: 2 }} active={true} />
      </StakePoolContainer>
    </>
  );
};

export { StakePoolsSkeleton };

export default StakePools;
