import React from 'react';
import configs from '../configs';
import { useContext, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { tokenListAtom, tokenPricesAtom } from '../recoil/token/atom';
import { poolListAtom } from '../recoil/pool/atom';
import { nftProjectListAtom } from '../recoil/market/atom';

import { Address } from '../types';
import { walletAddressSelector } from '../recoil/wallet/selectors';
import { useCookies } from 'react-cookie';

const InitAtoms = () => {
  const { apiService, marketApiService: havahMarketApiService } = configs;

  const [, setWalletAddress] = useRecoilState(walletAddressSelector);
  const [cookie, setCookie] = useCookies(['walletAddress']);

  const [, setTokenListAtom] = useRecoilState(tokenListAtom);
  const [, setPoolListAtom] = useRecoilState(poolListAtom);
  const [, setNftProjectListAtom] = useRecoilState(nftProjectListAtom);
  const [, setTokenPricesAtom] = useRecoilState(tokenPricesAtom);

  useEffect(() => {
    (async () => {
      try {
        const tokens = await apiService.getTokenList();
        setTokenListAtom(tokens);

        const pools = await configs.apiService.getPoolList();
        setPoolListAtom(pools);

        const nftProjects = await havahMarketApiService.getNftProjectList();
        setNftProjectListAtom(nftProjects);

        const tokenPrices = await apiService.getTokenPrices();
        setTokenPricesAtom(tokenPrices);
      } catch (e) {
        console.log(e);
      }
    })();

    (window as any)?.havah?.on(
      'accountsChanged',
      async ({ address }: { address: Address }) => {
        setCookie('walletAddress', address, {
          expires: new Date(Date.now() + 1000 * 60 * 60)
        });
        setWalletAddress(address);
      }
    );
  }, []);
  return <></>;
};

export default InitAtoms;
