import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Skeleton } from 'antd';
import TokenPairBox from '../../../../components/TokenPairBox';
import { useNavigate } from 'react-router-dom';
import { Address, Pool, PoolTokenBalances, Token } from 'types';
import BigNumber from 'bignumber.js';
import { tokenPricesAtom } from '../../../../recoil/token/atom';
import { useRecoilValue } from 'recoil';
import { convertDecimal } from '../../../../common/Utils';

interface PoolTableProps {
  pools: Pool[];
  poolTokenBalances: PoolTokenBalances;
  isMyPool: boolean;
}

const PoolTable = ({ pools, poolTokenBalances, isMyPool }: PoolTableProps) => {
  const tableSpans = [4, 5, 4, 5, 6];
  const navigateTo = useNavigate();
  const tokenPrices = useRecoilValue(tokenPricesAtom);

  return (
    <PoolTableContainer>
      <TableHeader>
        <Col span={tableSpans[0]}>Pair name</Col>
        <Col span={tableSpans[1]}>Total Liquidity</Col>
        <Col span={tableSpans[2]}>Distributed Tokens</Col>
        <Col span={tableSpans[3]}>Estimate earnings</Col>
        <Col span={tableSpans[4]}></Col>
      </TableHeader>
      {pools.map((pool, idx) => {
        return (
          <PoolRow key={idx}>
            <PoolCol span={tableSpans[0]}>
              <TokenPairBox
                tokenA={pool.tokenA}
                tokenB={pool.tokenB}
                iconSize="24px"
                showTokenName={true}
              />
            </PoolCol>
            <PoolCol span={tableSpans[1]}>
              $ {pool.poolVolume.toLocaleString()}
            </PoolCol>
            <PoolCol span={tableSpans[2]}>
              <TokenPairBox
                tokenA={pool.tokenA}
                tokenB={pool.tokenB}
                iconSize="24px"
              />
            </PoolCol>
            <PoolCol span={tableSpans[3]}>
              <EstimateEarningContainer>
                <EstimateEarning>
                  <EarningItem>Swap fee reward</EarningItem>
                  <EarningValue>
                    {(pool.aprRate * 100).toFixed(4)} %
                  </EarningValue>
                </EstimateEarning>
              </EstimateEarningContainer>
            </PoolCol>
            <PoolCol span={tableSpans[4]}>
              <PoolButton
                onClick={() => {
                  navigateTo(`/liquidity-pool/detail/${pool.address}`);
                }}
              >
                {isMyPool ? 'Manage' : 'Liquidity Supply'}
              </PoolButton>
            </PoolCol>
          </PoolRow>
        );
      })}
    </PoolTableContainer>
  );
};

export const PoolTableSkeleton = () => {
  return (
    <PoolTableContainer>
      <TableHeader>
        <Col span={4}>Pair name</Col>
        <Col span={5}>Total Liquidity</Col>
        <Col span={4}>Distributed Tokens</Col>
        <Col span={5}>Estimate earnings</Col>
        <Col span={6}></Col>
      </TableHeader>
      <PoolRow>
        <Skeleton active />
      </PoolRow>
    </PoolTableContainer>
  );
};

export default PoolTable;

const PoolTableContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.75rem;
`;

const TableHeader = styled(Row)`
  width: 100%;
  font-size: 0.6875rem;
  font-weight: 400;
  background-color: #f8f8f8;
  color: #999999;
  padding: 1.25rem;
`;

const PoolRow = styled(Row)`
  width: 100%;
  color: #999999;
  align-items: center;
  padding: 1.75rem 1rem;
  border-bottom: 1px solid #99999938;

  &:last-child {
    border-radius: 0 0 0.75rem 0.75rem;
    border-bottom: none;
  }
`;

const PoolCol = styled(Col)`
  &:last-child {
    width: 15%;
    text-align: center;
  }
`;

const PoolButton = styled.button`
  padding: 3px 10px;
  cursor: pointer;
  background: transparent;
  font-size: 11px;
  line-height: 18px;
  color: #4d4d4d;
  border: 1px solid #4d4d4d;
  border-radius: 24px;
  min-width: 100px;
  transition: 0.3s linear;
  &:hover {
    opacity: 0.9;
    background: #f56666;
    color: #ffffff;
  }
`;

const EstimateEarningContainer = styled.div``;

const EstimateEarning = styled.div``;

const EarningItem = styled.span`
  margin-right: 0.5rem;
`;

const EarningValue = styled.span`
  color: #f56666;
`;
