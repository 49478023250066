import React, { Fragment } from 'react';
import {
  FooterContainer,
  FooterCopyright,
  FooterLogo,
  FooterSection,
  FooterSocialContainer,
  FooterSocialItem
} from './styles';

const Footer = () => {
  const footerSocials = [
    <>
      <FooterSocialItem>
        <a href="https://twitter.com/havahswap">
          <img src="/assets/social/twitter.svg" alt={'havah twitter'} />
        </a>
      </FooterSocialItem>
    </>,
    <>
      <FooterSocialItem>
        <a href="https://docs.havahswap.io">
          <img src="/assets/social/gitbook.svg" alt={'havah gitbook'} />
        </a>
      </FooterSocialItem>
    </>,
    <>
      <FooterSocialItem>
        <a href="https://t.me/havahswap">
          <img src="/assets/social/telegram.svg" alt={'havah telegram'} />
        </a>
      </FooterSocialItem>
    </>,
    <>
      <FooterSocialItem>
        <a href="https://discord.gg/havahswap">
          <img src="/assets/social/discord.svg" alt={'havah discord'} />
        </a>
      </FooterSocialItem>
    </>,
    <>
      <FooterSocialItem>
        <a href="mailto:contact@havahswap.io">
          <img src="/assets/social/email.svg" alt={'havah email'} />
        </a>
      </FooterSocialItem>
    </>
  ];
  return (
    <FooterContainer>
      <FooterSection>
        <FooterLogo src="/logo/havahswap_logo_text.svg" alt="logo" />
        <FooterCopyright>
          Copyright © 2023 HAVAHswap. All rights reserved.
        </FooterCopyright>
        <FooterSocialContainer>
          {footerSocials.map((item, idx) => (
            <Fragment key={idx}>{item}</Fragment>
          ))}
        </FooterSocialContainer>
      </FooterSection>
    </FooterContainer>
  );
};
export default Footer;
