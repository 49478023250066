import styled from 'styled-components';

export const ProfileCommonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 30px auto;
  width: 62%;
  min-width: 1200px;
`;

export const ProfilePageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: '#ffffff';
`;
